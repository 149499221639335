import { http, apiEndpoint } from 'shared/api';

class BillDocumentAPI {

  static fetchBillDocuments(billID, params = {}) {
    return http.get(`bills/${billID}/bill_documents`, {
      params: {
        ...params
      }
    });
  }

  static downloadUrl(billDocument, external = false) {
    return apiEndpoint(`bills/${billDocument.billable_id}/bill_documents/${billDocument.id}.pdf?external=${external}`);
  }
}

export default BillDocumentAPI;
