import * as types from './types';

// Shared actions
export function resetScreen() {
  return {
    type: types.RESET_SCREEN
  };
}

export function clearErrors() {
  return {
    type: types.CLEAR_ERRORS
  };
}

export function setCurrentStep(step) {
  return {
    type: types.SET_CURRENT_STEP,
    payload: step
  };
}

export function setAccount(account) {
  return {
    type: types.SET_ACCOUNT,
    payload: account
  };
}

export function setPayment(payment) {
  return {
    type: types.SET_PAYMENT,
    payload: payment
  };
}

export function toggleSwitchAccountModal() {
  return {
    type: types.TOGGLE_SWITCH_ACCOUNT_MODAL
  };
}

export function setConfigPending() {
  return {
    type: types.FETCH_CONFIG_PENDING
  };
}

export function createStripeIntent(account) {
  return function(dispatch) {
    dispatch({
      type: types.CREATE_STRIPE_INTENT,
      payload: PayMethodAPI.createStripeIntent(account)
    }).catch((error) => {});
  };
}

//
// TODO: Move to appropriate screen/resource actions
//
import ConfigAPI from '../api/configAPI';
import UserSessionAPI from '../api/userSessionAPI';
import UserAPI from '../api/userAPI';
import ResetPasswordAPI from '../api/resetPasswordAPI';
import BillAPI from '../api/billAPI';
import ReceivableAccountAPI from '../api/receivableAccountAPI';
import PaymentAPI from '../api/paymentAPI';
import OneTimePaymentAPI from '../api/oneTimePaymentAPI';
import PayMethodAPI from '../api/payMethodAPI';
import BillRecurrenceAPI from '../api/billRecurrenceAPI';
import RecurrenceAccountAPI from '../api/recurrenceAccountAPI';
import PortalAccountAPI from '../api/portalAccountAPI';
import PortalAgreementAPI from '../api/portalAgreementAPI';
import AccountRecurrenceAPI from '../api/accountRecurrenceAPI';
import BillDocumentAPI from '../api/billDocumentAPI';
import ExternalBillAPI from '../api/externalBillAPI';
import ExternalPaymentAPI from '../api/externalPaymentAPI';
import PayMethodAssignmentAPI from '../api/payMethodAssignmentAPI';
import PortalDocumentAPI from '../api/portalDocumentAPI';
import EmailConfirmationAPI from '../api/emailConfirmationAPI';

export function fetchConfig() {
  return function(dispatch) {
    dispatch({
      type: types.FETCH_CONFIG,
      payload: ConfigAPI.fetchConfig()
    });
  };
}

export function login(email, password) {
  return function(dispatch) {
    dispatch({
      type: types.LOGIN,
      payload: UserSessionAPI.create(email, password)
    }).catch((error) => {});
  };
}

export function updateUser(user, password = null, email = null) {
  return function(dispatch) {
    dispatch({
      type: types.UPDATE_USER,
      payload: UserAPI.updateUser(user, password, email)
    }).catch((error) => {});
  };
}

export function reset(email) {
  return function(dispatch) {
    dispatch({
      type: types.RESET_PASSWORD,
      payload: ResetPasswordAPI.create(email)
    }).catch((error) => {});
  };
}

export function updatePassword(token, user) {
  return function(dispatch) {
    dispatch({
      type: types.UPDATE_PASSWORD,
      payload: ResetPasswordAPI.update(token, user)
    }).catch((error) => {});
  };
}

export function sendEmailConfirmation(emailAddress, paymentID) {
  return function(dispatch) {
    dispatch({
      type: types.SEND_EMAIL_CONFIRMATION,
      payload: EmailConfirmationAPI.create(emailAddress, paymentID)
    }).catch((error) => {});
  };
}

export function clearPasswordResetErrors() {
  return {
    type: types.CLEAR_ERRORS,
    payload: 'passwordReset'
  };
}

export function clearLoginErrors() {
  return {
    type: types.CLEAR_ERRORS,
    payload: 'login'
  };
}

export function clearUserErrors() {
  return {
    type: types.CLEAR_ERRORS,
    payload: 'user'
  };
}

export function setUserErrors(errors) {
  return {
    type: types.SET_ERRORS,
    payload: {
      key: 'user',
      errors
    }
  };
}

export function setPayMethodErrors(errors) {
  return {
    type: types.SET_ERRORS,
    payload: {
      key: 'pay_method',
      errors
    }
  };
}

export function setPaymentErrors(errors) {
  return {
    type: types.SET_ERRORS,
    payload: {
      key: 'payment',
      errors
    }
  };
}

export function setAccountsErrors(errors) {
  return {
    type: types.SET_ERRORS,
    payload: {
      key: 'accounts',
      errors
    }
  };
}

export function clearAccountsErrors() {
  return {
    type: types.CLEAR_ERRORS,
    payload: 'accounts'
  };
}

export function setOneTimePaymentErrors(errors) {
  return {
    type: types.SET_ERRORS,
    payload: {
      key: 'one_time_payment',
      errors
    }
  };
}

export function clearOneTimePaymentErrors() {
  return {
    type: types.CLEAR_ERRORS,
    payload: 'one_time_payment'
  };
}

export function logout(redirect) {
  return function(dispatch) {
    dispatch({type: types.LOGOUT_PENDING});
    UserSessionAPI.destroy()
    .then((response) => {
      if (redirect) { redirect(); }
      else {
        dispatch({
          type: types.LOGOUT_FULFILLED,
          payload: response
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: types.LOGOUT_REJECTED,
        payload: error
      });
    });
  };
}

export function checkSessionTimeout(redirect) {
  return function(dispatch) {
    dispatch({type: types.SESSION_TIMEOUT_PENDING});
    UserSessionAPI.checkTimeout()
    .then((response) => {
      dispatch({
        type: types.SESSION_TIMEOUT_FULFILLED,
        payload: response
      });
    })
    .catch((error) => {
      if (redirect) { redirect(); }
      else {
        dispatch({
          type: types.SESSION_TIMEOUT_REJECTED,
          payload: error
        });
      }
    });
  };
}

export function fetchBillsOverview() {
  return function(dispatch) {
    dispatch({
      type: types.FETCH_BILLS_OVERVIEW,
      payload: BillAPI.fetchOverview()
    });
  };
}

export function fetchBills(params = {}) {
  return function(dispatch) {
    dispatch({
      type: types.FETCH_BILLS,
      payload: BillAPI.fetchBills(params)
    });
  };
}

export function selectBills(params = {}) {
  return function(dispatch) {
    dispatch({
      type: types.SELECT_BILLS,
      payload: BillAPI.fetchBills({
        ...params,
        all: params.all
      })
    });
  };
}

export function selectDueBills(params = {}) {
  return function(dispatch) {
    dispatch({
      type: types.SELECT_BILLS,
      payload: BillAPI.fetchDueBills(params)
    });
  };
}

export function clearSelectedBills() {
  return {
    type: types.CLEAR_SELECTED_BILLS
  };
}

export function fetchAccounts(portalUser, params = {}) {
  return function(dispatch) {
    dispatch({
      type: types.FETCH_ACCOUNTS,
      payload: ReceivableAccountAPI.fetchAccounts(portalUser, params)
    });
  };
}

export function clearAccounts() {
  return {
    type: types.CLEAR_ACCOUNTS
  };
}

export function fetchReceivableAccount(account_number,first_name,last_name) {
  return function(dispatch) {
    dispatch({
      type: types.FETCH_RECEIVABLE_ACCOUNT,
      payload: ReceivableAccountAPI.fetchReceivableAccount(account_number,first_name,last_name)
    });
  };
}

export function fetchPayments(params) {
  return function(dispatch) {
    dispatch({
      type: types.FETCH_PAYMENTS,
      payload: PaymentAPI.fetchPayments(params)
    });
  };
}

// TODO:
// export function fetchBankName(routing_number) {
//   return function(dispatch) {
//     dispatch({
//       type: types.FETCH_OTP_BANK_NAME,
//       payload: OtpAPI.fetchBankName(routing_number)
//     });
//   };
// }

export function createOneTimePayment(payment, payMethod, account) {
  return function(dispatch) {
    dispatch({
      type: types.SAVE_PAYMENT,
      payload: OneTimePaymentAPI.createPayment(payment, payMethod, account)
    }).catch((error) => {});
  };
}

export function clearPayments() {
  return {
    type: types.CLEAR_PAYMENTS
  };
}

export function savePayment(payment, params = {}) {
  return function(dispatch) {
    dispatch({
      type: types.SAVE_PAYMENT,
      payload: PaymentAPI.savePayment(payment, params)
    }).catch((error) => {});
  };
}

export function clearPaymentErrors() {
  return {
    type: types.CLEAR_ERRORS,
    payload: 'payment'
  };
}

export function clearPayment() {
  return {
    type: types.CLEAR_PAYMENT
  };
}

export function clearUpdatedUserCheck() {
  return {
    type: types.CLEAR_UPDATED_USER_CHECK
  };
}

export function clearSavedPayment() {
  return {
    type: types.CLEAR_SAVED_PAYMENT
  };
}

export function fetchPayMethods(params = {}) {
  return function(dispatch) {
    dispatch({
      type: types.FETCH_PAY_METHODS,
      payload: PayMethodAPI.fetchPayMethods(params)
    });
  };
}

export function savePayMethod(payMethod, params = {}) {
  return function(dispatch) {
    dispatch({
      type: types.SAVE_PAY_METHOD,
      payload: PayMethodAPI.savePayMethod(payMethod, params)
    }).catch((error) => {});
  };
}

export function clearSavePayMethodErrors() {
  return {
    type: types.CLEAR_ERRORS,
    payload: 'pay_method'
  };
}

export function deletePayMethod(payMethod) {
  return function(dispatch) {
    dispatch({
      type: types.DELETE_PAY_METHOD,
      payload: PayMethodAPI.deletePayMethod(payMethod)
    });
  };
}

export function clearDeletedPayMethod() {
  return {
    type: types.CLEAR_DELETED_PAY_METHOD
  };
}


export function clearSavedPayMethod() {
  return {
    type: types.CLEAR_SAVED_PAY_METHOD
  };
}

export function clearDocsAdded() {
  return {
    type: types.CLEAR_DOCS_ADDED
  };
}

export function fetchPortalUsers(payer) {
  return function(dispatch) {
    dispatch({
      type: types.FETCH_PORTAL_USERS,
      payload: UserAPI.fetchPortalUsers(payer)
    });
  };
}

export function fetchBillDocuments(billId, external = false) {
  return function(dispatch) {
    dispatch({
      type: types.FETCH_BILL_DOCUMENTS,
      payload: BillDocumentAPI.fetchBillDocuments(billId, external)
    });
  };
}

export function fetchPayMethodAssignments(portalUser) {
  return function(dispatch) {
    dispatch({
      type: types.FETCH_PAY_METHOD_ASSIGNMENTS,
      payload: PayMethodAssignmentAPI.fetchPayMethodAssignments(portalUser)
    });
  };
}

export function createPayMethodAssignment(portalUser, payMethod) {
  return function(dispatch) {
    dispatch({
      type: types.CREATE_PAY_METHOD_ASSIGNMENT,
      payload: PayMethodAssignmentAPI.createPayMethodAssignment(portalUser, payMethod)
    });
  };
}

export function deletePayMethodAssignment(portalUser, payMethod) {
  return function(dispatch) {
    dispatch({
      type: types.DELETE_PAY_METHOD_ASSIGNMENT,
      payload: PayMethodAssignmentAPI.deletePayMethodAssignment(portalUser, payMethod)
    });
  };
}

export function clearPayMethodAssignments() {
  return {
    type: types.CLEAR_PAY_METHOD_ASSIGNMENTS
  };
}

export function fetchExternalBillDocuments(bill) {
  return fetchBillDocuments(bill.id,
  {
    external: true
  });
}

export function clearBillDocuments() {
  return {
    type: types.CLEAR_BILL_DOCUMENTS
  };
}

export function fetchExternalBills(params = {}) {
  return function(dispatch) {
    dispatch({
      type: types.FETCH_EXTERNAL_BILLS,
      payload: ExternalBillAPI.fetchExternalBills(params)
    });
  };
}

export function fetchExternalPayments(params = {}) {
  return function(dispatch) {
    dispatch({
      type: types.FETCH_EXTERNAL_PAYMENTS,
      payload: ExternalPaymentAPI.fetchExternalPayments(params)
    });
  };
}

export function fetchExternalPayment(id) {
  return function(dispatch) {
    dispatch({
      type: types.FETCH_EXTERNAL_PAYMENT,
      payload: ExternalPaymentAPI.fetchExternalPayment(id)
    });
  };
}

export function clearExternalPayment() {
  return {
    type: types.CLEAR_EXTERNAL_PAYMENT
  };
}

export function deletePayment(payment) {
  return function(dispatch) {
    dispatch({
      type: types.DELETE_PAYMENT,
      payload: PaymentAPI.deletePayment(payment)
    });
  };
}

export function clearDeletedPayment() {
  return {
    type: types.CLEAR_DELETED_PAYMENT
  };
}

export function fetchBillRecurrences(params = {}) {
  return function(dispatch) {
    dispatch({
      type: types.FETCH_BILL_RECURRENCES,
      payload: BillRecurrenceAPI.fetchBillRecurrences(params)
    });
  };
}

export function clearBillRecurrences() {
  return {
    type: types.CLEAR_BILL_RECURRENCES
  };
}

export function fetchBillRecurrence(portalPayer, billRecurrenceId) {
  return function(dispatch) {
    dispatch({
      type: types.FETCH_BILL_RECURRENCE,
      payload: BillRecurrenceAPI.fetchBillRecurrence(portalPayer, billRecurrenceId)
    });
  };
}

export function fetchRecurrenceAccounts(recurrence, params = {}) {
  return function(dispatch) {
    dispatch({
      type: types.FETCH_RECURRENCE_ACCOUNTS,
      payload: RecurrenceAccountAPI.fetchRecurrenceAccounts(recurrence, params)
    });
  };
}

export function clearRecurrenceAccounts() {
  return {
    type: types.CLEAR_RECURRENCE_ACCOUNTS
  };
}

export function saveBillRecurrence(recurrence) {
  return function(dispatch) {
    dispatch({
      type: types.SAVE_BILL_RECURRENCE,
      payload: BillRecurrenceAPI.saveBillRecurrence(recurrence)
    });
  };
}

export function clearSavedBillRecurrence() {
  return {
    type: types.CLEAR_SAVED_BILL_RECURRENCE
  };
}

export function createRecurrenceAccount(recurrence, params = {}) {
  return function(dispatch) {
    dispatch({
      type: types.CREATE_RECURRENCE_ACCOUNT,
      payload: RecurrenceAccountAPI.createRecurrenceAccount(recurrence, params)
    });
  };
}

export function clearCreatedRecurrenceAccount() {
  return {
    type: types.CLEAR_CREATED_RECURRENCE_ACCOUNT
  };
}

export function fetchPortalAccounts(params = {}) {
  return function(dispatch) {
    dispatch({
      type: types.FETCH_PORTAL_ACCOUNTS,
      payload: PortalAccountAPI.fetchPortalAccounts(params)
    });
  };
}

export function clearPortalAccounts() {
  return {
    type: types.CLEAR_PORTAL_ACCOUNTS
  };
}

export function deleteRecurrenceAccount(recurrence, account) {
  return function(dispatch) {
    dispatch({
      type: types.DELETE_RECURRENCE_ACCOUNT,
      payload: RecurrenceAccountAPI.deleteRecurrenceAccount(recurrence, account)
    });
  };
}

export function clearDeletedRecurrenceAccount() {
  return {
    type: types.CLEAR_DELETED_RECURRENCE_ACCOUNT
  };
}

export function editBillRecurrence(portalPayer, recurrence) {
  return function(dispatch) {
    dispatch({
      type: types.EDIT_BILL_RECURRENCE,
      payload: BillRecurrenceAPI.editBillRecurrence(portalPayer, recurrence)
    });
  };
}

export function deleteBillRecurrence(recurrence) {
  return function(dispatch) {
    dispatch({
      type: types.DELETE_BILL_RECURRENCE,
      payload: BillRecurrenceAPI.deleteBillRecurrence(recurrence)
    });
  };
}

export function clearDeletedBillRecurrence() {
  return {
    type: types.CLEAR_DELETED_BILL_RECURRENCE
  };
}

export function deleteAccountRecurrence(recurrence) {
  return function(dispatch) {
    dispatch({
      type: types.DELETE_ACCOUNT_RECURRENCE,
      payload: AccountRecurrenceAPI.deleteAccountRecurrence(recurrence)
    });
  };
}

export function clearDeletedAccountRecurrence() {
  return {
    type: types.CLEAR_DELETED_ACCOUNT_RECURRENCE
  };
}

export function createAccountRecurrence(recurrence) {
  return function(dispatch) {
    dispatch({
      type: types.CREATE_ACCOUNT_RECURRENCE,
      payload: AccountRecurrenceAPI.createAccountRecurrence(recurrence)
    }).catch((error) => {});
  };
}

export function createPortalAgreement(portalUser) {
  return function(dispatch) {
    dispatch({
      type: types.CREATE_PORTAL_AGREEMENT,
      payload: PortalAgreementAPI.createPortalAgreement(portalUser)
    });
  };
}

export function clearCreatedAccountRecurrence() {
  return {
    type: types.CLEAR_CREATED_ACCOUNT_RECURRENCE
  };
}

export function clearPasswordReset() {
  return {
    type: types.CLEAR_PASSWORD_RESET
  };
}

export function createPortalDocument(portalPayment, file, splitId = null) {
  return {
    type: types.CREATE_PORTAL_DOCUMENT,
    payload: PortalDocumentAPI.createPortalDocument(portalPayment, file, splitId)
  };
}

export function deletePortalDocument(portalPayment, file) {
  return {
    type: types.DELETE_PORTAL_DOCUMENT,
    payload: PortalDocumentAPI.deletePortalDocument(portalPayment, file)
  };
}
