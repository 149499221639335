import React from 'react';
import { PropTypes } from 'prop-types';
import PasswordStrength from './PasswordStrength';
import WarningMessage from './WarningMessage';
import Link from './Link';
import { Popover, PopoverTitle, PopoverContent } from 'reactstrap';
import NoticePopover from './NoticePopover';
import ReactMarkdown from 'react-markdown';

class UserForm extends React.Component {
  constructor(props) {
    super(props);

    const user = {
      email: '',
      password: '',
      password_confirmation: ''
    };

    this.state = {
      user,
      isValid: false,
      emailPopoverOpen: false,
      passwordPopoverOpen: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.toggleEmailPopover = this.toggleEmailPopover.bind(this);
  }

  toggleEmailPopover() {
    this.setState({
      emailPopoverOpen: !this.state.emailPopoverOpen
    });
  }

  emailNotice() {
    if (this.props.content.register_account_notice) {
      return (
        <>
          <div className="input-group-append">
            <Link className="btn btn-outline-primary" onClick={this.toggleEmailPopover}>
              <span id="emailNoticePopover">
                <i className="fa icon-help"></i>
              </span>
            </Link>
          </div>
          <NoticePopover
            placement="top"
            isOpen={this.state.emailPopoverOpen}
            target="emailNoticePopover"
            toggle={this.toggleEmailPopover}
            source={this.props.content.register_account_notice}
            className="email-notice top"
            containerTagName="span" />
        </>
      );
    }
  }

  emailFieldClass() {
    const fieldClass = this.props.content.register_account_notice ? 'input-group' : '';
    return `${fieldClass}`;
  }

  emailField() {
    return (
      <div className="form-group row">
        <label className="col-form-label col-sm-4 text-sm-right" htmlFor="email">
          {this.props.i18n.common.email}
        </label>
        <div className="col-sm-7">
          <div className={this.emailFieldClass()}>
            <input
              required
              className="form-control"
              name="email"
              type="email"
              onChange={this.handleFieldChange}
              value={this.state.user.email}
            />
            {this.emailNotice()}
          </div>
        </div>
      </div>
    );
  }

  passwordField() {
    return (
      <div className="form-group row">
        <label className="col-form-label col-sm-4 text-sm-right" htmlFor="password">
          {this.props.i18n.common.password}
        </label>
        <div className="col-sm-7">
          <PasswordStrength
            content={this.props.content}
            labels={this.props.i18n.portal.password_validation_helper}
            changeCallback={this.handlePasswordChange}
            inputProps={{ name: "password" }} />
        </div>
      </div>
    );
  }

  showPasswordConfirmationWarning() {
    const user = this.state.user;
    return (user.password.length > 0 && user.password_confirmation.length > 0) && !(user.password == user.password_confirmation);
  }

  passwordConfirmationWarning() {
    return (
      <WarningMessage
        showWarning={this.showPasswordConfirmationWarning()}
        warningType="warning"
        alignment="center"
        message={this.props.i18n.portal.sign_up.passwords_must_match} />
    );
  }

  confirmPasswordField() {
    return (
      <div className="form-group row">
        <label className="col-form-label col-sm-4 text-sm-right" htmlFor="password_confirmation">
          {this.props.i18n.common.confirm_password}
        </label>
        <div className="col-sm-7">
          <input
            required
            className="form-control"
            name="password_confirmation"
            type="password"
            onChange={this.handleFieldChange}
            value={this.state.user.password_confirmation}
          />
          {this.passwordConfirmationWarning()}
        </div>
      </div>
    );
  }

  handlePasswordChange(state) {
    const { isValid, message, password } = state;

    this.setState({ passwordMessage: message, passwordValid: isValid });
    this.setUser({ password });
  }

  handleFieldChange(e) {
    this.setUser({
      [e.currentTarget.name]: e.currentTarget.value
    });
  }

  setUser(user) {
    this.setState({
      user: {
        ...this.state.user,
        ...user
      }
    }, this.validateForm);
  }

  validateForm() {
    const user = this.state.user;
    const passwordValid =
      this.state.passwordValid && user.password == user.password_confirmation;
    const isValid = user.email && passwordValid;
    this.setState({ isValid });
  }

  actions() {
    return (
      <div className="form-actions form-group">
        <input
          className="btn btn-lg primary-btn"
          type="submit"
          disabled={!this.state.isValid}
          value={this.props.i18n.common.submit} />
      </div>
    );
  }

  handleSubmit($event) {
    $event.preventDefault();
    this.props.onSubmit(this.state.user);
  }

  render() {
    return (
      <form
        onSubmit={this.handleSubmit}
        className="form-horizontal"
        noValidate="novalidate">
        <div className="inner">
          {this.emailField()}
          {this.passwordField()}
          {this.confirmPasswordField()}
          {this.actions()}
        </div>
      </form>
    );
  }
}

UserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  content: PropTypes.object,
  i18n: PropTypes.object.isRequired
};

export default UserForm;
