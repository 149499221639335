import React from 'react';
import { PropTypes } from 'prop-types';
import { CardElement } from '@stripe/react-stripe-js';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", "Helvetica, sans-serif"',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

function CardSection(props) {
  const { onError, clearErrors } = props;

  const onChange = (event) => {
    if (event.error) {
      onError(event.error.message);
    } else {
      clearErrors();
    }
  };

  return (
    <div>
      <label htmlFor="stripe-card">
        Card details
      </label>
      <CardElement
        onChange={onChange}
        options={CARD_ELEMENT_OPTIONS}
      />
    </div>
  );
}

CardSection.propTypes = {
  onError: PropTypes.func,
  clearErrors: PropTypes.func,
};

export default CardSection;
