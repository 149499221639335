import React from 'react';
import { PropTypes } from 'prop-types';
import { Modal as ReactModal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from './Button';

class Modal extends React.Component {

  constructor(props) {
    super(props);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  header() {
    if (this.props.title) {
      return (
        <ModalHeader toggle={this.props.toggle}>
          {this.props.title}
        </ModalHeader>
      );
    }
  }

  actions() {
    if (this.props.actions) {
      return this.props.actions;
    }
    else {
      return (
        <>
          {this.closeButton()}
          {this.confirmButton()}
        </>
      );
    }
  }

  closeButton() {
    if (this.props.showClose) {
      return (
        <Button onClick={this.props.toggle} size="">
          {this.props.closeLabel}
        </Button>
      );
    }
  }

  confirmButton() {
    if (this.props.showConfirm) {
      return (
        <Button color="primary" onClick={this.handleConfirm} size="">
          {this.props.confirmLabel}
        </Button>
      );
    }
  }

  handleConfirm() {
    this.props.onConfirm();
  }

  content() {
    return (
      <ModalBody>
        {this.props.message || this.props.children}
      </ModalBody>
    );
  }

  footer() {
    if (this.props.actions || this.props.showConfirm || this.props.showClose) {
      return (
        <ModalFooter>
          {this.actions()}
        </ModalFooter>
      );
    }
  }

  render() {
    return (
      <ReactModal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle || (() => { })}
        className={this.props.className}>
        {this.header()}
        {this.content()}
        {this.footer()}
      </ReactModal>
    );
  }
}

Modal.defaultProps = {
  confirmClassName: "primary",
  confirmEnabled: true
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  message: PropTypes.node,
  children: PropTypes.node,
  title: PropTypes.string,
  className: PropTypes.string,
  actions: PropTypes.element,
  showClose: PropTypes.bool,
  closeLabel: PropTypes.string,
  showConfirm: PropTypes.bool,
  confirmLabel: PropTypes.string,
  onConfirm: PropTypes.func,
  confirmEnabled: PropTypes.bool,
  confirmClassName: PropTypes.string,
};

export default Modal;
