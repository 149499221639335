import { http } from 'shared/api';

class PortalAccountAPI {

  static fetchPortalAccounts(params = {}) {
    return http.get("portal_accounts", {
      params
    });
  }
}

export default PortalAccountAPI;
