import { http } from 'shared/api';

class UserAPI {

  static create(registration, user) {
    return http.post(`registrations/${registration.token}/users`, {
      user
    });
  }

  static updateUser(user, password = null, email = null) {
    return http.put(`users/${user.id}`, {
      user: {
        email,
        password
      }
    });
  }

  static fetchPortalUsers(payer) {
    return http.get(`portal_payers/${payer.id}/portal_users`);
  }

  static fetchPortalUserPayAssignment(user) {
    return http.get(`portal_users/${user}/pay_method_assignments`);
  }
}

export default UserAPI;
