import { http, apiEndpoint } from 'shared/api';

class PaymentAPI {

  static fetch(id, params = {}) {
    return http.get(`portal_payments/${id}`, {
      params
    });
  }

  static fetchStaged(params = {}) {
    return http.get('portal_payments/new', {
      params
    });
  }

  static fetchPayments(params = {}) {
    return http.get('portal_payments', {
      params: {
        paged: true,
        ...params
      }
    });
  }

  static exportUrl(payment, format) {
    return apiEndpoint(`portal_payments/${payment.id}.${format}`);
  }

  static paymentsExportUrl(format = "csv") {
    return apiEndpoint(`portal_payments.${format}`);
  }

  static createPayment(payment, params = {}) {
    return http.post('portal_payments', {
      portal_payment: {
        amount: payment.amount,
        pay_method_id: payment.pay_method_id,
        scheduled_date: payment.scheduled_date
      },
      ...params,
      amount_reason: payment.amount_reason
    });
  }

  static updatePayment(payment, params = {}) {
    return http.put(`portal_payments/${payment.id}`, {
      portal_payment: {
        amount: payment.amount,
        pay_method_id: payment.pay_method_id,
        scheduled_date: payment.scheduled_date
      },
      ...params
    });
  }

  static savePayment(payment, params = {}) {
    const save = payment.id ? this.updatePayment : this.createPayment;
    return save(payment, params);
  }

  static deletePayment(payment) {
    return http.delete(`portal_payments/${payment.id}`);
  }
}

export default PaymentAPI;
