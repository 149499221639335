import React from 'react';
import { PropTypes } from 'prop-types';
import { Button as ReactButton } from 'reactstrap';
import Icon from 'shared/components/Icon';

class Button extends React.Component {
  icon(icon) {
    if (icon) {
      return (
        <>
          <Icon type={icon} />
          {' '}
        </>
      );
    }
  }

  buttonClasses() {
    const classes = [this.props.className];
    if (this.props.active) classes.push('active');
    return classes.join(' ');
  }

  render() {
    const { children, icon, outline, ...props } = this.props;

    return (
      <ReactButton
        className={this.buttonClasses()}
        outline={props.color == "secondary" ? true : outline}
        type="button"
        {...props}
      >
        {this.icon(icon)}
        {children}
      </ReactButton>
    );
  }
}

Button.defaultProps = {
  color: "secondary",
  size: "sm",
};

Button.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  outline: PropTypes.bool,
  size: PropTypes.string,
};

export default Button;
