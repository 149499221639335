import React from 'react';
import { PropTypes } from 'prop-types';
import format from '../../utils/formatUtils';
import moment from 'moment';

class PaymentDetails extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    const isNewAchPayMethod = this.isAch(this.props.newPayMethod);
    const isNewCreditCardPayMethod = this.isCreditCard(this.props.newPayMethod);
    const isNewCheckPayMethod = this.isCheck(this.props.newPayMethod);
    const isPlastiqPayMethod = this.isPlastiq(this.props.newPayMethod);
    this.setState({
      isNewAchPayMethod,
      isNewCreditCardPayMethod,
      isNewCheckPayMethod,
      isPlastiqPayMethod
    });
  }

  confirmationNumberRow() {
    if (this.props.payment.confirmation_code) {
      return (
        <tr>
          <td className="td-label">
            {this.props.i18n.portal.payment_confirmation.confirmation_code}
          </td>
          <td>{this.props.payment.confirmation_code}</td>
        </tr>
      );
    }
  }

  accountRow() {
    if (!this.props.config.feature.bill_search_enabled && this.props.account) {
      return (
        <tr>
          <td className="td-label">
            {this.props.i18n.common.account}
          </td>
          <td>{this.props.account.account_label}</td>
        </tr>
      );
    }
  }

  accountNumRow() {
    const account = this.props.account || this.props.payment.receivable_account;
    if (this.props.config.feature.bill_search_enabled && account) {
      return (
        <tr>
          <td className="td-label">
            {this.props.content.receivable_accounts_external_key_label}
          </td>
          <td>{account.external_key}</td>
        </tr>
      );
    }
  }

  accountLabelRow() {
    const account = this.props.account || this.props.payment.receivable_account;
    if (this.props.config.feature.bill_search_enabled && account) {
      return (
        <tr>
          <td className="td-label">
            {this.props.content.payers_name_label}
          </td>
          <td>{account.payer.name}</td>
        </tr>
      );
    }
  }

  hasFees() {
    return this.props.payment.fees_total > 0;
  }

  subtotalRow() {
    if (!this.hasFees()) return;
    return (
      <tr>
        <td className="td-label">
          {this.props.i18n.portal.payer.payment.payment_subtotal}
        </td>
        <td>
          {format.currency(this.props.payment.subtotal)}
        </td>
      </tr>
    );
  }

  feesRow() {
    if (!this.hasFees()) return;
    return (
      <tr>
        <td className="td-label">
          {this.props.content.fees_label}{' '}
          ({this.props.payment.fee_percentage}%)
        </td>
        <td>
          {format.currency(this.props.payment.fees_total)}
        </td>
      </tr>
    );
  }

  amountRow() {
    return (
      <tr>
        <td className="td-label">
          {this.props.i18n.portal.payer.payment.payment_total}
        </td>
        <td>
          {format.currency(this.props.payment.amount)}
        </td>
      </tr>
    );
  }

  amountReasonRow() {
    if (
      this.props.config.feature.bill_search_enabled &&
      !this.props.oneTimePayment
    ) {
      return;
    }

    const portalSplit = this.props.payment.portal_splits_data &&
      this.props.payment.portal_splits_data.portal_splits[0];

    const amountReason = this.props.payment.amount_reason ||
      (portalSplit && portalSplit.amount_reason);

    if (this.props.config.different_amount_reason_required && amountReason) {
      return (
        <tr>
          <td className="td-label">
            {this.props.i18n.portal.payment.amount_reason_label}
          </td>
          <td>
            {amountReason}
          </td>
        </tr>
      );
    }
  }

  paymentDateRow() {
    let date = this.props.payment.external ? this.props.payment.payment_date : this.props.payment.scheduled_date;
    if (this.props.payment.is_staged && this.props.config.same_day_payments_enforced) date = moment();
    return (
      <tr>
        <td className="td-label">
          {this.props.i18n.portal.payer.payment.payment_date}
        </td>
        <td>
          {format.date(date)}
        </td>
      </tr>
    );
  }

  isAch(payMethod) {
    return payMethod ? (payMethod.type == "AchPayMethod") : false;
  }

  isCreditCard(payMethod) {
    return payMethod ? (payMethod.type == "CreditCardPayMethod") : false;
  }

  isCheck(payMethod) {
    return payMethod ? (payMethod.type == "CheckPayMethod") : false;
  }

  isPlastiq(payMethod) {
    return payMethod && payMethod.type == "PlastiqPayMethod";
  }

  achAccountTypeRow() {
    if (this.state.isNewAchPayMethod) {
      return (
        <tr>
          <td className="td-label">
            {this.props.i18n.portal.payer.payment.ach_account_type}
          </td>
          <td>{this.props.newPayMethod.ach_account_type}</td>
        </tr>
      );
    }
  }

  achRoutingNumberRow() {
    if (this.state.isNewAchPayMethod) {
      return (
        <tr>
          <td className="td-label">
            {this.props.i18n.portal.payer.payment.ach_routing_num}
          </td>
          <td>
            {this.props.newPayMethod.ach_routing_num}
          </td>
        </tr>
      );
    }
  }

  achAccountNumberRow() {
    if (this.state.isNewAchPayMethod) {
      return (
        <tr>
          <td className="td-label">
            {this.props.content.ach_account_num_label}
          </td>
          <td>{this.maskedAchAccountNumber()}</td>
        </tr>
      );
    }
  }

  ccPayMethodRow() {
    if (this.state.isNewCreditCardPayMethod) {
      return (
        <tr>
          <td className="td-label">
            {this.props.content.pay_method_label}
          </td>
          <td>
            {this.props.i18n.common.credit_card}
          </td>
        </tr>
      );
    }
  }

  payMethodRow() {
    const payMethod = this.getPayMethod() || this.props.payment.pay_method;
    if (payMethod) {
      return (
        <tr>
          <td className="td-label">
            {this.props.content.pay_method_label}
          </td>
          <td>{payMethod.details}</td>
        </tr>
      );
    }
  }

  getPayMethod() {
    if (this.props.payMethods) {
      return this.props.payMethods.find((p) => {
        return p.id == this.props.payment.pay_method_id;
      });
    }
  }

  maskedAchAccountNumber() {
    if (this.props.newPayMethod.ach_account_num) {
      const accountNumber = this.props.newPayMethod.ach_account_num;
      const lastDigits = accountNumber.substring(accountNumber.length - 3);
      return `****${lastDigits}`;
    }
  }

  numItemsRow() {
    let numItems;

    const { payment } = this.props;

    if (payment.portal_splits_data && payment.portal_splits_data.portal_splits) {
      numItems = payment.portal_splits_data.total_portal_splits;
    } else if (payment.external_bills) {
      numItems = payment.external_bills.length;
    } else if (payment.splits) {
      numItems = payment.splits.length;
    } else {
      numItems = 0;
    }

    if (this.props.config.feature.bill_search_enabled && numItems > 0) {
      return (
        <tr>
          <td className="td-label">
            {this.props.i18n.common.num_items}
          </td>
          <td>
            {numItems}
          </td>
        </tr>
      );
    }
  }

  statusRow() {
    if (this.props.payment.status) {
      return (
        <tr>
          <td className="td-label">
            {this.props.i18n.common.status}
          </td>
          <td>
            {this.getStatus()}
          </td>
        </tr>
      );
    }
  }

  getStatus() {
    if (this.props.payment.is_processing) {
      return this.props.content.payment_status_processing;
    }
    else {
      return this.props.payment.status;
    }
  }

  failureReasonRow() {
    if (this.props.payment.is_failed) {
      return (
        <tr>
          <td className="td-label">
            {this.props.i18n.common.failure_reason}
          </td>
          <td>
            {this.props.payment.failure_reason}
          </td>
        </tr>
      );
    }
  }

  commentRow() {
    if (this.props.payment.otp_comment) {
      return (
        <tr>
          <td className="td-label">
            {this.props.i18n.common.comment}
          </td>
          <td>
            {this.props.payment.otp_comment}
          </td>
        </tr>
      );
    }
  }

  checkPayMethodRow() {
    if (this.state.isNewCheckPayMethod) {
      return (
        <tr>
          <td className="td-label">
            {this.props.content.pay_method_label}
          </td>
          <td>
            {this.props.i18n.check}
          </td>
        </tr>
      );
    }
  }

  checkImagesRow() {
    if (this.state.isNewCheckPayMethod) {
      return (
        <tr>
          <td colSpan={2}>
            <div className="col-6" >
              <img
                is
                alt="Captured check front"
                class="img-thumbnail"
                src={this.props.newPayMethod.checkFront}
              />
            </div>
            <div className="col-6" >
              <img
                is
                alt="Captured check rear"
                class="img-thumbnail"
                src={this.props.newPayMethod.checkRear}
              />
            </div>
          </td>
        </tr>
      );
    }
  }

  docItemsRow() {
    if (this.props.payment.portal_documents && this.props.payment.portal_documents.length > 0) {
      return (
        <tr>
          <td className="td-label">
            {this.props.i18n.common.documents}
          </td>
          <td>
            <ul className="docs-list">
              {this.docListItem()}
            </ul>
          </td>
        </tr>
      );
    }
  }

  docListItem() {
    return this.props.payment.portal_documents.map((doc, i) => {
      return (
        <li
          key={i}
          className="portal-payment-file-name"
          >
          {doc.display_name.split("\n").map((text, i) => {
            return <div className="portal-payment-file-name-contents" key={i}>{text}</div>;
          })}
        </li>
      );
    });
  }

  render() {
    return (
      <table className="table table-bordered payment-confirmation-table">
        <tbody>
          {this.confirmationNumberRow()}
          {this.paymentDateRow()}
          {this.accountRow()}
          {this.accountNumRow()}
          {this.accountLabelRow()}
          {this.achAccountTypeRow()}
          {this.achRoutingNumberRow()}
          {this.achAccountNumberRow()}
          {this.ccPayMethodRow()}
          {this.payMethodRow()}
          {this.numItemsRow()}
          {this.commentRow()}
          {this.statusRow()}
          {this.failureReasonRow()}
          {this.checkPayMethodRow()}
          {this.checkImagesRow()}
          {this.docItemsRow()}
          {this.subtotalRow()}
          {this.feesRow()}
          {this.amountRow()}
          {this.amountReasonRow()}
        </tbody>
      </table>
    );
  }
}

PaymentDetails.propTypes = {
  oneTimePayment: PropTypes.bool,
  payment: PropTypes.object,
  account: PropTypes.object,
  splits: PropTypes.array,
  payMethods: PropTypes.array,
  newPayMethod: PropTypes.object,
  i18n: PropTypes.object.isRequired,
  config:PropTypes.object.isRequired,
  content: PropTypes.object.isRequired
};

export default PaymentDetails;
