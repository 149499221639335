import { http, apiEndpoint } from 'shared/api';

class ExternalPaymentAPI {

  static fetchExternalPayments(params = {}) {
    return http.get('external_payments', {
      params
    });
  }

  static fetchExternalPayment(id) {
    return http.get(`external_payments/${id}`);
  }

  static exportUrl(externalPayment, format) {
    return apiEndpoint(`external_payments/${externalPayment.id}.${format}`);
  }

  static paymentsExportUrl(params = {}, format = "csv") {
    const url = apiEndpoint(`external_payments.${format}`);
    const paramsString = $.param(params);
    return `${url}?${paramsString}`;
  }
}

export default ExternalPaymentAPI;
