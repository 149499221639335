import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router';
import PasswordStrength from '../common/PasswordStrength';
import FormErrors from 'shared/components/FormErrors';
import { getQueryParam } from '../../utils/urlUtils';

class PasswordResetPage extends React.Component {

  constructor(props) {
    super(props);

    const user = {
      password: '',
      password_confirmation: ''
    };

    this.state = {
      isValid: false,
      user
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  componentWillMount() {
    this.props.clearPasswordReset();
    this.props.clearPasswordResetErrors();
  }

  handleSubmit($event) {
    $event.preventDefault();
    const token = this.getPasswordResetToken();
    this.props.clearPasswordResetErrors();
    this.props.updatePassword(token, this.state.user);
  }

  getPasswordResetToken() {
    if (this.props.currentUser) return this.props.currentUser.password_reset_token;
    else return getQueryParam("password_reset_token");
  }

  handlePasswordChange(state) {
    this.setState({passwordMessage: state.message, passwordValid: state.isValid});
    this.setPasswordReset({password: state.password});
  }

  handleFieldChange(e) {
    this.setPasswordReset({
      [e.currentTarget.name]: e.currentTarget.value
    });
  }

  setPasswordReset(user) {
    this.setState({
      user: {
        ...this.state.user,
        ...user
      }
    }, this.validateForm);
  }

  validateForm() {
    const user = this.state.user;
    const isValid = this.state.passwordValid && user.password == user.password_confirmation;
    this.setState({isValid});
  }

  passwordField() {
    return (
      <div className="form-group">
        <PasswordStrength
          content={this.props.content}
          labels={this.props.i18n.portal.password_validation_helper}
          changeCallback={this.handlePasswordChange}
          inputProps={{
            name: "password",
            className: "form-control form-control-lg",
            placeholder: this.props.i18n.portal.password_reset.new_password
          }} />
      </div>
    );
  }

  passwordMessageClass() {
    return this.state.passwordMessage ? 'help-block' : 'hidden';
  }

  confirmPasswordField() {
    return (
      <div className="form-group">
        <input
          required
          className="form-control form-control-lg"
          name="password_confirmation"
          type="password"
          onChange={this.handleFieldChange}
          value={this.state.user.password_confirmation}
          placeholder={this.props.i18n.portal.password_reset.confirm_new_password}
        />
      </div>
    );
  }

  actions() {
    return (
      <div className="form-actions">
        <input
          className="btn btn-lg primary-btn"
          type="submit"
          disabled={!this.state.isValid}
          value={this.props.i18n.portal.password_reset.update_password} />
      </div>
    );
  }

  passwordForm() {
    if (!this.props.resetPassword) {
      return (
        <form
          onSubmit={this.handleSubmit}
          noValidate="novalidate">
          <div className="inner">
            {this.passwordField()}
            {this.confirmPasswordField()}
            {this.actions()}
          </div>
        </form>
      );
    }
  }

  formErrors() {
    return (
      <FormErrors
        errors={this.props.errors.passwordReset}
        dismiss={this.props.clearPasswordResetErrors} />
    );
  }

  successMessage() {
    if (this.props.resetPassword) {
      return (
        <footer className="centered">
          <p className="lead">
            {this.props.i18n.portal.password_reset.password_updated}
          </p>
          <Link to="/login" className="btn primary-btn btn-lg">
            {this.props.content.sign_in_label}
          </Link>
        </footer>
      );
    }
  }

  passwordChangeRequiredMessage() {
    const currentUser = this.props.currentUser;
    if (!this.props.resetPassword && currentUser && currentUser.password_change_required) {
      return (
        <div className="alert alert-warning centered">
          {this.props.i18n.portal.password_reset.reset_required}
        </div>
      );
    }
  }

  heading() {
    return (
      <div>
        <h1>{this.props.content.portal_title}</h1>
        <p className="lead">
          {this.props.i18n.portal.password_reset.reset_password}
        </p>
      </div>
    );
  }

  render() {
    return (
      <div id="password-reset">
        {this.formErrors()}
        <div className="card panel-landing">
          <div className="card-body">
            {this.heading()}
            {this.passwordChangeRequiredMessage()}
            {this.passwordForm()}
            {this.successMessage()}
          </div>
        </div>
      </div>
    );
  }
}

PasswordResetPage.propTypes = {
  i18n: PropTypes.object,
  config: PropTypes.object,
  currentUser: PropTypes.object,
  content: PropTypes.object,
  errors: PropTypes.object,
  reset: PropTypes.func,
  resetPassword: PropTypes.bool,
  clearPasswordReset: PropTypes.func,
  clearPasswordResetErrors: PropTypes.func,
  updatePassword: PropTypes.func
};

export default PasswordResetPage;
