import React from 'react';
import { PropTypes } from 'prop-types';
import Button from './Button';
import ordinal from 'ordinal-numbers';
import format from '../../utils/formatUtils';

class RecurrenceSettings extends React.Component {

  constructor(props) {
    super(props);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  editButton() {
    return (
      <Button icon="pencil-alt" onClick={this.handleEdit}>
        {this.props.i18n.portal.payer.autopay.edit_autopay}
      </Button>
    );
  }

  handleEdit() {
    this.props.onEdit(this.props.recurrence);
  }

  removeButton() {
    return (
      <Button color="danger" onClick={this.handleRemove} icon="trash">
        {this.props.i18n.portal.payer.autopay.remove_autopay}
      </Button>
    );
  }

  handleRemove() {
    this.props.onRemove(this.props.recurrence);
  }

  payMethodRow() {
    return (
      <tr>
        <td className="td-label">
          {this.props.content.pay_method_label}
        </td>
        <td>
          {this.props.recurrence.pay_method.details}
        </td>
      </tr>
    );
  }

  amountRow() {
    if (this.props.recurrence.amount) {
      return (
        <tr>
          <td className="td-label">
            {this.props.i18n.common.amount}
          </td>
          <td>{format.currency(this.props.recurrence.amount)}</td>
        </tr>
      );
    }
  }

  dayOfMonthRow() {
    if (this.props.recurrence.day_of_month) {
      return (
        <tr>
          <td className="td-label">
            {this.props.i18n.common.recurs_on}
          </td>
          <td>
            {ordinal(parseFloat(this.props.recurrence.day_of_month))}
          </td>
        </tr>
      );
    }
  }

  endDateRow() {
    if (this.props.recurrence.end_date) {
      return (
        <tr>
          <td className="td-label">
            {this.props.i18n.common.ends}
          </td>
          <td>
            {format.date(this.props.recurrence.end_date)}
          </td>
        </tr>
      );
    }
  }

  recurrenceDetails() {
    return (
      <table className="table table-bordered">
        <tbody>
          {this.amountRow()}
          {this.payMethodRow()}
          {this.dayOfMonthRow()}
          {this.endDateRow()}
        </tbody>
      </table>
    );
  }

  actions() {
    return (
      <div className="centered">
        {this.editButton()}{' '}
        {this.removeButton()}
      </div>
    );
  }

  heading() {
    return (
      <p className="lead centered">
        {this.props.i18n.portal.dashboard.autopay_settings}
      </p>
    );
  }

  render() {
    return (
      <section className="recurrence-details">
        {this.heading()}
        {this.recurrenceDetails()}
        {this.actions()}
      </section>
    );
  }
}

RecurrenceSettings.propTypes = {
  recurrence: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired
};

export default RecurrenceSettings;
