import React from 'react';
import { PropTypes } from 'prop-types';
import MenuItem from './MenuItem';
import UserDropdown from '../common/UserDropdown';
import BrandLink from '../common/BrandLink';
import NavbarToggle from '../common/NavbarToggle';
import SwitchAccountModal from './SwitchAccountModal';

class PayerLayout extends React.Component {

  constructor(props) {
    super(props);

    this.logout = this.logout.bind(this);
    this.exit = this.exit.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  logout(logoutUrl = this.props.config.logout_url) {
    const redirect = logoutUrl ? function() { window.location = logoutUrl; } : null;
    this.props.logout(redirect);
  }

  exit() {
    window.location = this.props.config.exit_url;
  }

  userDropdown(props = {}) {
    return (
      <UserDropdown
        {...props}
        content={this.props.content}
        config={this.props.config}
        showPayerInfo={this.hasMultipleDivisions()}
        currentUser={this.props.currentUser}
        toggleSwitchAccountModal={this.props.toggleSwitchAccountModal}
        i18n={this.props.i18n}
        exit={this.exit}
        logout={this.logout}
      />
    );
  }

  header() {
    return (
      <header id="payer-header">
        {this.userDropdown()}
      </header>
    );
  }

  navbar() {
    return (
      <nav id="payer-navbar" className="navbar navbar-expand-md navbar-light bg-light fixed-top">
        <h1 className="sr-only">{this.props.i18n.portal.ada_headers.payer_navbar}</h1>
        <div className="navbar-header">
          <NavbarToggle onClick={this.toggleMenu} />
        </div>
      </nav>
    );
  }

  brandLink() {
    return (
      <BrandLink
        linkTo="/payer"
        content={this.props.content}
        linkLabel={this.props.config.portal.biller.name}
        logo={this.props.content.logo_image_url}
        logoClass="logo"
        logoLinkClass="logo-link"
        onClick={this.closeMenu} />
    );
  }

  isCurrentPath(path) {
    return this.context.router.location.pathname.endsWith(path);
  }

  dashboardLinkItem() {
    const path = "/payer";
    const active = this.isCurrentPath(path);
    return (
      <MenuItem
        active={active}
        linkTo={path}
        onClick={this.closeMenu}
        linkLabel={this.props.content.menu_dashboard_label}
        linkDescription={this.props.content.menu_dashboard_description} />
    );
  }

  autopayLinkItem() {
    if (this.props.config.bill_recurrence_enabled && this.props.currentUser.portal_user.has_bill_recurrence_enabled_accounts) {
      const path = "/payer/autopay";
      const active = this.isCurrentPath(path);
      return (
        <MenuItem
          active={active}
          linkTo={path}
          onClick={this.closeMenu}
          linkLabel={this.props.i18n.portal.payer.menu.autopay}
          linkDescription={this.props.i18n.portal.payer.menu.autopay_description} />
      );
    }
  }

  settingsLinkItem() {
    if (this.props.abilities.manage_pay_method) {
      const path = "/payer/settings";
      const active = this.isCurrentPath(path);
      return (
        <MenuItem
          active={active}
          linkTo={path}
          onClick={this.closeMenu}
          linkLabel={this.props.content.menu_settings_label}
          linkDescription={this.props.content.menu_settings_description} />
      );
    }
  }

  accountLinkItem() {
    const enabled = this.props.config.account_tab_enabled;
    const operator = this.props.currentUser.portal_user.portal_operator;
    if (enabled && !operator) {
      const path = "/payer/account";
      const active = this.isCurrentPath(path);
      return (
        <MenuItem
          active={active}
          linkTo={path}
          onClick={this.closeMenu}
          linkLabel={this.props.content.menu_account_label}
          linkDescription={this.props.content.menu_account_description} />
      );
    }
  }

  helpLinkItem() {
    const enabled = this.props.config.help_section_enabled;
    if (enabled) {
      const path = "/payer/help";
      const active = this.isCurrentPath(path);
      return (
        <MenuItem
          active={active}
          linkTo={path}
          onClick={this.closeMenu}
          linkLabel={this.props.content.help_section_label}
          linkDescription={this.props.content.help_section_description} />
      );
    }
  }

  getDocumentHeight() {
    const d = document.documentElement;
    const b = document.body;
    let e = b;
    if (d.offsetHeight) e = d;
    return Math.max(e.scrollHeight, e.offsetHeight);
  }

  toggleMenu() {
    const menu = $('#payer-sidenav');
    const height = this.getDocumentHeight();

    if (menu.hasClass('open')) {
      this.closeMenu();
    }
    else {
      menu.css('height', `${height}px`);
      menu.addClass('open');
      window.scrollTo(0, 0);
    }
  }

  closeMenu() {
    const menu = $('#payer-sidenav');
    menu.removeClass('open');
    menu.css('height', '100%');
  }

  menu() {
    return (
      <nav id="payer-sidenav">
        <h1 className="sr-only">
          {this.props.i18n.portal.ada_headers.payer_sidenav}
        </h1>
        <div className="inner">
          <header>
            {this.brandLink()}
          </header>
          <ul className="nav flex-column">
            {this.dashboardLinkItem()}
            {this.autopayLinkItem()}
            {this.settingsLinkItem()}
            {this.accountLinkItem()}
            {this.helpLinkItem()}
          </ul>
          {this.userDropdown({ focusable: false })}
        </div>
      </nav>
    );
  }

  skipToMainButton() {
    return (
      <a href="#payer-main-content" className="sr-only sr-only-focusable">
        {this.props.i18n.common.skip_to_main}
      </a>
    );
  }

  switchAccountModal() {
    if (this.hasMultipleDivisions()) {
      return (
        <SwitchAccountModal
          isOpen={this.props.app.isSwitchAccountModalOpen}
          toggle={this.props.toggleSwitchAccountModal}
          title={this.props.i18n.portal.payer.menu.switch_account}
          divisions={this.props.currentUser.portal_user.portal_divisions}
        />
      );
    }
  }

  hasMultipleDivisions() {
    return this.props.currentUser.portal_user.portal_divisions.length > 1;
  }

  layout() {
    return (
      <div className="container-fluid" id="portal-payer">
        <div className="row">
          {this.skipToMainButton()}
          {this.header()}
          {this.navbar()}
        </div>
        <div className="row">
          {this.menu()}
          <main id="payer-content">
            {React.cloneElement(this.props.children.props.children, this.props)}
          </main>
        </div>
        {this.switchAccountModal()}
      </div>
    );
  }

  render() {
    return this.props.currentUser ? this.layout() : null;
  }
}

PayerLayout.contextTypes = {
  router: PropTypes.object
};

PayerLayout.propTypes = {
  logout: PropTypes.func,
  abilities: PropTypes.object,
  currentUser: PropTypes.object,
  children: PropTypes.node,
  i18n: PropTypes.object,
  config: PropTypes.object,
  content: PropTypes.object,
  app: PropTypes.object,
  toggleSwitchAccountModal: PropTypes.func
};

export default PayerLayout;
