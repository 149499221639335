import React from 'react';
import { PropTypes } from 'prop-types';
import RecurrenceForm from './RecurrenceForm';

class AccountRecurrenceForm extends React.Component {

  render() {
    return (
      <RecurrenceForm
        requireAmount
        requirePayMethod
        requireDayOfMonth
        optionalEndDate
        showConfirmation
        {...this.props} />
    );
  }
}

AccountRecurrenceForm.propTypes = RecurrenceForm.propTypes;

export default AccountRecurrenceForm;
