import React from 'react';
import { PropTypes } from 'prop-types';
import Modal from './Modal';

class EmailConfirmationModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      emailConfirmationAddress: null
    };
    this.closeEmailConfirmationModal = this.closeEmailConfirmationModal.bind(this);
    this.sendEmailConfirmation = this.sendEmailConfirmation.bind(this);
    this.handleEmailConfirmationChange = this.handleEmailConfirmationChange.bind(this);
  }

  sendEmailConfirmationForm() {
    return (
      <input
        required
        className="form-control form-control-lg"
        name="email"
        placeholder={this.props.i18n.common.email_address}
        size="30"
        type="email"
        onChange={this.handleEmailConfirmationChange}
      />
    );
  }

  closeEmailConfirmationModal() {
    this.props.toggle();
    this.sendEmailConfirmation();
  }

  sendEmailConfirmation() {
    this.props.sendEmailConfirmation(this.state.emailConfirmationAddress, this.props.payment.id);
  }

  handleEmailConfirmationChange(event) {
    this.setState({emailConfirmationAddress: event.target.value});
  }

  render() {
    return (
      <Modal
        showClose
        showConfirm
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        message={this.sendEmailConfirmationForm()}
        title={this.props.i18n.common.send_email_confirmation}
        closeLabel={this.props.i18n.common.cancel}
        confirmLabel={this.props.i18n.common.confirm}
        className="email-confirmation-modal"
        onConfirm={this.closeEmailConfirmationModal} />
    );
  }
}

EmailConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  sendEmailConfirmation: PropTypes.func,
  payment: PropTypes.object,
  content: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired
};

export default EmailConfirmationModal;
