import React from 'react';
import { PropTypes } from 'prop-types';
import Button from './Button';
import Modal from './Modal';
import PayMethodsTable from './PayMethodsTable';
import FormErrors from 'shared/components/FormErrors';

class PayMethodAssignmentModal extends React.Component {

  content() {
    return (
      <div>
        {this.formErrors()}
        {this.payMethodsTable()}
      </div>
    );
  }

  formErrors() {
    return (
      <FormErrors
        errors={this.props.errors}
        dismiss={this.props.clearErrors} />
    );
  }

  payMethodsTable() {
    return (
      <PayMethodsTable
        selectable
        results={this.props.payMethods}
        onRowClick={this.props.onPayMethodSelect}
        i18n={this.props.i18n}
        content={this.props.content}
        config={this.props.config} />
    );
  }

  render() {
    return (
      <Modal
        showClose
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className="pay-methods-modal"
        title={this.props.i18n.portal.modals.pay_methods.select_pay_method}
        message={this.content()}
        closeLabel={this.props.i18n.common.cancel} />
    );
  }
}

PayMethodAssignmentModal.propTypes = {
  payMethods: PropTypes.array,
  onPayMethodSelect: PropTypes.func,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  errors: PropTypes.array,
  clearErrors: PropTypes.func,
  i18n: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired
};

export default PayMethodAssignmentModal;
