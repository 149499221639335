import React from 'react';
import { PropTypes } from 'prop-types';

class PaymentConfirmationMessage extends React.Component {

  render() {
    let message, messageClass;

    if (this.props.payment.is_failed) {
      message = this.props.content.payment_failure_message;
      messageClass = 'alert alert-danger';
    }
    else if (this.props.payment.already_confirmed) {
      message = 'Payment has already been submitted';
      messageClass = 'alert alert-warning';
    }
    else {
      message = this.props.content.payment_confirmation_message;
      messageClass = 'alert alert-success';
    }

    return (
      <p className={`centered ${messageClass}`}>
        {message}
      </p>
    );
  }
}

PaymentConfirmationMessage.propTypes = {
  payment: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired
};

export default PaymentConfirmationMessage;
