import { connect } from 'react-redux';
import {
  DatePicker as DatePickerComponent
} from 'shared/components/DatePicker';

const mapStateToProps = (state) => {
  return {
    locale: state.config.locale,
  };
};

export const DatePicker = connect(mapStateToProps, null)(DatePickerComponent);
