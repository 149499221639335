import { http, apiEndpoint } from 'shared/api';

class BillAPI {
  static fetchOverview() {
    return http.get('bills/overview');
  }

  static fetchBills(params = {}) {
    return http.get('bills', {
      params
    });
  }

  static fetchDueBills(params = {}) {
    return http.get('bills/due', {
      params
    });
  }

  static CsvExportUrl(params = {}) {
    const url = apiEndpoint('bills.csv');
    const paramsString = $.param(params);
    return `${url}?${paramsString}`;
  }

  static PdfExportUrl(params = {}) {
    const url = apiEndpoint('bills.pdf');
    const paramsString = $.param(params);
    return `${url}?${paramsString}`;
  }
}

export default BillAPI;
