import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router';
import FormErrors from 'shared/components/FormErrors';

class LoginPage extends React.Component {

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    this.props.clearLoginErrors();
  }

  componentWillReceiveProps(nextProps) {
    this.handleLogin(nextProps);
  }

  handleLogin(nextProps) {
    if (!this.props.currentUser && nextProps.currentUser) {
      if (nextProps.currentUser.portal_payers.length > 1) {
        this.props.toggleSwitchAccountModal();
      }
    }
  }

  rememberMe() {
    if (this.props.config.remember_me_enabled) {
      return (
        <label className="remember-me" htmlFor="remember_me">
          <input
            name="remember_me"
            id="remember_me"
            type="checkbox"
            value="1" />
          {this.props.i18n.portal.login.remember_me}
        </label>
      );
    }
  }

  loginFooter() {
    return (
      <footer>
        {this.rememberMe()}
        <Link to="/forgot_password" className="forgot-password">
          {this.props.i18n.portal.login.forgot_password}
        </Link>
      </footer>
    );
  }

  handleSubmit($event) {
    $event.preventDefault();
    const email = this.refs.email.value;
    const password = this.refs.password.value;
    this.props.clearLoginErrors();
    this.props.login(email, password);
  }

  loginForm() {
    return (
      <form
        onSubmit={this.handleSubmit}
        className="form-signin landing-form"
        name="loginForm"
        ref="loginForm"
        noValidate="novalidate"
      >
        <section className="inputs">
          <h1 className="sr-only">
            {this.props.i18n.portal.ada_headers.sign_in_form}
          </h1>
          <div className="inner">
            <label htmlFor="email" className="sr-only">
              {this.props.content.email_label}
            </label>
            <input
              required
              className="form-control form-control-lg"
              id="email"
              name="email"
              ref="email"
              placeholder={this.props.content.email_label}
              size="30"
              type="email"
            />
            <label htmlFor="password" className="sr-only">
              {this.props.i18n.common.password}
            </label>
            <input
              required
              className="form-control form-control-lg"
              id="password"
              name="password"
              ref="password"
              placeholder={this.props.i18n.common.password}
              size="30"
              type="password"
              autoComplete="off"
            />
            <input
              className="btn-lg btn primary-btn"
              type="submit"
              value={this.props.content.sign_in_label}
            />
          </div>
        </section>

        {this.loginFooter()}
      </form>
    );
  }

  render() {
    return (
      <div id="portal-login">
        <FormErrors errors={this.props.errors.login} dismiss={this.props.clearLoginErrors} />
        <div className="card panel-landing">
          <div className="card-body">
            <h1>{this.props.content.portal_title}</h1>
            <p className="lead">{this.props.content.sign_in_label}</p>
            {this.loginForm()}
          </div>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  i18n: PropTypes.object,
  config: PropTypes.object,
  content: PropTypes.object,
  errors: PropTypes.object,
  login: PropTypes.func,
  clearLoginErrors: PropTypes.func,
  currentUser: PropTypes.object,
  toggleSwitchAccountModal: PropTypes.func
};

export default LoginPage;
