import React from 'react';
import { PropTypes } from 'prop-types';
import Link from './Link';
import Icon from 'shared/components/Icon';
import NoticePopover from './NoticePopover';

class DatePickerInput extends React.Component {
  constructor(props) {
    super(props);

    this.togglePopover = this.togglePopover.bind(this);

    this.state = {
      popoverOpen: false
    };
  }

  togglePopover() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  datePickernotice() {
    if (this.props.showNotice && this.props.noticeSource) {
      return (
        <>
          <Link className="input-group-append" onClick={this.togglePopover}>
            <span id="DatePickerNoticePopover">
              <i className="fa icon-help"></i>
            </span>
          </Link>
          <NoticePopover
            placement={"top"}
            isOpen={this.state.popoverOpen}
            target={"DatePickerNoticePopover"}
            toggle={this.togglePopover}
            source={this.props.noticeSource}
            className="date-picker-notice top"
            containerTagName="span" />
        </>
      );
    }
  }

  calendar() {
    if (this.props.showCalendar) {
      return (
        <div className="input-group-append">
          <Link className="btn btn-outline-primary" onClick={this.props.onClick}>
            <Icon type="calendar-alt" />
          </Link>
        </div>
      );
    }
  }

  render() {
    const groupClass = this.props.showCalendar ? 'input-group' : '';

    return (
      <div className={groupClass}>
        <input
          className="form-control"
          type="text"
          htmlFor={this.props.for}
          name={this.props.for}
          disabled={this.props.disabled}
          value={this.props.value}
          onClick={this.props.onClick}
          onKeyDown={this.props.onKeyDown}
        />
        {this.calendar()}
        {this.datePickernotice()}
      </div>
    );
  }
}

DatePickerInput.propTypes = {
  showNotice: PropTypes.bool,
  showCalendar: PropTypes.bool,
  onKeyDown: PropTypes.func,
  for: PropTypes.string,
  disabled: PropTypes.bool,
  noticeSource: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.string
};

DatePickerInput.defaultProps = {
  showCalendar: true
};

export default DatePickerInput;
