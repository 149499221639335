import { http } from 'shared/api';

class BillRecurrenceAPI {

  static fetchBillRecurrences(params = {}) {
    return http.get("portal_bill_recurrences", {
      params: {
        ...params,
        paged: true
      }
    });
  }

  static saveBillRecurrence(recurrence) {
    const save = recurrence.id ? this.updateBillRecurrence : this.createBillRecurrence;
    return save(recurrence);
  }

  static createBillRecurrence(recurrence) {
    return http.post("portal_bill_recurrences", {
      portal_bill_recurrence: recurrence
    });
  }

  static updateBillRecurrence(recurrence) {
    return http.put(`portal_bill_recurrences/${recurrence.id}`, {
      portal_bill_recurrence: {
        pay_method_id: recurrence.pay_method_id,
        payment_breakdown_type: recurrence.payment_breakdown_type,
        end_date: recurrence.end_date
      }
    });
  }

  static deleteBillRecurrence(recurrence) {
    return http.delete(`portal_bill_recurrences/${recurrence.id}`);
  }
}

export default BillRecurrenceAPI;
