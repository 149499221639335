import React from 'react';
import { PropTypes } from 'prop-types';
import Button from './Button';
import Filter from './Filter';
import Pagination from 'shared/components/Pagination';
import Link from './Link';
import format from '../../utils/formatUtils';
import Spinner from './Spinner';
import AccountsModal from './AccountsModal';
import Icon from 'shared/components/Icon';

class BillsTable extends React.Component {

  constructor(props) {
    super(props);

    const unsortableColumns = [
      'receivable_accounts.external_key',
      'payers.name',
      'bills.amount'
    ];

    const columnAlignment = {
      right: [
        'bills.discount_amount',
        'bills.due_amount',
        'bills.amount',
        'bills.original_amount',
        'bills.rollup_amount',
        'bills.external_code'
      ]
    };

    const defaultSortField = this.props.config.default_bill_sort_field + " ASC";

    this.state = {
      unsortableColumns,
      columnAlignment,
      sortOrder: defaultSortField,
      isAccountsModalOpen: false
    };

    this.toggleAccountsModal = this.toggleAccountsModal.bind(this);
    this.handleAccountSelect = this.handleAccountSelect.bind(this);
  }

  hasBills() {
    const { data } = this.props;
    return data && data.bills.length > 0;
  }

  hasPayableBills() {
    const { data } = this.props;
    return data && data.total_payable_bills > 0;
  }

  hasPayableDueBills() {
    const { data } = this.props;
    return data && data.total_payable_due_bills > 0;
  }

  dropdownItemClasses(enabled) {
    const classes = ['dropdown-item'];
    if (!enabled) classes.push('disabled');
    return classes.join(' ');
  }

  selectButton() {
    if (!this.props.selectEnabled) return;

    const selectAllEnabled = this.hasPayableBills();
    const selectAllDueEnabled = this.hasPayableDueBills();

    return (
      <div className="btn-group dropdown">
        <Button
          aria-expanded="false"
          aria-haspopup="true"
          className="dropdown-toggle"
          data-toggle="dropdown"
          disabled={!selectAllEnabled && !selectAllDueEnabled}
          id="billsTableSelectDropdownToggle"
        >
          {this.props.i18n.common.select}{' '}
          <span className="caret" />
        </Button>
        <div
          className="dropdown-menu"
          aria-labelledby="billsTableSelectDropdownToggle"
        >
          <Link
            onClick={this.props.onSelectAll}
            className={this.dropdownItemClasses(selectAllEnabled)}
            disabled={!selectAllEnabled}
          >
            {this.props.i18n.portal.bills_table.select_all}
          </Link>
          <Link
            onClick={this.props.onSelectAllDue}
            className={this.dropdownItemClasses(selectAllDueEnabled)}
            disabled={!selectAllDueEnabled}
          >
            {this.props.i18n.portal.bills_table.select_all_due}
          </Link>
        </div>
      </div>
    );
  }

  exportButton() {
    const exportEnabled = this.hasBills();
    return (
      <div className="btn-group dropdown">
        <Button
          aria-expanded="false"
          aria-haspopup="true"
          className="dropdown-toggle"
          data-toggle="dropdown"
          disabled={!exportEnabled}
          id="billsTableExportDropdownToggle"
        >
          {this.props.i18n.common.export}{' '}
          <span className="caret" />
        </Button>
        <div
          className="dropdown-menu"
          aria-labelledby="billsTableExportDropdownToggle"
        >
          <Link className="dropdown-item" onClick={this.props.onExportCsv}>
            <Icon type="file-excel" style="regular" />
            {` ${this.props.i18n.common.csv}`}
          </Link>
          <Link className="dropdown-item" onClick={this.props.onExportPdf}>
            <Icon type="file-pdf" style="regular" />
            {` ${this.props.i18n.common.pdf}`}
          </Link>
        </div>
      </div>
    );
  }

  actions() {
    const btnGroupClass = this.props.selectEnabled ? 'btn-group' : '';
    return (
      <span className="actions-toolbar float-right">
        <div className={btnGroupClass}>
          {this.selectButton()}
          {this.exportButton()}
        </div>
      </span>
    );
  }

  filter() {
    return (
      <Filter
        showNotice
        showDateFilter
        showAddFilter
        onAddFilter={this.toggleAccountsModal}
        placeholder={this.props.i18n.portal.bills_table.filter_invoices}
        onChange={this.props.onFilter}
        content={this.props.content}
        noticeSource={this.props.content.bill_search_notice}
        filterQuery={this.props.filterQuery}
        filterLabels={this.props.filterLabels}
        onRemoveFilter={this.props.onRemoveAccountFilter}
        i18n={this.props.i18n} />
    );
  }

  // Account filter
  toggleAccountsModal() {
    this.setState({ isAccountsModalOpen: !this.state.isAccountsModalOpen });
  }

  accountsModal() {
    return (
      <AccountsModal
        accounts={this.props.accounts}
        fetchAccounts={this.props.fetchAccounts}
        clearAccounts={this.props.clearAccounts}
        isOpen={this.state.isAccountsModalOpen}
        toggle={this.toggleAccountsModal}
        onRowClick={this.handleAccountSelect}
        i18n={this.props.i18n}
        content={this.props.content}
        config={this.props.config} />
    );
  }

  handleAccountSelect(account) {
    this.toggleAccountsModal();
    this.props.onAddAccountFilter(account);
  }
  // ---

  table() {
    return (
      <div className="card clear">
        <div className="table-responsive">
          <table className="table table-selectable data-table bills-table">
            <thead>
              <tr>
                {this.tableHeaders()}
              </tr>
            </thead>
            <tbody>
              {this.tableRows()}
            </tbody>
          </table>
        </div>
        {this.noResults()}
      </div>
    );
  }

  noResults() {
    if (this.props.data && this.props.data.total_bills == 0) {
      return (
        <div className="no-results">
          {this.props.i18n.portal.data_table.no_results}
        </div>
      );
    }
  }

  handleRowClick(bill) {
    this.props.onRowClick(bill);
  }

  alignmentClass(column) {
    if (this.state.columnAlignment.right.includes(column)) {
      return 'right';
    }
  }

  headerClass(column) {
    return [
      this.alignmentClass(column),
      this.sortClass(column),
    ].filter(className => className).join(' ');
  }

  sortClass(column) {
    if (this.state.sortOrder == `${column} ASC`) {
      return 'sort-asc';
    }
    else if (this.state.sortOrder == `${column} DESC`) {
      return 'sort-desc';
    }
  }

  cellValue(bill, column) {
    switch (column) {
      case 'receivable_accounts.external_key':
        return this.cellValueContents('receivable_accounts.external_key', bill.account_number, bill);
      case 'payers.name':
        return this.cellValueContents('payers.name', bill.account_name, bill);
      case 'bills.due_date':
        return this.cellValueContents('bills.due_date', this.billDueDate(bill), bill);
      case 'bills.external_type':
        return this.cellValueContents('bills.external_type', bill.external_type, bill);
      case 'bills.external_key':
        return this.cellValueContents('bills.external_key', bill.external_key, bill);
      case 'bills.external_number':
        return this.cellValueContents('bills.external_number', bill.external_number, bill);
      case 'bills.alternate_key':
        return this.cellValueContents('bills.alternate_key', bill.alternate_key, bill);
      case 'bills.discount_amount':
        return this.cellValueContents('bills.discount_amount', this.discountAmount(bill), bill);
      case 'bills.due_amount':
        return this.cellValueContents('bills.due_amount', format.currency(bill.due_amount), bill);
      case 'bills.amount':
        return this.cellValueContents('bills.amount', format.currency(this.props.amount(bill)), bill);
      case 'bills.discount_date':
        return this.cellValueContents('bills.discount_date', bill.discount_date == null ? "-" : format.date(bill.discount_date, this.props.config.date_format_string), bill);
      case 'bills.origin_date':
        return this.cellValueContents('bills.origin_date', bill.origin_date == null ? "-" : format.date(bill.origin_date, this.props.config.date_format_string), bill);
      case 'bills.original_amount':
        return this.cellValueContents('bills.original_amount', format.currency(bill.original_amount), bill);
      case 'bills.rollup_amount':
        return this.rollupContents('bills.rollup_amount', format.currency(bill.rollup_amount), bill);
      case 'bills.external_code':
        return this.cellValueContents('bills.external_code', bill.external_code, bill);
    }
  }

  rollupContents(key, value, bill) {
    if (value == '$0.00') {
      return '---';
    }
    else {
      return value;
    }
  }

  cellValueContents(key, value, bill) {
    if (this.props.showBillDocsLink) {
      if (this.props.config.bill_documents_link_field == key) {
        const handleClick = this.showBillDocsLinkClick.bind(this, bill);
        return (
          <Link onClick={handleClick}>
            {value}
          </Link>
        );
      } else {
        return value;
      }
    } else {
      return value;
    }
  }

  showBillDocsLinkClick(bill, e) {
    if (this.props.onBillDocsLinkClick) {
      e.stopPropagation();
      this.props.onBillDocsLinkClick(bill.id);
    }
  }

  billDueDate(bill) {
    const dueLabel = bill.due ? this.dueLabel() : null;
    return (
      <span>
        {format.date(bill.due_date, this.props.config.date_format_string)}{' '}
        {dueLabel}
      </span>
    );
  }

  discountAmount(bill) {
    const discountAmount = format.currency(bill.discount_amount);

    return this.props.isDiscountExpired(bill) ?
      <strike>{discountAmount}</strike> :
      discountAmount;
  }

  dueLabel() {
    return (
      <span className="due badge badge-danger">
        {this.props.content.due_label}
      </span>
    );
  }

  tableHeaders() {
    const columns = this.props.columns;
    return columns.map((column, i) => {
      const sortColumn = this.sortBy.bind(this, column);
      return (
        <th
          key={column}
          className={this.headerClass(column)}
          onClick={sortColumn}>
          {this.headerLabel(column)}
        </th>
      );
    });
  }

  tableRows() {
    if (this.props.data) {
      const columns = this.props.columns;
      const bills = this.props.data.bills;
      return bills.map((bill) => {
        const handleClick = this.handleRowClick.bind(this, bill);
        return (
          <tr
            key={bill.id}
            onClick={handleClick}
            className={this.rowClass(bill)}>
            {columns.map((column) => this.tableData(column, bill))}
          </tr>
        );
      });
    }
  }

  tableData(column, bill) {
    return (
      <td key={column} className={this.alignmentClass(column)}>
        {this.cellValue(bill, column)}
      </td>
    );
  }

  rowClass(bill) {
    const selectedClass = this.props.isRowSelected(bill) ? 'selected' : '';
    const viewOnlyClass = bill.view_only ? 'view-only' : '';
    return `${selectedClass} ${viewOnlyClass}`;
  }

  sortBy(column) {
    if (!this.state.unsortableColumns.includes(column)) {
      const sortOrder = this.state.sortOrder;
      let newSortOrder;

      switch (sortOrder) {
        case `${column} ASC`: {
          newSortOrder = `${column} DESC`;
          break;
        }
        case `${column} DESC`: {
          newSortOrder = null;
          break;
        }
        default: {
          newSortOrder = `${column} ASC`;
        }
      }

      this.setState({ sortOrder: newSortOrder });
      this.props.onSort(newSortOrder);
    }
  }

  headerLabel(column) {
    const columnName = column.replace(/\./, '_');
    return this.props.content[`${columnName}_label`];
  }

  pagination() {
    if (this.props.data && this.props.data.total_bills > 0) {
      return (
        <Pagination
          totalItems={this.props.data.total_bills}
          itemsPerPage={this.props.data.items_per_page}
          onPageChange={this.props.onPage}
          currentPage={this.props.data.current_page} />
      );
    }
  }

  render() {
    if (!this.props.data) {
      return <Spinner centered size="medium" />;
    } else {
      return (
        <section className="data-table-section bills-table-section">
          <h1>
            {this.props.heading}
          </h1>
          {this.actions()}
          {this.filter()}
          {this.table()}
          {this.pagination()}
          {this.accountsModal()}
        </section>
      );
    }
  }
}

BillsTable.propTypes = {
  amount: PropTypes.func.isRequired,
  data: PropTypes.object,
  columns: PropTypes.array.isRequired,
  heading: PropTypes.string.isRequired,
  isDiscountExpired: PropTypes.func.isRequired,
  onPage: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  onSelectAllDue: PropTypes.func.isRequired,
  onExportCsv: PropTypes.func.isRequired,
  onExportPdf: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  isRowSelected: PropTypes.func.isRequired,
  showBillDocsLink: PropTypes.bool,
  selectEnabled: PropTypes.bool,
  onBillDocsLinkClick: PropTypes.func,
  filterQuery: PropTypes.string,
  i18n: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  accounts: PropTypes.object,
  fetchAccounts: PropTypes.func,
  clearAccounts: PropTypes.func,
  filterLabels: PropTypes.array,
  onAddAccountFilter: PropTypes.func,
  onRemoveAccountFilter: PropTypes.func
};

export default BillsTable;
