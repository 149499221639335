import React from 'react';
import { PropTypes } from 'prop-types';
import Link from './Link';

class Tab extends React.Component {
  constructor(props) {
    super(props);
    this.handleTabClick = this.handleTabClick.bind(this);
  }

  handleTabClick() {
    if (!this.props.active) {
      this.props.onSelect();
    }
  }

  linkClasses() {
    const classes = ['nav-link'];
    if (this.props.active) classes.push('active');
    return classes.join(' ');
  }

  render() {
    if (this.props.show) {
      return (
        <li className="nav-item">
          <Link className={this.linkClasses()} onClick={this.handleTabClick}>
            {this.props.label}
          </Link>
        </li>
      );
    }
    else {
      return null;
    }
  }
}

Tab.propTypes = {
  label: PropTypes.string.isRequired,
  active: PropTypes.bool,
  onSelect: PropTypes.func,
  show: PropTypes.bool
};

export default Tab;
