import React from 'react';
import { PropTypes } from 'prop-types';
import { Link, IndexLink } from 'react-router';
import BrandLink from './BrandLink';
import NavbarToggle from './NavbarToggle';

class Navbar extends React.Component {
  navbarHeader() {
    const { config, content } = this.props;
    const logoUrl = content.landing_logo_image_url || content.logo_image_url;

    return (
      <>
        <BrandLink
          content={content}
          linkTo="/"
          linkClass="navbar-brand"
          linkLabel={config.portal.biller.name}
          logo={logoUrl}
          logoLinkClass="navbar-logo" />
        <NavbarToggle target="navbarLinks" />
      </>
    );
  }

  registrationLinkItem() {
    if (this.props.config.user_registration_enabled) {
      return (
        <li className="nav-item">
          <Link to="/signup" className="nav-link register-link d-inline-flex align-items-center">
            {this.props.i18n.common.register}
          </Link>
        </li>
      );
    }
  }

  loginLinkItem() {
    return (
      <li className="nav-item">
        <Link to="/login" className="nav-link sign-in-link d-inline-flex align-items-center">
          <i className="user-icon fa fa-fw" />
          {` ${this.props.content.sign_in_label} `}
        </Link>
      </li>
    );
  }

  navbarLinks() {
    return (
      <div className="collapse navbar-collapse" id="navbarLinks">
        <ul className="nav navbar-nav ml-auto">
          {this.loginLinkItem()}
          {this.registrationLinkItem()}
        </ul>
      </div>
    );
  }

  render() {
    return (
      <nav>
        <h1 className="sr-only">{this.props.i18n.portal.ada_headers.navbar}</h1>
        <div className="navbar navbar-expand-md navbar-light" id="navbar">
          {this.navbarHeader()}
          {this.navbarLinks()}
        </div>
      </nav>
    );
  }
}

Navbar.propTypes = {
  i18n: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired
};

export default Navbar;
