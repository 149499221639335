import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router';

class LandingOption extends React.Component {
  render() {
    return (
      <div className={`option-wrapper ${this.props.wrapperClass}`}>
        <div className="option">
          <h1>{this.props.heading}</h1>
          <h2>{this.props.subHeading}</h2>
          <Link to={this.props.linkTo} className="btn primary-btn option-link">
            {this.props.linkLabel}
          </Link>
        </div>
      </div>
    );
  }
}

LandingOption.propTypes = {
  wrapperClass: PropTypes.string,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  linkTo: PropTypes.string,
  linkLabel: PropTypes.string
};

export default LandingOption;
