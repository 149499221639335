import { http } from 'shared/api';

class UserSessionAPI {
  static create(email, password) {
    return http.post('user_sessions', {
      user_session: { email, password }
    });
  }

  static destroy() {
    return http.delete('user_sessions/signout');
  }

  static checkTimeout() {
    return http.get('user_sessions/timeout');
  }


  static createUser(account, token, user) {
    return http.post('users', {
      account, token: token, user: user
    });
  }
}

export default UserSessionAPI;
