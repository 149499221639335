import React from 'react';
import { PropTypes } from 'prop-types';
import Modal from './Modal';

class ImageModal extends React.Component {

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className="check-help-modal">
        <img
          alt={this.props.imgAlt}
          longdesc={this.props.imgDescription}
          src={this.props.image}
        />
      </Modal>
    );
  }
}

ImageModal.propTypes = {
  imgDescription: PropTypes.string,
  imgAlt: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  image: PropTypes.string
};

export default ImageModal;
