import React from 'react';
import { PropTypes } from 'prop-types';

class UserDetails extends React.Component {

  payerNameField() {
    if (this.props.fields.includes('payers.name')) {
      return (
        <strong>
          {this.props.account.payer.name}
        </strong>
      );
    }
  }

  payerEmailField() {
    if (this.props.fields.includes('payers.email')) {
      return (
        <div>
          {this.props.account.payer.email}
        </div>
      );
    }
  }

  accountExternalKeyField() {
    if (this.props.fields.includes('receivable_accounts.external_key')) {
      return (
        <div>
          <strong>
            {this.props.content.receivable_accounts_external_key_label}
          </strong>{': '}
          {this.props.account.external_key}
        </div>
      );
    }
  }

  render() {
    return (
      <section className="card info-panel">
        <h1 className="sr-only">{this.props.i18n.portal.ada_headers.account_details}</h1>
        <div className="card-body">
          {this.payerNameField()}
          {this.payerEmailField()}
          {this.accountExternalKeyField()}
        </div>
      </section>
    );
  }
}

UserDetails.propTypes = {
  account: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  i18n: PropTypes.object
};

export default UserDetails;
