export const CREATE_REGISTRATION              = 'CREATE_REGISTRATION';
export const CREATE_REGISTRATION_PENDING      = 'CREATE_REGISTRATION_PENDING';
export const CREATE_REGISTRATION_FULFILLED    = 'CREATE_REGISTRATION_FULFILLED';
export const CREATE_REGISTRATION_REJECTED     = 'CREATE_REGISTRATION_REJECTED';

export const CREATE_REGISTRATION_ACCOUNT            = 'CREATE_REGISTRATION_ACCOUNT';
export const CREATE_REGISTRATION_ACCOUNT_PENDING    = 'CREATE_REGISTRATION_ACCOUNT_PENDING';
export const CREATE_REGISTRATION_ACCOUNT_FULFILLED  = 'CREATE_REGISTRATION_ACCOUNT_FULFILLED';
export const CREATE_REGISTRATION_ACCOUNT_REJECTED   = 'CREATE_REGISTRATION_ACCOUNT_REJECTED';

export const CREATE_USER                      = 'CREATE_USER';
export const CREATE_USER_PENDING              = 'CREATE_USER_PENDING';
export const CREATE_USER_FULFILLED            = 'CREATE_USER_FULFILLED';
export const CREATE_USER_REJECTED             = 'CREATE_USER_REJECTED';

export const NEW_ACCOUNT                      = 'NEW_ACCOUNT';
