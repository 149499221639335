import React from 'react';
import { PropTypes } from 'prop-types';
import Navbar from './Navbar';
import Icon from 'shared/components/Icon';

class Layout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showSkipToMain: true
    };
  }

  componentWillMount() {
    if ((this.props.children.props.route.landing && !this.props.children.props.route.path) || this.props.children.props.route.path == "payer") {
      this.setState({showSkipToMain: false});
    } else {
      this.setState({showSkipToMain: true});
    }
  }

  componentWillReceiveProps(nextProps) {
    if ((nextProps.children.props.route.landing && !nextProps.children.props.route.path) || this.props.children.props.route.path == "payer") {
      this.setState({showSkipToMain: false});
    } else {
      this.setState({showSkipToMain: true});
    }
  }

  toggleLanding() {
    const landingClass = 'landing';
    const isLanding = this.props.children.props.route.landing == true;
    $('body').toggleClass(landingClass, isLanding);
  }

  navbar() {
    if (this.props.children.props.route.navbar) {
      return <Navbar {...this.props} />;
    }
  }

  skipToMainButton() {
    if (this.state.showSkipToMain) {
      return (
        <a href="#main-content" className="sr-only sr-only-focusable">
          {this.props.i18n.common.skip_to_main}
        </a>
      );
    }
  }

  layout() {
    return (
      <div>
        {this.skipToMainButton()}
        <h1 className="sr-only">
          {this.props.i18n.portal.ada_headers.payer_layout}
        </h1>
        {this.navbar()}
        <div className="container-fluid">
          <div id="portal-content">
            <main id="main-content" ref="main">
              {React.cloneElement(this.props.children, this.props)}
            </main>
          </div>
        </div>
      </div>
    );
  }

  spinner() {
    return (
      <div id="app-loading">
        <Icon spin type="circle-notch" />
      </div>
    );
  }

  render() {
    this.toggleLanding();
    return this.props.config.isLoaded ? this.layout() : this.spinner();
  }
}

Layout.propTypes = {
  children: PropTypes.node,
  config: PropTypes.object.isRequired,
  i18n: PropTypes.object
};

export default Layout;
