import { http } from 'shared/api';

class AccountRecurrenceAPI {

  static createAccountRecurrence(recurrence) {
    return http.post('portal_account_recurrences', {
      portal_recurrence: {
        receivable_account_id: recurrence.receivable_account_id,
        pay_method_id: recurrence.pay_method_id,
        amount: recurrence.amount,
        day_of_month: recurrence.day_of_month,
        end_date: recurrence.end_date
      }
    });
  }

  static deleteAccountRecurrence(recurrence) {
    return http.delete(`portal_account_recurrences/${recurrence.id}`);
  }
}

export default AccountRecurrenceAPI;
