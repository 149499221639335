import React from 'react';
import { PropTypes } from 'prop-types';
import Button from '../common/Button';
import { DatePicker } from './DatePicker';
import moment from 'moment';
import Icon from 'shared/components/Icon';
import format from '../../utils/formatUtils';
import PaymentDetails from './PaymentDetails';
import PayMethodForm from './PayMethodForm';
import PayMethodModal from '../common/PayMethodModal';
import UserDetails from '../common/UserDetails';
import PaymentAmountInput from '../common/PaymentAmountInput';
import WarningMessage from '../common/WarningMessage';
import ConfirmModal from '../common/ConfirmModal';
import AccountDetails from '../account/AccountDetails';
import FormErrors from 'shared/components/FormErrors';
import DatePickerInput from './DatePickerInput';
import Link from './Link';
import PaymentConfirmationMessage from '../common/PaymentConfirmationMessage';
import Checkbox from './Checkbox';
import Spinner from './Spinner';

class PaymentForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      currentStep: 1,
      lastStep: 3,
      overPaymentAmountLimit: false,
      percentageRestrictionExceeded: false,
      negativeAmount: false,
      billsValid: true,
      lockdownDate: false,
      selectedPayMethodIsExpired: false,
      paymentAuthorizationChecked: false,
      isPlastiqConfirmationModalOpen: false,
    };

    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.editDetails = this.editDetails.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.togglePayMethodModal = this.togglePayMethodModal.bind(this);
    this.closePayMethodModal = this.closePayMethodModal.bind(this);
    this.handleNewPayMethodChange = this.handleNewPayMethodChange.bind(this);
    this.onPaymentAuthChange = this.onPaymentAuthChange.bind(this);
  }

  componentWillMount() {
    const payMethods = this.getPayMethods();
    const payMethod = this.getPayMethod();
    const newPayMethod = {};
    const formGroupClass = this.getFormGroupClass();
    const labelClass = this.getLabelClass();
    const fieldClass = this.getFieldClass();
    const paymentDocs = this.props.payment.portal_documents;
    const scheduledDate = this.props.payment.scheduled_date;

    this.setDefaults(payMethods);

    this.setState({
      paymentDocs,
      payMethod,
      newPayMethod,
      payMethods,
      formGroupClass,
      labelClass,
      fieldClass,
      isPayMethodModalOpen: false,
    }, this.validateForm);
  }

  componentWillReceiveProps(nextProps) {
    this.handleErrors(nextProps);
    this.handleValidationChange(nextProps);
    this.handleSavedPayment(nextProps);
    this.handlePayMethods(nextProps);
    this.handleSavedPayMethod(nextProps);
    this.handleDocsChange(nextProps);
    this.handleSplitUpdating(nextProps);
  }

  handleSplitUpdating(nextProps) {
    this.validateForm(nextProps);
  }

  handleDocsChange(nextProps) {
    if (nextProps.docsAdded) {
      this.props.clearDocsAdded();
      const payMethods = this.getPayMethods();
      let payMethodId = payMethods.length ? this.getDefaultPayMethodId(payMethods) : '';
      this.props.onChange({
        pay_method_id: payMethodId
      });

      this.setState({ paymentDocs: nextProps.payment.portal_documents });
    }
  }

  handleValidationChange(nextProps) {
    const paymentChanged = this.props.payment != nextProps.payment;
    const splitsChanged = this.props.splitsValid != nextProps.splitsValid;
    if (paymentChanged || splitsChanged) {
      this.validateForm(nextProps);
    }
  }

  handleErrors(nextProps) {
    if (nextProps.errors) {
      this.setState({ currentStep: 1 });
    }
  }

  handleSavedPayment(nextProps) {
    if (nextProps.savedPayment) {
      const selectedPayMethod = this.getSelectedPayMethod(this.props.payment);
      if (this.isPlastiq(selectedPayMethod)) {
        window.location = nextProps.savedPayment.plastiq_request_url;
      } else {
        this.setState({ currentStep: 3 });
      }
    }
  }

  handlePayMethods(nextProps) {
    const payMethodsChanged = this.props.payMethods != nextProps.payMethods;
    const ccEnabledChanged = this.props.creditCardEnabled != nextProps.creditCardEnabled;
    if (!this.props.newPayMethodMode && (payMethodsChanged || ccEnabledChanged)) {
      const payMethods = this.filterPayMethods(nextProps.payMethods, nextProps.creditCardEnabled);
      this.setAvailablePayMethod(payMethods);
      this.setState({ payMethods });
    }
  }

  handleSavedPayMethod(nextProps) {
    if (nextProps.savedPayMethod) {
      this.props.clearSavedPayMethod();

      this.props.onChange({
        pay_method_id: nextProps.savedPayMethod.id
      });

      this.setState({
        isPayMethodModalOpen: false
      }, this.validateForm);
    }
  }

  handleNewPayMethodChange(data) {
    this.setState({
      newPayMethod: data.newPayMethod,
      isNewPayMethodValid: data.isNewPayMethodValid
    }, this.validateForm);
  }

  handleAmountChange(amount) {
    const account = this.props.account || {};
    const possibleDueAmounts = [account.due_amount, account.payment_amount]
      .map((amount) => (+amount).toFixed(2));
    const different_amount = !possibleDueAmounts.includes(amount.toFixed(2));
    const amount_reason = different_amount ? this.props.payment.amount_reason : '';

    this.props.onChange({
      amount,
      different_amount,
      amount_reason
    });
  }

  handleDateChange(date) {
    const newScheduledDate = moment(date).format('YYYY-MM-DD');
    this.props.onChange({
      scheduled_date: newScheduledDate
    });
    this.props.updatePayment({
      ...this.props.payment,
      scheduled_date: newScheduledDate
    });
  }

  handleFieldChange(e) {
    this.props.onChange({
      [e.currentTarget.name]: e.currentTarget.value
    });
  }

  setDefaults(payMethods) {
    const payment = {};

    if (!this.props.payment.amount) {
      payment.amount = this.getDefaultAmount();
    }

    if (!this.props.payment.scheduled_date && this.props.config.default_date_enabled) {
      payment.scheduled_date = this.props.minDate;
    }

    if (!this.props.payment.pay_method_id || !(this.props.payment.pay_method && this.props.payment.pay_method.active)) {
      payment.pay_method_id = payMethods.length ? this.getDefaultPayMethodId(payMethods) : '';
    }

    this.props.onChange({
      ...payment
    });
  }

  getDefaultPayMethodId(payMethods) {
    let defaultPayMethod = payMethods.find((payMethod) => {
      return payMethod.default;
    });

    defaultPayMethod = defaultPayMethod || payMethods[0];

    return defaultPayMethod ? defaultPayMethod.id : null;
  }

  getDefaultAmount() {
    const account = this.props.account || {};
    const amount = account.due_amount || account.payment_amount || 0;
    return parseFloat(amount);
  }

  setAvailablePayMethod(payMethods) {
    if (!payMethods) return;
    const isAvailable = payMethods.find((p) => p.id == this.props.payment.pay_method_id);
    if (!isAvailable) {
      const newPayMethodId = payMethods.length ? payMethods[0].id : '';
      this.props.onChange({
        pay_method_id: newPayMethodId
      });
    }
  }

  getPayMethods() {
    if (this.props.payMethods) {
      return this.filterPayMethods(this.props.payMethods, this.props.creditCardEnabled);
    }
    else {
      return [];
    }
  }

  getPayMethod() {
    if (this.props.payMethod) {
      return this.props.payMethod;
    }
    else {
      return this.props.payment ? this.props.payment.pay_method : null;
    }
  }

  filterPayMethods(payMethods, creditCardEnabled) {
    if (!creditCardEnabled) {
      return payMethods.filter((p) => {
        return !p.is_credit_card;
      });
    }
    else {
      return payMethods;
    }
  }

  getFormGroupClass() {
    const classes = ['form-group'];
    if (!this.props.verticalLayout) classes.push('row');
    return classes.join(' ');
  }

  getLabelClass() {
    return this.props.verticalLayout ? '' : `col-form-label ${this.props.labelClass}`;
  }

  getFieldClass() {
    return this.props.verticalLayout ? '' : 'col-md-6';
  }

  stepClass(step) {
    const currentStep = this.state.currentStep;
    let classes = ['step', 'btn'];
    if (currentStep == step) classes.push('active');
    else if (currentStep < step) classes.push('disabled');
    if (currentStep > step && currentStep == this.state.lastStep) classes.push('disabled');
    return classes.join(' ');
  }

  stepLink(step, label) {
    return (
      <Link className={this.stepClass(step)}>
        <h1>
          {label}
        </h1>
        <span alt="" className="step-indicator">
          <span alt="" className="step-number">
            {step}
          </span>
        </span>
      </Link>
    );
  }

  steps() {
    if (this.props.showSteps) {
      return (
        <div className="steps-progress btn-group btn-group-justified btn-group-lg d-flex">
          {this.stepLink(1, this.props.i18n.common.details)}
          {this.stepLink(2, this.props.i18n.common.confirm)}
          {this.stepLink(3, this.props.i18n.common.done)}
        </div>
      );
    }
  }

  getSelectedPayMethod(payment) {
    if (payment.pay_method_id) {
      return this.props.payMethods.find((payMethod) => {
        return payMethod.id == payment.pay_method_id;
      });
    }
    else {
      return this.state.newPayMethod;
    }
  }

  isDateValid() {
    const blackoutConfig = this.props.config.payment_blackout_config;
    const blackoutDates = blackoutConfig.blackout_dates;
    const date = this.getPaymentDate();

    if (!date) return true;
    const dateString = date.format().substring(0,10);

    if (blackoutDates.length > 0 && this.state.lockdownDate) {
      return !blackoutDates.includes(dateString);
    } else {
      return true;
    }
  }

  isDateLocked(props) {
    const selectedPayMethod = this.getSelectedPayMethod(props.payment);
    return this.isPlastiq(selectedPayMethod) || this.props.config.same_day_payments_enforced;
  }

  isPlastiq(selectedPayMethod) {
    return selectedPayMethod && selectedPayMethod.type == "PlastiqPayMethod";
  }

  validateForm(props) {
    props = props || this.props;

    let billsValid = true;

    if (props.config.enforce_due_date) {
      billsValid = this.props.areBillsInDateRage();
      this.setState({ billsValid });
    }

    const payment = props.payment;

    let selectedPayMethod;
    let selectedPayMethodIsExpired;

    if (payment.pay_method_id) {
      selectedPayMethod = this.getSelectedPayMethod(payment);
      if (selectedPayMethod && selectedPayMethod.is_expired) {
        selectedPayMethodIsExpired = selectedPayMethod.is_expired;
      }
    }

    const amount = props.config.fees_enabled ? payment.subtotal : payment.amount;
    const amountValid = amount && amount > 0;
    const negativeAmount = amount && amount < 0;
    const dateValid = payment.scheduled_date;
    const payMethodValid = (selectedPayMethod && !selectedPayMethodIsExpired) || this.state.isNewPayMethodValid;
    const commentValid = !props.commentRequired || payment.otp_comment;
    const splitsValid = props.splitsValid;
    const lockdownDate = this.isDateLocked(props);
    const amountReasonPresent = !props.config.different_amount_reason_required ||
      !props.payment.different_amount ||
      !!props.payment.amount_reason;
    const splitNotUpdating = !props.splitUpdating;

    const isValid =
      amountValid &&
      dateValid &&
      payMethodValid &&
      commentValid &&
      splitsValid &&
      billsValid &&
      amountReasonPresent &&
      splitNotUpdating;

    this.setState({
      isValid,
      negativeAmount,
      splitsValid,
      lockdownDate,
      selectedPayMethodIsExpired
    });
  }

  handleContinue($event) {
    $event.preventDefault();
    if (this.state.isValid) {
      if (this.props.onContinue) {
        this.props.onContinue(this.props.payment);
      }
      if (!this.props.disableContinue) {
        this.props.clearErrors();
        this.setState({ currentStep: 2 });
      }
    }
  }

  handleSubmit($event) {
    $event.preventDefault();
    const { config, payment } = this.props;
    const selectedPayMethod = this.getSelectedPayMethod(payment);
    const plastiqConfirmationEnabled = config.pay_method_config.plastiq_confirmation_notice_enabled;

    if (this.isPlastiq(selectedPayMethod) && plastiqConfirmationEnabled) {
      this.togglePlastiqConfirmationModal();
    } else {
      this.plastiqConfirmation();
    }
  }

  formErrors() {
    if (this.props.showErrors) {
      return (
        <FormErrors
          errors={this.props.errors}
          dismiss={this.props.clearErrors} />
      );
    }
  }

  headingText() {
    if (this.props.oneTimePayment) {
      const heading = `${this.props.i18n.portal.payer.payment.payment_details}`;
      return heading;
    } else {
      const canManangePayments = this.props.abilities.manage_portal_payment && (this.state.payMethods && this.state.payMethods.length > 0) || this.props.abilities.manage_pay_method;
      const heading = canManangePayments ? `${this.props.i18n.portal.payer.payment.payment_details}` : `${this.props.content.no_pay_method_notice}`;
      return heading;
    }
  }

  formHeading() {
    return (
      <h4 className="centered lead">
        {this.headingText()}
      </h4>
    );
  }

  userDetails() {
    if (this.props.showUserDetails) {
      return (
        <UserDetails
          i18n={this.props.i18n}
          account={this.props.account}
          content={this.props.content}
          fields={this.props.config.feature.portal_ach_form_fields} />
      );
    }
  }

  accountDetails() {
    if (this.props.account && !this.props.config.feature.bill_search_enabled) {
      const showNotice = this.props.oneTimePayment ? true : false;
      return (
        <section className={this.props.accountDetailsClass}>
          <h1 className="sr-only">{this.props.i18n.portal.ada_headers.account_details}</h1>
          <div className="card-body">
            <AccountDetails
              showNotice={showNotice}
              content={this.props.content}
              showLabel
              account={this.props.account}
              labelConfig={this.props.config.feature}
              config={this.props.config} />
          </div>
        </section>
      );
    }
  }

  warningTargetClass(stateField, initialClass, warningClass) {
    const fieldClass = stateField ? warningClass : initialClass;
    return `${fieldClass}`;
  }

  amountField() {
    if (this.props.showAmountField) {
      return (
        <div className={this.state.formGroupClass}>
          <label className={this.state.labelClass} htmlFor="amount">
            {this.props.i18n.common.amount}
          </label>
          <div className={this.state.fieldClass}>
            <PaymentAmountInput
              value={this.props.payment.amount}
              onChange={this.handleAmountChange}
              account={this.props.account}
              config={this.props.config} />
          </div>
        </div>
      );
    }
  }

  amountReasonField() {
    if (
      this.props.config.different_amount_reason_required &&
      this.props.payment.different_amount
    ) {
      const amountReason = this.props.payment.amount_reason || '';
      const errorClass = amountReason ? '' : 'has-error';
      const placeholder = this.props.i18n.portal.payment.amount_reason_instruction;

      return (
        <div className={`${this.state.formGroupClass} ${errorClass}`}>
          <label className={this.state.labelClass} htmlFor="amount_reason">
            {this.props.i18n.portal.payment.amount_reason_label}
          </label>
          <div className={this.state.fieldClass}>
            <input
              className="form-control"
              name="amount_reason"
              type="text"
              placeholder={placeholder}
              onChange={this.handleFieldChange}
              value={amountReason}
            />
          </div>
        </div>
      );
    }
  }

  dateField() {
    const date = this.getPaymentDate();
    const blackoutConfig = this.props.config.payment_blackout_config;
    return (
      <div className={this.state.formGroupClass}>
        <label className={this.state.labelClass} htmlFor="payment_date">
          {this.props.i18n.portal.payer.payment.payment_date}
        </label>
        <div className={this.state.fieldClass}>
          <DatePicker
            selected={date}
            disabled={this.state.lockdownDate}
            value={date}
            onChange={this.handleDateChange}
            minDate={this.props.minDate}
            maxDate={this.props.maxDate}
            maxRange={blackoutConfig.max_range}
            excludeDates={blackoutConfig.blackout_dates}
            customInput={this.datePickerInput()}
            excludeWeekends={blackoutConfig.blackout_weekends} />
        </div>
      </div>
    );
  }

  getPaymentDate() {
    if (this.state.lockdownDate) {
      return moment();
    }
    else if (this.props.payment.scheduled_date) {
      return moment(this.props.payment.scheduled_date);
    }
  }

  datePickerInput() {
    return (
      <DatePickerInput
        showNotice
        for={"payment_date"}
        showCalendar={!this.state.lockdownDate}
        disabled={this.state.lockdownDate}
        content={this.props.content}
        noticeSource={this.props.content.payment_date_notice}
      />
    );
  }

  payMethodField() {
    if (!this.props.newPayMethodMode && this.state.payMethods) {
      return (
        <div className={this.state.formGroupClass}>
          <label className={this.state.labelClass} htmlFor="pay_method_id">
            {this.props.content.pay_method_label}
          </label>
          <div className={this.state.fieldClass}>
            {this.payMethodSelect()}
          </div>
        </div>
      );
    }
  }

  payMethodSelect() {
    const className = this.newPayMethodButtonEnabled() ? 'input-group' : '';

    return (
      <div className={className}>
        <select
          id="pay-method-select"
          className="custom-select"
          name="pay_method_id"
          value={this.props.payment.pay_method_id || ''}
          onChange={this.handleFieldChange}>
          {this.payMethodOptions()}
        </select>
        {this.newPayMethodButton()}
      </div>
    );
  }

  payMethodOptions() {
    return this.state.payMethods.map((payMethod, i) =>
      <option key={payMethod.id} value={payMethod.id}>
        {payMethod.details}
      </option>
    );
  }

  enabledTypesLength() {
    const achEnabled = this.props.config.pay_method_config.ach_enabled;
    const checkEnabled = this.props.config.pay_method_config.check_enabled;
    const creditCardEnabled = this.props.config.pay_method_config.cc_enabled;
    const plastiqEnabled = this.props.plastiqEnabled;
    const enabledTypes = [achEnabled, checkEnabled, creditCardEnabled, plastiqEnabled];
    return enabledTypes.filter((enabled) => enabled).length;
  }

  newPayMethodButtonEnabled() {
    return this.props.abilities.manage_pay_method && this.enabledTypesLength() > 0;
  }

  newPayMethodButton() {
    if (this.newPayMethodButtonEnabled()) {
      return (
        <div className="input-group-append">
          <Link
            icon="plus-circle"
            className="btn btn-outline-primary"
            onClick={this.togglePayMethodModal}
          />
        </div>
      );
    }
  }

  payMethodForm() {
    if (this.props.newPayMethodMode) {
      return (
        <PayMethodForm
          oneTimePayment={this.props.oneTimePayment}
          ref="PayMethodForm"
          newPayMethod={this.state.newPayMethod}
          creditCardEnabled={this.props.creditCardEnabled}
          plastiqEnabled={this.props.plastiqEnabled}
          showNicknameField={this.props.showNicknameField}
          onChange={this.handleNewPayMethodChange}
          onError={this.props.onPayMethodError}
          clearErrors={this.props.clearErrors}
          labelClass={this.state.labelClass}
          fieldClass={this.state.fieldClass}
          i18n={this.props.i18n}
          content={this.props.content}
          config={this.props.config}
          abilities={this.props.abilities}
          account={this.props.account}
          stripeEnabled={this.props.config.pay_method_config.stripe_enabled}
          createStripeIntent={this.props.createStripeIntent}
          stripeIntent={this.props.stripeIntent} />
      );
    }
  }

  registerNewPayMethod(onSuccess) {
    this.refs.PayMethodForm.registerNewPayMethod(onSuccess);
  }

  documentsField() {
    if (this.state.paymentDocs.length > 0) {
      return (
        <div className={this.state.formGroupClass}>
          <label className={this.state.labelClass} htmlFor="documents">
            {this.props.i18n.common.documents}
          </label>
          <div className="well docs-well">
            <ul className="docs-list payment-form-docs-list">
              {this.fileListItem()}
            </ul>
          </div>
        </div>
      );
    }
  }

  removePortalDoc(doc) {
    this.props.deletePortalDocument(this.props.payment, doc);
  }

  fileListItem() {
    return this.state.paymentDocs.map((doc, i) => {
      const handleRemove = this.removePortalDoc.bind(this, doc);
      return (
        <li key={i} className="portal-payment-file-container">
          <div className="portal-payment-file-name">
            {doc.display_name.split("\n").map((text, i) => {
              return <div className="portal-payment-file-name-contents" key={i}>{text}</div>;
            })}
          </div>
          <Link
            className="remove-portal-doc"
            onClick={handleRemove}
            tabIndex="-1"
            icon="times-circle"
          />
        </li>
      );
    });
  }

  commentField() {
    if (this.props.commentRequired) {
      return (
        <div className={this.state.formGroupClass}>
          <label className={this.state.labelClass} htmlFor="otp_comment">
            {this.props.i18n.common.comment}
          </label>
          <div className={this.state.fieldClass}>
            <textarea
              className="form-control"
              name="otp_comment"
              rows={3}
              onChange={this.handleFieldChange} />
          </div>
        </div>
      );
    }
  }

  payMethodExpiredMessage() {
    return (
      <WarningMessage
        showWarning={this.state.selectedPayMethodIsExpired}
        warningType={"danger"}
        alignment={"center"}
        message={this.props.i18n.portal.payer.payment.pay_method_is_expired} />
    );
  }

  negativeAmountMessage() {
    return (
      <WarningMessage
        showWarning={this.state.negativeAmount}
        warningType={"danger"}
        alignment={"center"}
        message={this.props.i18n.portal.payer.payment.negative_amount_message} />
    );
  }

  discountDateWarning() {
    return (
      <WarningMessage
        showWarning={!this.state.billsValid}
        warningType={"danger"}
        alignment={"center"}
        message={this.props.i18n.portal.payer.payment.due_date_warning} />
    );
  }

  amountDiffersWarning() {
    return (
      <WarningMessage
        showWarning={this.state.splitsValid === false}
        warningType={"danger"}
        alignment={"center"}
        message={this.props.i18n.portal.payer.payment.splits_invalid} />
    );
  }

  expiredDiscountWarning() {
    return (
      <WarningMessage
        showWarning={this.props.hasExpiredDiscount}
        warningType={"danger"}
        alignment={"center"}
        message={this.props.i18n.portal.payer.payment.expired_discount} />
    );
  }

  blackedOutDateWarning() {
    return (
      <WarningMessage
        showWarning={!this.isDateValid()}
        warningType={"danger"}
        alignment={"center"}
        message={this.props.i18n.portal.payer.payment.cant_be_on_holiday} />
    );
  }

  paymentBalance() {
    if (this.props.showBalance) {
      const { fees_enabled } = this.props.config;
      const payment = this.props.payment;
      const amount = fees_enabled ? payment.subtotal : payment.amount;
      const label = fees_enabled ? 'payment_subtotal' : 'payment_total';

      return (
        <div className="payment-balance-panel">
          <h4 className="centered lead">
            {this.props.i18n.portal.payer.payment.payment_balance}
          </h4>
          <table className="payment-balance-table">
            <tbody>
              <tr>
                <td className="total-label">
                  {this.props.i18n.portal.payer.payment[label]}
                </td>
                <td className={this.warningTargetClass(this.state.negativeAmount, 'total right', 'total right text-danger')}>
                  {this.amountValue(amount)}
                </td>
              </tr>
            </tbody>
          </table>
          {this.payMethodExpiredMessage()}
          {this.expiredDiscountWarning()}
          {this.negativeAmountMessage()}
          {this.discountDateWarning()}
          {this.amountDiffersWarning()}
          {this.blackedOutDateWarning()}
        </div>
      );
    }
  }

  amountValue(amount) {
    if (this.props.splitUpdating) {
      return <Spinner size="small" />;
    } else {
      return <div> {format.currency(amount)} </div>;
    }
  }

  formActions() {
    return (
      <div className="form-actions">
        <input
          className="btn primary-btn btn-lg"
          name="commit"
          type="submit"
          value={this.props.i18n.common.continue}
          disabled={!this.state.isValid} />
      </div>
    );
  }

  detailsSection() {
    const hiddenClass = this.state.currentStep == 1 ? '' : 'd-none';

    return (
      <form
        onSubmit={this.handleContinue}
        className={`payment-form form-themed ${hiddenClass}`}
        name="paymentForm"
        noValidate="noValidate">
        {this.formHeading()}
        {this.detailsSectionContent()}
      </form>
    );
  }

  detailsSectionContent() {
    const canManangePayments = this.props.abilities.manage_portal_payment && (this.state.payMethods && this.state.payMethods.length > 0) || this.props.abilities.manage_pay_method;
    if (this.props.oneTimePayment || canManangePayments) {
      return (
        <div>
          {this.userDetails()}
          {this.accountDetails()}
          {this.amountField()}
          {this.amountReasonField()}
          {this.dateField()}
          {this.payMethodField()}
          {this.payMethodForm()}
          {this.commentField()}
          {this.documentsField()}
          {this.paymentBalance()}
          {this.formActions()}
        </div>
      );
    }
  }

  editDetails() {
    this.setState({ currentStep: 1 });
  }

  confirmSection() {
    if (this.state.currentStep == 2) {
      const payment = this.props.payment;
      const translations = this.props.i18n.portal.payer.payment;
      const confirmText = translations.confirm_payment_details;
      const detailsText = translations.payment_details;
      const submitText = translations.submit_payment;
      const plastiqText = translations.continue_to_plastiq;
      const selectedPayMethod = this.getSelectedPayMethod(payment);
      const buttonText = this.isPlastiq(selectedPayMethod) ? plastiqText : submitText;
      const headerText = this.isPlastiq(selectedPayMethod) ? detailsText : confirmText;
      const paymentAuthorizationEnabled = this.props.config.payment_authorization_checkbox_enabled;
      const paymentNeedsAuthorization = paymentAuthorizationEnabled && !this.state.paymentAuthorizationChecked;
      const submitDisabled = this.props.submitting || paymentNeedsAuthorization;
      return (
        <div className="form-horizontal form-themed">
          <p className="centered lead">
            {headerText}
          </p>

          <PaymentDetails
            {...this.props}
            payment={payment}
            newPayMethod={this.state.newPayMethod} />

          <div className="centered">
            <Button icon="edit" onClick={this.editDetails}>
              {this.props.i18n.portal.payer.payment.edit_details}
            </Button>
          </div>
          {this.paymentAuthorizationContent()}
          <div className="form-actions">
            <Button
              color="primary"
              disabled={submitDisabled}
              onClick={this.handleSubmit}
              size="lg"
            >
              {buttonText}
            </Button>
          </div>
        </div>
      );
    }
  }

  paymentAuthorizationContent() {
    if (!this.props.config.payment_authorization_checkbox_enabled) return null;

    const label = this.props.content.payment_authorization_checkbox_label;
    const content = this.props.content.payment_authorization_checkbox_content;
    return (
      <Checkbox
        name="paymentAuthorizationCheckbox"
        label={label}
        content={content}
        onChange={this.onPaymentAuthChange} />
    );
  }

  onPaymentAuthChange(event) {
    this.setState({ paymentAuthorizationChecked: event.target.checked });
  }

  doneSection() {
    if (this.state.currentStep == 3) {
      return (
        <div className="form-horizontal form-themed">
          <PaymentConfirmationMessage
            payment={this.props.savedPayment}
            content={this.props.content}
          />
          <PaymentDetails
            {...this.props}
            payment={this.props.savedPayment}
          />
          <div className="centered">
            {this.printPaymentButton()}
          </div>
          <div className="form-actions">
            <button className="btn primary-btn btn-lg"
              onClick={this.props.onDone}>
              {this.props.i18n.common.done}
            </button>
          </div>
        </div>
      );
    }
  }

  printPaymentButton() {
    if (!this.props.savedPayment.is_failed) {
      return (
        <Button className="print" icon="print" onClick={this.props.onPrint}>
          {this.props.i18n.common.print}
        </Button>
      );
    }
  }

  payMethodModal() {
    return (
      <PayMethodModal
        newPayMethodMode
        isOpen={this.state.isPayMethodModalOpen}
        toggle={this.togglePayMethodModal}
        onSubmit={this.props.onSubmitPayMethod}
        errors={this.props.payMethodErrors}
        clearErrors={this.props.clearPayMethodErrors}
        onError={this.props.onPayMethodError}
        i18n={this.props.i18n}
        config={this.props.config}
        content={this.props.content}
        abilities={this.props.abilities}
        creditCardEnabled={this.props.creditCardEnabled}
        plastiqEnabled={this.props.plastiqEnabled}
        createStripeIntent={this.props.createStripeIntent}
        stripeIntent={this.props.stripeIntent} />
    );
  }

  plastiqConfirmationModal() {
    const { i18n } = this.props;
    const message = this.props.content.plastiq_confirmation_notice;
    const heading = i18n.common.confirmation;
    const buttonText = i18n.common.continue;
    const action = this.plastiqConfirmation;
    const isOpen = this.state.isPlastiqConfirmationModalOpen;
    const toggle = this.togglePlastiqConfirmationModal;
    return (
      <ConfirmModal
        i18n={this.props.i18n}
        message={message}
        heading={heading}
        buttonText={buttonText}
        action={action}
        isOpen={isOpen}
        toggle={toggle} />
    );
  }

  togglePlastiqConfirmationModal = () => {
    this.setState({
      isPlastiqConfirmationModalOpen: !this.state.isPlastiqConfirmationModalOpen
    });
  }

  togglePayMethodModal = () => {
    this.props.clearPayMethodErrors();
    this.setState({
      isPayMethodModalOpen: !this.state.isPayMethodModalOpen
    });
  }

  plastiqConfirmation = () => {
    if (this.props.newPayMethodMode) {
      this.registerNewPayMethod((newPayMethod) => {
        this.props.onSubmit(this.props.payment, {
          newPayMethod
        });
      });
   } else {
     this.props.onSubmit(this.props.payment);
   }
   this.setState({ paymentAuthorizationChecked: false });
  }

  closePayMethodModal() {
    this.setState({ isPayMethodModalOpen: false });
  }

  render() {
    return (
      <section className="payment-section">
        {this.steps()}
        {this.formErrors()}
        {this.detailsSection()}
        {this.confirmSection()}
        {this.doneSection()}
        {this.payMethodModal()}
        {this.plastiqConfirmationModal()}
      </section>
    );
  }
}

PaymentForm.defaultProps = {
  labelClass: 'col-md-5',
  showErrors: true,
  splitsValid: true
};

PaymentForm.propTypes = {
  abilities: PropTypes.object,
  account: PropTypes.object,
  accountDetailsClass: PropTypes.string,
  areBillsInDateRage: PropTypes.func,
  clearDocsAdded: PropTypes.func,
  clearErrors: PropTypes.func,
  clearPayMethodErrors: PropTypes.func,
  clearSavedPayMethod: PropTypes.func,
  commentRequired: PropTypes.bool,
  config: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  createStripeIntent: PropTypes.func,
  creditCardEnabled: PropTypes.bool,
  deletePortalDocument: PropTypes.func,
  disableContinue: PropTypes.bool,
  errors: PropTypes.array,
  hasExpiredDiscount: PropTypes.bool,
  i18n: PropTypes.object.isRequired,
  labelClass: PropTypes.string,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  newPayMethod: PropTypes.object,
  newPayMethodMode: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onContinue: PropTypes.func,
  onDone: PropTypes.func,
  oneTimePayment: PropTypes.bool,
  onPayMethodError: PropTypes.func,
  onPrint: PropTypes.func,
  onSubmit: PropTypes.func,
  onSubmitPayMethod: PropTypes.func,
  payment: PropTypes.object,
  payMethod: PropTypes.object,
  payMethodErrors: PropTypes.array,
  payMethods: PropTypes.array,
  plastiqEnabled: PropTypes.bool,
  savedPayment: PropTypes.object,
  savedPayMethod: PropTypes.object,
  showAmountField: PropTypes.bool,
  showBalance: PropTypes.bool,
  showErrors: PropTypes.bool,
  showNicknameField: PropTypes.bool,
  showSteps: PropTypes.bool,
  showUserDetails: PropTypes.bool,
  splitUpdating: PropTypes.bool,
  splitsValid: PropTypes.bool,
  stripeEnabled: PropTypes.bool,
  stripeIntent: PropTypes.string,
  submitting: PropTypes.bool,
  updatePayment: PropTypes.func.isRequired,
  updatePaymentAmount: PropTypes.func,
  verticalLayout: PropTypes.bool,
};

export default PaymentForm;
