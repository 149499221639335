import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Checkbox extends Component {
  render() {
    return (
      <div className="centered checkbox-area">
        <input
          type="checkbox"
          name={this.props.name}
          id="checkbox"
          onChange={this.props.onChange} />
        <label htmlFor="checkbox">
          {this.props.label}
        </label>
        <div>
          {this.props.content}
        </div>
      </div>
    );
  }
}

Checkbox.defaultProps = {
  name: "checkbox",
};

Checkbox.propTypes = {
  content: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

export default Checkbox;
