import React from 'react';
import { PropTypes } from 'prop-types';
import Button from './Button';
import CurrencyInput from 'shared/components/CurrencyInput';
import Filter from './Filter';
import Pagination from 'shared/components/Pagination';
import Link from './Link';
import Spinner from './Spinner';
import format from '../../utils/formatUtils';
import arraySort from 'array-sort';
import deep from 'griddle-react/modules/deep';
import debounce from '../../utils/debounce';

class SplitsTable extends React.Component {

  constructor(props) {
    super(props);

    const unsortableColumns = [];
    const columnAlignment = {
      right: [
        'bills.discount_amount',
        'bills.due_amount',
        'splits.amount'
      ]
    };

    this.state = {
      splits: this.props.splits,
      sortOrder: 'bills.due_date ASC',
      sortAscending: true,
      currentPage: 1,
      unsortableColumns,
      columnAlignment,
      commentValue: '',
      editingCommentSplitId: null,
      amountValue: '',
      editingAmountSplitId: null
    };

    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.onChangeDebounced = debounce(this.onChangeDebounced, 500);
    this.onAmountDebounced = debounce(this.onAmountDebounced, 300);
  }

  componentWillMount() {
    this.applySortAndFilter([...this.props.splits]);
  }

  componentWillReceiveProps(nextProps) {
    this.applySortAndFilter([...nextProps.splits]);
  }

  applySortAndFilter(splits) {
    // apply sort
    if (this.state.sortColumn) {
      splits = this.sortDataAscending(splits, this.state.sortColumn);
      if (!this.state.sortAscending) splits.reverse();
    }

    // apply filter
    if (this.state.filter) {
      splits = this.filterData(splits, this.state.filter);
    }

    // set valid page
    let totalPages = Math.ceil(splits.length / this.props.itemsPerPage);
    let currentPage = this.state.currentPage;

    if (this.state.currentPage > totalPages) {
      currentPage = totalPages;
    }

    this.setState({
      splits,
      currentPage
    });
  }

  filter() {
    return (
      <Filter
        i18n={this.props.i18n}
        placeholder={this.props.i18n.portal.data_table.filter_items}
        onChange={this.props.onFilter} />
    );
  }

  handleFilterChange(filter) {
    const splits = this.filterData(this.props.splits, filter);
    this.setState({ splits, filter });
  }

  filterData(splits, filter) {
    const filteredSplits = splits.filter((item) => {
      const arr = deep.keys(item);
      for (let i = 0; i < arr.length; i++) {
        if ((deep.getAt(item, arr[i]) || "").toString().toLowerCase().indexOf(filter.toLowerCase()) >= 0) {
          return true;
        }
      }
      return false;
    });
    return filteredSplits;
  }

  handleRowClick(split) {
    if (this.props.onRowClick) {
      this.props.onRowClick(split);
    }
  }

  alignmentClass(column) {
    if (this.state.columnAlignment.right.includes(column)) {
      return 'right';
    }
  }

  headerClass(column) {
    return [
      this.alignmentClass(column),
      this.sortClass(column),
    ].filter(className => className).join(' ');
  }

  sortClass(column) {
    if (this.state.sortOrder == `${column} ASC`) {
      return 'sort-asc';
    }
    else if (this.state.sortOrder == `${column} DESC`) {
      return 'sort-desc';
    }
  }

  amountRowValue(split) {
    if (split.loading) {
      return (
        <Spinner
          centered
          className={"loading-icon"} />
      );
    }
    else {
      const editEnabled = this.props.config.split_amount_edit_enabled;
      if ((this.props.entryMode && editEnabled) || (this.props.entryMode && !split.bill)) {
        return this.amountField(split);
      } else {
        return format.currency(split.amount);
      }
    }
  }

  amountField(split) {
    const autoFocus = split.bill_id ? false : true;
    const handleAmountChange = this.handleAmountChange.bind(this, split);
    const handleAmountBlur = this.handleAmountBlur.bind(this, split);
    let amountValue;

    if (this.state.editingAmountSplitId == split.id) {
      amountValue = this.state.amountValue;
    } else {
      amountValue = split.amount;
    }

    return (
      <CurrencyInput
        allowNegative
        autoFocus={autoFocus} // eslint-disable-line jsx-a11y/no-autofocus
        className="amount-field form-control form-control-sm"
        name="payment_amount"
        value={amountValue}
        onClick={this.handleClick}
        onChange={handleAmountChange}
        onBlur={handleAmountBlur}
      />
    );
  }

  handleClick(e) {
    e.stopPropagation();
  }

  handleAmountChange(split, e) {
    let amount = e;
    let negativeAllowed = false;
    if (split.bill && parseFloat(split.bill.due_amount) < 0) {
      negativeAllowed = true;
    }
    if (amount < 0 && !negativeAllowed) amount = Math.abs(amount);
    this.setState(
      {
        amountValue: amount,
        editingAmountSplitId: split.id
      }
    );
    this.onAmountDebounced(amount, split);
  }

  onAmountDebounced(amount, split) {
    if (this.props.onAmountChange) {
      this.props.onAmountChange(split, amount);
    }
  }

  handleAmountBlur(split) {
    if (this.props.onSplitChange) {
      this.setState(
          {
            amountValue: '',
            editingAmountSplitId: null
          }
      );
    }
  }

  billDueDate(bill) {
    let dueLabel = null;
    if (bill.due && this.props.showDueLabel) {
      dueLabel = this.dueLabel();
    }
    return (
      <span>
        {format.date(bill.due_date, this.props.config.date_format_string)}{' '}
        {dueLabel}
      </span>
    );
  }

  dueLabel() {
    return (
      <span className="due badge badge-danger">
        {this.props.content.due_label}
      </span>
    );
  }

  tableHeaders() {
    const columns = this.props.columns;
    return columns.map((column, i) => {
      const handleSortChange = this.handleSortChange.bind(this, column);
      return (
        <th
          key={column}
          className={this.headerClass(column)}
          onClick={handleSortChange}>
          {this.headerLabel(column)}
        </th>
      );
    });
  }

  tableRows() {
    if (this.props.splits) {
      return this.props.splits.map((split, i) => {
        return (
          <tbody key={i}>
            {this.dataRow(split)}
            {this.infoRow(split, `${i}_info`)}
          </tbody>
        );
      });
    }
  }

  dataRow(split) {
    const handleClick = this.handleRowClick.bind(this, split);
    const rowClass = this.getRowClass(split);

    let data = this.props.columns.map((column) => {
      const value = this.rowValue(split, column);
      if (value) {
        return this.tableRowData(split, column, value);
      }
    });

    if (!split.bill) {
      data.splice(data.length - 1, 0, this.payToAccountRowData());
    }

    return (
      <tr className={rowClass} onClick={handleClick}>
        {data.map((rowData) => rowData)}
      </tr>
    );
  }

  payToAccountRowData() {
    const colSpan = this.props.columns.length - 3;
    return (
      <td key="payToAccount" className="centered" colSpan={colSpan}>
        <strong>{this.props.i18n.portal.payer.payment.pay_to_account}</strong>
      </td>
    );
  }

  rowValue(split, column) {
    switch (column) {
      case 'receivable_accounts.external_key':
        return split.bill ? split.bill.account_number : split.receivable_account.external_key;
      case 'payers.name':
        return split.bill ? split.bill.account_name : split.receivable_account.payer.name;
      case 'bills.due_date':
        return split.bill ? this.billDueDate(split.bill) : null;
      case 'bills.external_type':
        return split.bill ? split.bill.external_type : null;
      case 'bills.external_key':
        return split.bill ? this.billExternalKey(split.bill) : null;
      case 'bills.discount_amount':
        return split.bill ? format.currency(split.bill.discount_amount) : null;
      case 'bills.due_amount':
        return split.bill ? format.currency(split.bill.due_amount) : null;
      case 'splits.amount':
        return this.amountRowValue(split);
    }
  }

  billExternalKey(bill) {
    if (this.props.showBillExternalKeyAsLink) {
      const handleExternalKeyClick = this.handleExternalKeyClick.bind(this, bill);
      return (
        <Link onClick={handleExternalKeyClick}>
          {bill.external_key}
        </Link>
      );
    }
    else {
      return bill.external_key;
    }
  }

  handleExternalKeyClick(bill, e) {
    if (this.props.onBillExternalKeyClick) {
      e.stopPropagation();
      this.props.onBillExternalKeyClick(bill.id);
    }
  }

  tableRowData(split, column, value) {
    return (
      <td key={column} className={this.getCellClass(split, column)}>
        {value}
      </td>
    );
  }

  infoRow(split, key) {
    if (this.showAmountReason(split)) {
      return (
        <tr key={key} className="info-td">
          <td colSpan={999}>
            {this.commentField(split)}
            {this.comment(split)}
          </td>
        </tr>
      );
    }
  }

  showAmountReason(split) {
    if (split.bill) {
      if (this.props.config.different_amount_reason_required) {
        if (this.props.entryMode && split.bill) {
          return split.amount != this.props.billAmount(split.bill);
        }
        else {
          return split.amount_reason;
        }
      }
    } else {
      if (this.props.config.pay_to_account_comment_required) {
        if (this.props.entryMode) {
          return true;
        }
        else {
          return split.pay_to_account_comment;
        }
      }
    }
  }

  commentField(split) {
    if (this.props.entryMode) {
      const fieldName = split.bill ? "amount_reason" : "pay_to_account_comment";
      const commentExists = (split.amount_reason || split.pay_to_account_comment);
      const errorClass = commentExists ? '' : 'has-error';
      const handleCommentChange = this.handleCommentChange.bind(this, split);
      const handleCommentBlur = this.handleCommentBlur.bind(this, split);
      const placeholder = split.bill ? this.props.i18n.portal.payment.amount_reason_instruction : this.props.content.pay_to_account_comment_instructions;
      let commentValue;

      if (this.state.editingCommentSplitId == split.id) {
        commentValue = this.state.commentValue;
      } else {
        commentValue = commentExists || '';
      }

      return (
        <span className={`form-group ${errorClass}`}>
          <input
            className="form-control form-control-sm"
            name={fieldName}
            type="text"
            placeholder={placeholder}
            onChange={handleCommentChange}
            value={commentValue}
            onBlur={handleCommentBlur}
          />
        </span>
      );
    }
  }

  handleCommentChange(split, e) {
    const reason = e.target.value;
    this.setState(
      {
        commentValue: reason,
        editingCommentSplitId: split.id
      }
    );
    this.onChangeDebounced(reason, split);
  }

  onChangeDebounced(reason, split) {
    if (split.bill && this.props.onAmountReasonChange) {
      this.props.onAmountReasonChange(split, reason);
    }
    else if (this.props.onAccountCommentChange) {
      this.props.onAccountCommentChange(split, reason);
    }
  }

  handleCommentBlur(split) {
    if (this.props.onSplitChange) {
      this.setState(
        {
          commentValue: '',
          editingCommentSplitId: null
        }
      );
      this.props.onSplitChange(split);
    }
  }

  comment(split) {
    const label = split.bill ? this.props.i18n.portal.payment.amount_reason_label : this.props.i18n.common.comment;
    const commentValue = split.bill ? split.amount_reason : split.pay_to_account_comment;
    if (!this.props.entryMode) {
      return (
        <span className="text-muted">
          <span className="badge badge-secondary">
            {label}
          </span>
          {'  '}
          {commentValue}
        </span>
      );
    }
  }

  getPaginatedData() {
    const itemsPerPage = this.props.itemsPerPage;
    const offset = Math.ceil((this.state.currentPage - 1) * itemsPerPage);
    return this.state.splits.slice(offset, itemsPerPage + offset);
  }

  getRowClass(split) {
    if (this.props.isRowSelected) {
      return this.props.isRowSelected(split) ? 'selected' : '';
    }
  }

  getCellClass(split, column) {
    if (column == 'splits.amount') {
      const entryClass = (this.props.entryMode && this.props.config.split_amount_edit_enabled) || (this.props.entryMode && !split.bill) ? 'entry-td' : '';
      return `${this.alignmentClass(column)} ${entryClass}`;
    }
  }

  getTableClass() {
    const selectableClass = this.props.selectable ? 'table-selectable' : '';
    return `table data-table splits-table ${selectableClass}`;
  }

  handleSortChange(column) {
    if (!this.state.unsortableColumns.includes(column)) {
      const sortOrder = this.state.sortOrder;
      let newSortOrder;

      switch (sortOrder) {
        case `${column} ASC`: {
          newSortOrder = `${column} DESC`;
          break;
        }
        case `${column} DESC`: {
          newSortOrder = null;
          break;
        }
        default: {
          newSortOrder = `${column} ASC`;
        }
      }

      this.setState({ sortOrder: newSortOrder });
      this.props.onSort(newSortOrder);
    }
  }

  sortDataAscending(splits, column) {
    switch (column) {
      case 'receivable_accounts.external_key':
        return arraySort(splits, ['bill.external_key', 'receivable_account.external_key']);
      case 'payers.name':
        return arraySort(splits, ['bill.account_name', 'receivable_account.payer.name']);
      case 'bills.due_date':
        return arraySort(splits, 'bill.due_date');
      case 'bills.external_key':
        return arraySort(splits, 'bill.external_key');
      case 'bills.discount_amount':
        return arraySort(splits, 'bill.discount_amount');
      case 'bills.due_amount':
        return arraySort(splits, 'bill.due_amount');
      case 'splits.amount':
        return arraySort(splits, 'amount');
    }
  }

  headerLabel(column) {
    if (column == "splits.amount") {
      return this.props.i18n.common.amount;
    }
    else {
      const columnName = column.replace(/\./, '_');
      return this.props.content[`${columnName}_label`];
    }
  }

  table() {
    const tableClass = this.getTableClass();
    return (
      <div className="card clear">
        <div className="table-responsive">
          <table className={tableClass}>
            <thead>
              <tr>
                {this.tableHeaders()}
              </tr>
            </thead>
            {this.tableRows()}
          </table>
        </div>
        {this.noResults()}
      </div>
    );
  }

  noResults() {
    if (this.props.splits && this.props.data.total_displayed_splits == 0) {
      return (
        <div className="no-results">
          {this.props.i18n.portal.data_table.no_results}
        </div>
      );
    }
  }

  heading() {
    if (this.props.heading) {
      return (
        <h1>{this.props.heading}</h1>
      );
    }
  }

  actions() {
    if (this.props.actions) {
      return (
        <span className="actions-toolbar actions-right">
          {this.props.actions}
        </span>
      );
    }
  }

  pagination() {
    if (this.props.splits) {
      return (
        <Pagination
          totalItems={this.props.data.total_displayed_splits}
          itemsPerPage={this.props.itemsPerPage}
          onPageChange={this.props.onPage}
          currentPage={this.props.data.current_page} />
      );
    }
  }

  handlePageChange(page) {
    this.setState({ currentPage: page.selected });
  }

  header() {
    if (this.props.heading || this.props.actions) {
      return (
        <section className="header-section">
          {this.heading()}
          {this.actions()}
        </section>
      );
    }
  }

  render() {
    return (
      <section className="data-table-section">
        {this.header()}
        {this.filter()}
        {this.table()}
        {this.pagination()}
      </section>
    );
  }
}

SplitsTable.propTypes = {
  billAmount: PropTypes.func,
  data: PropTypes.object,
  splits: PropTypes.array,
  columns: PropTypes.array.isRequired,
  heading: PropTypes.string,
  selectable: PropTypes.bool,
  actions: PropTypes.element,
  showActions: PropTypes.bool,
  onRowClick: PropTypes.func,
  isRowSelected: PropTypes.func,
  onAmountChange: PropTypes.func,
  onAmountReasonChange: PropTypes.func,
  onAccountCommentChange: PropTypes.func,
  onFilter: PropTypes.func,
  onSort: PropTypes.func,
  onPage: PropTypes.func,
  onSplitChange: PropTypes.func,
  itemsPerPage: PropTypes.number.isRequired,
  entryMode: PropTypes.bool,
  amountReasonRequired: PropTypes.bool,
  showBillExternalKeyAsLink: PropTypes.bool,
  onBillExternalKeyClick: PropTypes.func,
  i18n: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  showDueLabel: PropTypes.bool
};

export default SplitsTable;
