import React from 'react';
import { PropTypes } from 'prop-types';
import { DatePicker } from './DatePicker';
import Button from './Button';
import Icon from 'shared/components/Icon';
import Link from './Link';
import Modal from './Modal';
import NoticePopover from './NoticePopover';
import format from '../../utils/formatUtils';
import moment from 'moment';

class Filter extends React.Component {

  constructor(props) {
    super(props);

    this.togglePopover = this.togglePopover.bind(this);
    this.state = {
      isDateModalOpen: false,
      query: this.props.filterQuery || '',
      popoverOpen: false
    };

    this.toggleDateModal = this.toggleDateModal.bind(this);
    this.startDateChanged = this.startDateChanged.bind(this);
    this.endDateChanged = this.endDateChanged.bind(this);
    this.clearDateFilter = this.clearDateFilter.bind(this);
    this.submitDateFilter = this.submitDateFilter.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.filterLabel = this.filterLabel.bind(this);
  }

  componentWillMount() {
    if (this.props.showDateFilter && this.state.query != '') {
      this.parseDates();
    }
  }

  togglePopover() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  parseDates() {
    const dates = this.state.query.split('-');
    const startDate = this.parseDate(dates[0]);
    const endDate = this.parseDate(dates[1]);
    if (startDate.isValid()) this.setState({ startDate });
    if (endDate && endDate.isValid()) this.setState({ endDate });
  }

  parseDate(dateString) {
    if (dateString) {
      return moment(dateString.trim(), "MM/DD/YYYY", true);
    }
  }

  handleChange(e) {
    const query = e.target.value;
    this.setState({ query });
    this.props.onChange(query);
  }

  addFilter() {
    if (this.props.showAddFilter) {
      return (
        <div className="input-group-prepend">
          <Link
            className="add-filter btn btn-outline-primary"
            onClick={this.props.onAddFilter}>
            <Icon type="plus" />
          </Link>
        </div>
      );
    }
  }

  dateFilter() {
    if (this.props.showDateFilter) {
      return (
        <div className="input-group-prepend">
          <Link className="btn btn-outline-primary" onClick={this.toggleDateModal}>
            <Icon type="calendar-alt" />
          </Link>
        </div>
      );
    }
  }

  // --- Date Range Modal
  dateModal() {
    if (this.props.showDateFilter) {
      return (
        <Modal
          isOpen={this.state.isDateModalOpen}
          toggle={this.toggleDateModal}
          title={this.props.i18n.portal.modals.datepicker.title}
          message={this.dateModalContent()}
          actions={this.dateModalActions()} />
      );
    }
  }

  dateModalContent() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-6">
            <DatePicker
              inline
              fixedHeight
              selectsStart
              selected={this.state.startDate}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onChange={this.startDateChanged} />
          </div>
          <div className="col-6">
            <div className="float-right">
              <DatePicker
                inline
                fixedHeight
                selectsEnd
                selected={this.state.endDate}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={this.endDateChanged} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  dateModalActions() {
    return (
      <>
        <Button onClick={this.clearDateFilter}>
          {this.props.i18n.portal.modals.datepicker.clear}
        </Button>
        <Button onClick={this.toggleDateModal}>
          {this.props.i18n.common.cancel}
        </Button>
        <Button color="primary" onClick={this.submitDateFilter}>
          {this.props.i18n.common.submit}
        </Button>
      </>
    );
  }

  startDateChanged(date) {
    this.setState({ startDate: date });
  }

  endDateChanged(date) {
    this.setState({ endDate: date });
  }

  toggleDateModal() {
    this.setState({ isDateModalOpen: !this.state.isDateModalOpen });
  }

  clearDateFilter() {
    this.setState({
      startDate: null,
      endDate: null,
      isDateModalOpen: false,
      query: ''
    });
    this.props.onChange('');
  }

  submitDateFilter() {
    const dates = [];

    if (this.state.startDate) {
      dates.push(format.date(this.state.startDate));
    }
    if (this.state.endDate) {
      dates.push(format.date(this.state.endDate));
    }

    const query = dates.join(' - ');
    this.setState({ isDateModalOpen: false, query });
    this.props.onChange(query);
  }

  searchNotice() {
    if (this.props.showNotice && this.props.noticeSource) {
      return (
        <>
          <div className="input-group-prepend">
            <Link
              className="btn btn-outline-primary"
              onClick={this.togglePopover}
            >
              <span id="SearchNoticePopover">
                <i className="fa icon-help"></i>
              </span>
            </Link>
          </div>
          <NoticePopover
            placement={"top"}
            isOpen={this.state.popoverOpen}
            target={"SearchNoticePopover"}
            toggle={this.togglePopover}
            source={this.props.noticeSource}
            className="filter-search-notice top"
            containerTagName="span" />
        </>
      );
    }
  }

  filterLabels() {
    if (this.props.filterLabels && this.props.filterLabels.length) {
      return (
        <div className="selected-filters">
          {this.props.filterLabels.map(this.filterLabel)}
        </div>
      );
    }
  }

  filterLabel(filter, i) {
    const removeFilter = this.props.onRemoveFilter.bind(this, filter);
    return (
      <span className="filter-label badge badge-pill badge-primary" key={i}>
        {filter.label}
        <Link
          className="label-close"
          onClick={removeFilter}>
          <Icon type="times-circle" />
        </Link>
      </span>
    );
  }

  render() {
    return (
      <section className="section-search">
        <h1 className="sr-only">{this.props.i18n.portal.ada_headers.search_bar}</h1>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <Icon type="filter" />
            </span>
          </div>
          {this.searchNotice()}
          {this.addFilter()}
          {this.dateFilter()}
          <input
            className="form-control"
            type="search"
            id="search-bar"
            placeholder={this.props.placeholder}
            value={this.state.query}
            onChange={this.handleChange} />
        </div>
        {this.dateModal()}
        {this.filterLabels()}
      </section>
    );
  }
}

Filter.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onAddFilter: PropTypes.func,
  showDateFilter: PropTypes.bool,
  showAddFilter: PropTypes.bool,
  noticeSource: PropTypes.string,
  showNotice: PropTypes.bool,
  filterQuery: PropTypes.string,
  i18n: PropTypes.object,
  filterLabels: PropTypes.array,
  onRemoveFilter: PropTypes.func
};

export default Filter;
