import { http, apiEndpoint } from 'shared/api';

class ExternalBillAPI {

  static fetchExternalBills(params = {}) {
    return http.get('external_bills', {
      params
    });
  }

  static billsExportUrl(params = {}, format = "csv") {
    const url = apiEndpoint(`external_bills.${format}`);
    const paramsString = $.param(params);
    return `${url}?${paramsString}`;
  }
}

export default ExternalBillAPI;
