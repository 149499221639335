import { http } from 'shared/api';

class EmailConfirmationAPI {
  static create(emailAddress, paymentID) {
    return http.post('email_confirmations', {
      email_address: emailAddress,
      payment_id: paymentID
    });
  }
}

export default EmailConfirmationAPI;
