import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router';
import Modal from '../../components/common/Modal';

export default class SwitchAccountModal extends Component {

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        title={this.props.title}>
        <ul>
          {this.props.divisions.map((division) => {
            return (
              <li key={division.id}>
                <a href={division.link}>
                  {division.name}
                </a>
              </li>
            );
          })}
        </ul>
      </Modal>
    );
  }
}

SwitchAccountModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  divisions: PropTypes.array.isRequired
};
