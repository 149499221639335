import { http } from 'shared/api';

class ReceivableAccountAPI {

  static fetchAccounts(portalUser, params = {}) {
    let url = "receivable_accounts";
    if (portalUser) url = `portal_users/${portalUser.id}/${url}`;
    return http.get(url, {
      params
    });
  }
}

export default ReceivableAccountAPI;
