import React from 'react';
import { PropTypes } from 'prop-types';
import ReactMarkdown from 'react-markdown';

class HelpPage extends React.Component {  // ---

  render() {
    return (
      <div id="help-page">
        <div className="content-main">
          <h1 className="section-heading">
            {this.props.content.help_section_label}
          </h1>
          <div className="card panel-themed">
            <div className="card-body">
              <ReactMarkdown
                source={this.props.content.help_section_content}
                escapeHtml={false}
                className="help-section-content" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HelpPage.propTypes = {
  content: PropTypes.object
};

export default HelpPage;
