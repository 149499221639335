import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class FallbackErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError || this.props.error) {
      return (
        <h2 style={errorStyle}>
          Something went wrong.
        </h2>
      );
    }
    return this.props.children;
  }
}

const errorStyle = {
  textAlign: 'center',
  paddingTop: '15%',
  margin: 0
};

FallbackErrorBoundary.propTypes = {
  children: PropTypes.object,
  error: PropTypes.object,
  info: PropTypes.object
};
