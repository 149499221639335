import React from 'react';
import { PropTypes } from 'prop-types';
import Modal from './Modal';
import Link from './Link';
import Icon from 'shared/components/Icon';
import Pagination from 'shared/components/Pagination';

class BillDocumentsModal extends React.Component {
  constructor(props) {
    super(props);

    this.fetchDocuments = this.fetchDocuments.bind(this);
  }

  documentsList() {
    if (this.props.billDocuments && this.props.billDocuments.documents.length) {
      return (
        <div>
          <ul className="list-unstyled">
            {this.props.billDocuments.documents.map((billDoc) => this.documentItem(billDoc))}
          </ul>
          {this.pagination()}
        </div>
      );
    }
    else {
      return (
        <span className="text-muted">
          {this.props.content.no_documents_found_message}
        </span>
      );
    }
  }

  documentItem(billDocument) {
    const handleDocumentClick = this.handleDocumentClick.bind(this, billDocument);
    return (
      <div key={billDocument.id}>
        <Link className="bill-doc-link" onClick={handleDocumentClick}>
          <Icon type="arrow-alt-circle-down" />{' '}
          {this.props.i18n.portal.bill_document_type[billDocument.document_type]}
        </Link>
      </div>
    );
  }

  handleDocumentClick(billDocument) {
    this.props.onDocumentClick(billDocument);
  }

  fetchDocuments(data) {
    const billID = this.props.billDocuments.documents[0].billable_id;
    this.props.fetchBillDocuments(
      billID,
      {
        page: data.selected
      }
    );
  }

  pagination() {
    return (
      <Pagination
        totalItems={this.props.billDocuments.total_documents}
        itemsPerPage={1}
        onPageChange={this.fetchDocuments} />
    );
  }

  render() {
    return (
      <Modal
        showClose
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        message={this.documentsList()}
        title={this.props.i18n.portal.modals.bill_documents.title}
        className="bill-documents-modal"
        closeLabel={this.props.i18n.common.close} />
    );
  }
}

BillDocumentsModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  billDocuments: PropTypes.object,
  onDocumentClick: PropTypes.func,
  fetchBillDocuments: PropTypes.func,
  content: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired
};

export default BillDocumentsModal;
