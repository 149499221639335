import { http } from 'shared/api';

class RecurrenceAccountAPI {

  static fetchRecurrenceAccounts(recurrence, params = {}) {
    return http.get(`portal_bill_recurrences/${recurrence.id}/recurrence_accounts`, {
      params: params
    });
  }

  static createRecurrenceAccount(recurrence, portalAccount) {
    return http.post(`portal_bill_recurrences/${recurrence.id}/recurrence_accounts`, {
      portal_recurrence_account: {
         portal_account_id: portalAccount.id
       }
    });
  }

  static deleteRecurrenceAccount(recurrence, account) {
    return http.delete(`portal_bill_recurrences/${recurrence.id}/recurrence_accounts/${account.id}`);
  }
}

export default RecurrenceAccountAPI;
