import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router';

class MenuItem extends React.Component {
  containerClasses() {
    const classes = ['nav-item'];
    if (this.props.active) classes.push('active');
    return classes.join(' ');
  }

  render() {
    return (
      <li className={this.containerClasses()}>
        <Link to={this.props.linkTo} className="nav-link" onClick={this.props.onClick}>
          {this.props.linkLabel}
        </Link>
        <div className="link-desc">
          {this.props.linkDescription}
        </div>
      </li>
    );
  }
}

MenuItem.propTypes = {
  active: PropTypes.bool,
  linkTo: PropTypes.string.isRequired,
  linkLabel: PropTypes.string.isRequired,
  linkDescription: PropTypes.string.isRequired,
  itemClass: PropTypes.string,
  onClick: PropTypes.func
};

export default MenuItem;
