import React from 'react';
import { PropTypes } from 'prop-types';
import WarningMessage from './WarningMessage';
import CurrencyInput from 'shared/components/CurrencyInput';
import accounting from 'accounting';

class PaymentAmountInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.handleAmountChange = this.handleAmountChange.bind(this);
  }

  handleAmountChange(amount) {
    const maxAmount = this.getMaxAmount();
    if (maxAmount > 0 && amount > maxAmount) {
      this.setState({
        maxAmount,
        showWarning: true
      });
    }
    else {
      this.setState({showWarning: false});
      this.props.onChange(amount);
    }
  }

  getMaxAmount() {
    const maxPaymentAmount = this.getMaxPaymentAmount();
    const maxDueAmount = this.getMaxDueAmount();
    const maxOverBalance = this.getMaxBalanceAmount();
    const amountLimits = [];
    if (maxPaymentAmount) amountLimits.push(maxPaymentAmount);
    if (maxDueAmount) amountLimits.push(maxDueAmount);
    if (maxOverBalance) amountLimits.push(maxOverBalance);
    return Math.min(...amountLimits);
  }

  getMaxPaymentAmount() {
    if (this.props.config.max_payment_amount) {
      return parseFloat(this.props.config.max_payment_amount);
    }
  }

  getMaxBalanceAmount() {
    if (this.props.account && this.props.account.balance) {
      const balance = parseFloat(this.props.account.balance);
      const balanceTolerance = parseInt(this.props.config.over_balance_tolerance);
      if (balanceTolerance >= 0) {
        const overBalanceAmount = balance * (balanceTolerance / 100);
        return balance + overBalanceAmount;
      }
      else {
        return balance;
      }
    }
  }

  getMaxDueAmount() {
    const duePercentage = parseInt(this.props.config.due_amount_percentage_restriction);
    if (duePercentage >= 0 && this.props.account && this.props.account.due_amount) {
      const dueAmount = parseFloat(this.props.account.due_amount);
      const duePercentageAmount = dueAmount * (duePercentage / 100);
      return dueAmount + duePercentageAmount;
    }
  }

  warning() {
    return (
      <WarningMessage
        warningType={"warning"}
        alignment={"left"}
        showWarning={this.state.showWarning}
        message={`Payment amount cannot exceed ${accounting.formatMoney(this.state.maxAmount)}`} />
    );
  }

  inputClasses() {
    const classes = ['input-group'];
    if (this.state.showWarning) classes.push('has-warning');
    return classes.join(' ');
  }

  render() {
    return (
      <div>
        <div className={this.inputClasses()}>
          <div className="input-group-prepend">
            <span className="input-group-text">$</span>
          </div>
          <CurrencyInput
            className="form-control"
            name="amount"
            value={this.props.value}
            onChange={this.handleAmountChange}
            readOnly={this.props.readOnly}
          />
        </div>
        {this.warning()}
      </div>
    );
  }
}

PaymentAmountInput.defaultProps = {
  readOnly: false,
};

PaymentAmountInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  account: PropTypes.object,
  config: PropTypes.object,
  readOnly: PropTypes.bool.isRequired,
};

export default PaymentAmountInput;
