import React from 'react';
import { PropTypes } from 'prop-types';

class Spinner extends React.Component {

  getSpinnerSize() {
    switch (this.props.size) {
      case "medium":
        return "fa-2x";
      default:
        return "";
    }
  }

  render() {
    const spinnerSize = this.getSpinnerSize();
    const spinnerClass = `fa fa-spinner fa-spin ${spinnerSize} ${this.props.className}`;
    const spinnerIcon = <i className={spinnerClass} />;
    if (this.props.centered) {
      return (
        <div className="centered">
          {spinnerIcon}
        </div>
      );
    }
    else {
      return spinnerIcon;
    }
  }
}

Spinner.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  centered: PropTypes.bool
};

export default Spinner;
