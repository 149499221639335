import { http } from 'shared/api';

class PayMethodAssignmentAPI {

  static fetchPayMethodAssignments(portalUser) {
    return http.get(`portal_users/${portalUser.id}/pay_method_assignments`);
  }

  static createPayMethodAssignment(portalUser, payMethod) {
    return http.post(`portal_users/${portalUser.id}/pay_method_assignments`, {
      pay_method_assignment: {
        pay_method_id: payMethod.id
      }
    });
  }

  static deletePayMethodAssignment(portalUser, payMethod) {
    const id = payMethod.portal_pay_method_assignment.id;
    return http.delete(`portal_users/${portalUser.id}/pay_method_assignments/${id}`);
  }
}

export default PayMethodAssignmentAPI;
