import { http } from 'shared/api';

class PayMethodAPI {

  static fetchPayMethods(params = {}) {
    return http.get("pay_methods", {
      params
    });
  }

  static createPayMethod(payMethod) {
    return http.post(`pay_methods`, {
      pay_method: payMethod
    });
  }

  static updatePayMethod(payMethod, params = {}) {
    return http.put(`pay_methods/${payMethod.id}`, {
      pay_method: params
    });
  }

  static savePayMethod(payMethod, params = {}) {
    const save = payMethod.id ? this.updatePayMethod : this.createPayMethod;
    return save(payMethod, params);
  }

  static deletePayMethod(payMethod) {
    return http.delete(`pay_methods/${payMethod.id}`);
  }

  static createStripeIntent(account) {
    const params = account ?  { receivable_account_id: account.id } : {};
    return http.post('stripe_intents', params);
  }
}

export default PayMethodAPI;
