import { http, apiEndpoint } from 'shared/api';

class OneTimePaymentAPI {

  static new(account) {
    return http.get('one_time_payments/new', {
      params: {
        token: account.encrypted_token
      }
    });
  }

  static createPayment(payment, payMethod, account) {
    return http.post('one_time_payments', {
      new_pay_method: payMethod,
      receivable_account_id: account.id,
      token: account.encrypted_token,
      amount_reason: payment.amount_reason,
      portal_payment: {
        amount: payment.amount,
        scheduled_date: payment.scheduled_date,
        otp_comment: payment.otp_comment
      }
    });
  }

  static exportUrl(payment, format = "pdf") {
    const url = apiEndpoint(`one_time_payments/${payment.id}.${format}`);
    const paramsString = $.param({token: payment.account.encrypted_token});
    return `${url}?${paramsString}`;
  }
}

export default OneTimePaymentAPI;
