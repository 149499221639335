import React from 'react';
import { PropTypes } from 'prop-types';
import Table from '../../common/Table';

class BillRecurrencesTable extends React.Component {

  columnMap() {
    return {
      'pay_methods.id': 'pay_method.details',
      'total_accounts': 'total_accounts'
    };
  }

  columnMetadata() {
    return [
      {
        columnName: "pay_method.details",
        displayName: this.props.content.pay_method_label
      },
      {
        columnName: "total_accounts",
        displayName: "Total Accounts",
        sortable: false
      }
    ];
  }

  render() {
    if (this.props.data) {
      return (
        <Table
          { ...this.props }
          actions={this.props.actions}
          showActions={this.props.showActions}
          results={this.props.data.recurrences}
          totalItems={this.props.data.total_recurrences}
          columnMap={this.columnMap()}
          columnMetadata={this.columnMetadata()}
          className="bill-recurrences-table"
          noDataMessage={this.props.i18n.portal.data_table.no_results} />
      );
    }
    else {
      return null;
    }
  }
}

BillRecurrencesTable.propTypes = {
  data: PropTypes.object,
  results: PropTypes.array,
  i18n: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  showFilter: PropTypes.bool,
  columnMetadata: PropTypes.array,
  filterPlaceholder: PropTypes.string,
  actions: PropTypes.element,
  showActions: PropTypes.bool,
  onRowClick: PropTypes.func,
  isRowSelected: PropTypes.func,
  useExternal: PropTypes.bool,
  externalSetFilter: PropTypes.func,
  externalSetPage: PropTypes.func,
  externalChangeSort: PropTypes.func
};

export default BillRecurrencesTable;
