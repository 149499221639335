import React from 'react';
import { PropTypes } from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from './Button';

class ConfirmModal extends React.Component {

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className="confirm-modal">
        <ModalHeader toggle={this.props.toggle}>
          {this.props.heading}
        </ModalHeader>
        <ModalBody>
          {this.props.message}
        </ModalBody>
        <ModalFooter>
          <Button onClick={this.props.toggle} size="">
            {this.props.i18n.common.close}
          </Button>
          <Button color="danger" onClick={this.props.action} size="">
            {this.props.buttonText}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  heading: PropTypes.string,
  message: PropTypes.node,
  action: PropTypes.func,
  buttonText: PropTypes.string,
  i18n: PropTypes.object.isRequired
};

export default ConfirmModal;
