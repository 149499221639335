import React from 'react';
import { PropTypes } from 'prop-types';
import { DatePicker } from './DatePicker';
import Button from './Button';
import DatePickerInput from './DatePickerInput';
import PaymentAmountInput from '../common/PaymentAmountInput';
import WarningMessage from '../common/WarningMessage';
import { hashHistory } from 'react-router';
import moment from 'moment';
import ordinal from 'ordinal-numbers';
import format from '../../utils/formatUtils';
import Link from './Link';
import NoticePopover from './NoticePopover';
import FormErrors from 'shared/components/FormErrors';
import Checkbox from './Checkbox';

class RecurrenceForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      recurrence: this.getRecurrence(),
      overPaymentAmountLimit: false,
      percentageRestrictionExceeded: false,
      popoverOpen: false,
      selectedPayMethodIsExpired: false,
      paymentAuthorizationChecked: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.togglePopover = this.togglePopover.bind(this);
    this.goToSettings = this.goToSettings.bind(this);
    this.onPaymentAuthChange = this.onPaymentAuthChange.bind(this);
  }

  componentWillMount() {
    this.validateForm();
  }

  getRecurrence() {
    const amount = this.props.account ? this.props.account.payment_amount : 0;
    const newRecurence = this.props.requireAmount ? { amount } : {};
    return this.props.recurrence || newRecurence;
  }

  togglePopover() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  dayOfMonthNotice() {
    if (this.props.content.day_of_month_notice) {
      return (
        <>
          <div className="input-group-append">
            <Link className="btn btn-outline-primary" onClick={this.togglePopover}>
              <span id="DayOfMonthNoticePopover">
                <i className="fa icon-help"></i>
              </span>
            </Link>
          </div>
          <NoticePopover
            placement={"top"}
            isOpen={this.state.popoverOpen}
            target={"DayOfMonthNoticePopover"}
            toggle={this.togglePopover}
            source={this.props.content.day_of_month_notice}
            className="day-of-month-notice top"
            containerTagName="span" />
        </>
      );
    }
  }

  validateForm() {
    let isValid = true;
    const recurrence = this.state.recurrence;

    let selectedPayMethod;
    let selectedPayMethodIsExpired;

    if (recurrence.pay_method_id) {
      selectedPayMethod = this.getSelectedPayMethod();
      if (selectedPayMethod && selectedPayMethod.is_expired) {
        selectedPayMethodIsExpired = selectedPayMethod.is_expired;
      }
    }

    if (this.props.requirePayMethod && !selectedPayMethod && selectedPayMethodIsExpired) {
      isValid = false;
    }
    if (selectedPayMethod && selectedPayMethodIsExpired) {
      isValid = false;
    }
    if (this.props.requireAmount && !recurrence.amount) {
      isValid = false;
    }
    if (this.props.requireDayOfMonth && !recurrence.day_of_month) {
      isValid = false;
    }

    this.setState({
      isValid,
      selectedPayMethodIsExpired
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ paymentAuthorizationChecked: false });
    this.props.onSubmit(this.state.recurrence);
  }

  handleFieldChange(e) {
    let value = null;

    if (e.currentTarget.type == "checkbox") {
      value = e.currentTarget.checked;
    } else {
      value = e.currentTarget.value;
    }

    this.setRecurrence({
      ...this.state.recurrence,
      [e.currentTarget.name]: value
    });
  }

  handleAmountChange(amount) {
    this.setRecurrence({
      ...this.state.recurrence,
      amount
    });
  }

  handleEndDateChange(date) {
    this.setRecurrence({
      ...this.state.recurrence,
      end_date: date
    });
  }

  setRecurrence(recurrence) {
    this.setState({ recurrence }, this.validateForm);
  }

  heading() {
    const action = this.state.recurrence.id ? 'edit_autopay' : 'setup_autopay';
    return (
      <p className="lead centered">
        {this.props.i18n.portal.payer.autopay[action]}
      </p>
    );
  }

  formErrors() {
    return (
      <FormErrors
        errors={this.props.errors}
        dismiss={this.props.clearErrors} />
    );
  }

  amountField() {
    if (this.props.requireAmount) {
      return (
        <div className="form-group row">
          <label htmlFor="amount" className="col-form-label col-sm-5 text-sm-right">
            {this.props.i18n.common.amount}
          </label>
          <div className="col-sm-5">
            <PaymentAmountInput
              value={this.state.recurrence.amount}
              onChange={this.handleAmountChange}
              account={this.props.account}
              config={this.props.config}
              readOnly={this.props.config.lockdown_autopay_amount || false}
            />
          </div>
        </div>
      );
    }
  }

  dayOfMonthFieldClass() {
    const fieldClass = this.props.content.day_of_month_notice ? 'input-group select-input-group' : 'input-group';
    return `${fieldClass}`;
  }

  dayOfMonthField() {
    if (this.props.requireDayOfMonth) {
      return (
        <div className="form-group row">
          <label htmlFor="day_of_month" className="col-form-label col-sm-5 text-sm-right">
            {this.props.i18n.common.day_of_month}
          </label>
          <div className="col-sm-5">
            <div className="day_of_month_select">
              <div className={this.dayOfMonthFieldClass()}>
                {this.dayOfMonthSelect()}
                {this.dayOfMonthNotice()}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  dayOfMonthSelect() {
    return (
      <select
        name="day_of_month"
        value={this.state.recurrence.day_of_month}
        className="custom-select"
        onChange={this.handleFieldChange}>
        <option></option>
        {this.dayOfMonthOptions()}
      </select>
    );
  }

  dayOfMonthOptions() {
    const account = this.props.account;
    const restriction = this.props.config.pay_date_restriction;
    let daysArray = [...Array(32).keys()].splice(1, 31);

    if (account && account.due_date && restriction) {
      daysArray = this.getRestrictedDays(account, restriction);
    }

    return daysArray.map((day) =>
      <option key={day} value={day}>
        {day}
      </option>
    );
  }

  getRestrictedDays(account, restriction) {
    const dueDate = moment(account.due_date);
    const minDate = moment(dueDate).subtract(restriction, 'days');
    const daysArray = [];
    let date = minDate;

    while (!date.isSame(dueDate)) {
      daysArray.push(date.add(1, 'day').date());
    }

    return daysArray;
  }

  payMethodField() {
    return (
      <div className="form-group row">
        <label htmlFor="payMethod" className="col-form-label col-sm-5 text-sm-right">
          {this.props.content.pay_method_label}
        </label>
        <div className="col-sm-5">
          {this.payMethodSelect()}
        </div>
      </div>
    );
  }

  paymentBreakdownField() {
    if (this.props.showPaymentBreakdownOption) {
      return (
        <div className="form-group row">
          <label htmlFor="payment_breakdown_type" className="col-form-label col-sm-5 text-sm-right">
            {this.props.i18n.portal.payer.autopay.payment_breakdown_type}
          </label>
          <div className="checkbox col-sm-5">
            {this.multipleBillsPerPaymentInput()}
            {this.singleBillPerPaymentInput()}
          </div>
        </div>
      );
    }
  }

  singleBillPerPaymentInput() {
    const id = "payment_breakdown_type_single";
    const isChecked = this.state.recurrence.payment_breakdown_type == "single_bill_per_payment";

    return (
      <div className="form-check">
        <input
          id={id}
          type="radio"
          name="payment_breakdown_type"
          value="single_bill_per_payment"
          className="form-check-input"
          onChange={this.handleFieldChange}
          checked={isChecked}
        />
        <label className="form-check-label" htmlFor={id}>
          {this.props.content.single_bill_per_payment_label}
        </label>
      </div>
    );
  }

  multipleBillsPerPaymentInput() {
    const id = "payment_breakdown_type_multiple";
    const isChecked = !this.state.recurrence.payment_breakdown_type || this.state.recurrence.payment_breakdown_type == "multiple_bills_per_payment";

    return (
      <div className="form-check">
        <input
          id={id}
          type="radio"
          name="payment_breakdown_type"
          className="form-check-input"
          value="multiple_bills_per_payment"
          onChange={this.handleFieldChange}
          checked={isChecked}
        />
        <label className="form-check-label" htmlFor={id}>
          {this.props.content.multiple_bills_per_payment_label}
        </label>
      </div>
    );
  }

  payMethodSelect() {
    if (this.props.payMethods && this.props.payMethods.length) {
      return (
        <select
          className="custom-select"
          name="pay_method_id"
          value={this.state.recurrence.pay_method_id}
          onChange={this.handleFieldChange}>
          <option></option>
          {this.payMethodOptions()}
        </select>
      );
    } else {
      return this.newPayMethodButton();
    }
  }

  goToSettings() {
    hashHistory.push('/payer/settings');
  }

  newPayMethodButton() {
    return (
      <Button icon="plus-circle" onClick={this.goToSettings} size="">
        {this.props.i18n.common.add}
      </Button>
    );
  }

  payMethodOptions() {
    return this.props.payMethods.map((payMethod) =>
      <option key={payMethod.id} value={payMethod.id}>
        {payMethod.details}
      </option>
    );
  }

  endDateField() {
    if (this.props.optionalEndDate) {
      const endDate = this.state.recurrence.end_date ? moment(this.state.recurrence.end_date) : null;
      return (
        <div className="form-group row">
          <label htmlFor="end_date" className="col-form-label col-sm-5 text-sm-right">
            {this.props.i18n.common.end_date}{' '}
            ({this.props.i18n.common.optional})
          </label>
          <div className="col-sm-5">
            <DatePicker
              isClearable
              customInput={<DatePickerInput />}
              onChange={this.handleEndDateChange}
              minDate={moment()}
              maxDate={moment().add(365, "days")}
              value={endDate}
              selected={endDate} />
          </div>
        </div>
      );
    }
  }

  onPaymentAuthChange(event) {
    this.setState({ paymentAuthorizationChecked: event.target.checked });
  }

  paymentAuthorizationContent() {
    if (!this.props.config.payment_authorization_checkbox_enabled) return null;

    const label = this.props.content.payment_authorization_checkbox_label;
    const content = this.props.content.payment_authorization_checkbox_content;

    return (
      <Checkbox
        name="paymentAuthorizationCheckbox"
        label={label}
        content={content}
        onChange={this.onPaymentAuthChange} />
    );
  }

  actions() {
    return (
      <footer className="form-actions">
        {this.cancelButton()}{' '}
        {this.submitButton()}
      </footer>
    );
  }

  submitButton() {
    const paymentAuthorizationEnabled = this.props.config.payment_authorization_checkbox_enabled;
    const paymentNeedsAuthorization = paymentAuthorizationEnabled && !this.state.paymentAuthorizationChecked;
    const submitDisabled = this.props.disabled || !this.state.isValid || paymentNeedsAuthorization;

    return (
      <input
        type="submit"
        className="btn primary-btn btn-lg"
        value={this.props.i18n.common.submit}
        disabled={submitDisabled} />
    );
  }

  cancelButton() {
    if (this.props.allowCancel) {
      return (
        <button className="btn btn-link" onClick={this.props.onCancel}>
          {this.props.i18n.common.cancel}
        </button>
      );
    }
  }

  confirmation() {
    if (this.props.showConfirmation && this.state.isValid) {
      return (
        <div className="confirm-recurrence centered">
          <hr />
          <h4 className="lead">
            {this.props.i18n.portal.payer.payment_settings.confirm_autopay}
          </h4>
          <p>
            {this.amountConfirmation()}{' '}
            {this.accountConfirmation()}{' '}
            {this.payMethodConfirmation()}{' '}
            {this.dayOfMonthConfirmation()}{' '}
            {this.endDateConfirmation()}
          </p>
        </div>
      );
    }
  }

  amountConfirmation() {
    return (
      <span>
        {this.props.i18n.portal.payer.payment_settings.auto_pay}{' '}
        <strong>{format.currency(this.state.recurrence.amount)}</strong>
      </span>
    );
  }

  accountConfirmation() {
    return (
      <span>
        {this.props.i18n.common.to}{' '}
        <strong>{this.props.account.account_label}</strong>
      </span>
    );
  }

  payMethodConfirmation() {
    const selectedPayMethod = this.getSelectedPayMethod();
    if (selectedPayMethod) {
      return (
        <span>
          {this.props.i18n.common.from}{' '}
          <strong>{selectedPayMethod.details}</strong>
        </span>
      );
    }
  }

  getSelectedPayMethod() {
    return this.props.payMethods.find((payMethod) => {
      return payMethod.id == this.state.recurrence.pay_method_id;
    });
  }

  dayOfMonthConfirmation() {
    return (
      <span>
        {this.props.i18n.portal.payer.payment_settings.every_month_on_the}{' '}
        <strong>{ordinal(parseFloat(this.state.recurrence.day_of_month))}</strong>
      </span>
    );
  }

  endDateConfirmation() {
    if (this.state.recurrence.end_date) {
      return (
        <span>
          <span>{this.props.i18n.common.ending_on}</span>{' '}
          <strong>{format.date(this.state.recurrence.end_date)}</strong>
        </span>
      );
    }
  }

  payMethodExpiredMessage() {
    return (
      <WarningMessage
        showWarning={this.state.selectedPayMethodIsExpired}
        warningType={"danger"}
        alignment={"center"}
        message={this.props.i18n.portal.payer.payment.pay_method_is_expired} />
    );
  }

  render() {
    return (
      <form
        name="recurrenceForm"
        className="form-themed recurrence-setup-form"
        onSubmit={this.handleSubmit}>
        {this.formErrors()}
        {this.heading()}
        {this.amountField()}
        {this.payMethodField()}
        {this.paymentBreakdownField()}
        {this.dayOfMonthField()}
        {this.endDateField()}
        {this.confirmation()}
        {this.payMethodExpiredMessage()}
        {this.paymentAuthorizationContent()}
        {this.actions()}
      </form>
    );
  }
}

RecurrenceForm.propTypes = {
  disabled: PropTypes.bool,
  recurrence: PropTypes.object,
  account: PropTypes.object,
  payMethods: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  showConfirmation: PropTypes.bool,
  requirePayMethod: PropTypes.bool,
  requireAmount: PropTypes.bool,
  requireDayOfMonth: PropTypes.bool,
  optionalEndDate: PropTypes.bool,
  allowCancel: PropTypes.bool,
  content: PropTypes.object.isRequired,
  config: PropTypes.object,
  i18n: PropTypes.object.isRequired,
  errors: PropTypes.array,
  clearErrors: PropTypes.func,
  showPaymentBreakdownOption: PropTypes.bool
};

export default RecurrenceForm;
