import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router';

class BrandLink extends React.Component {
  logoImage() {
    return (
      <img
        alt={this.props.content.logo_image_alt}
        longdesc={this.props.content.logo_image_description}
        src={this.props.logo}
        className={this.props.logoClass}
      />
    );
  }

  linkContent() {
    return this.props.logo ? this.logoImage() : this.props.linkLabel;
  }

  linkClass() {
    const defaultClass = "brand-link";
    const logoLinkClass = this.props.logoLinkClass || defaultClass;
    const linkClass = this.props.linkClass || defaultClass;

    return this.props.logo ? logoLinkClass : linkClass;
  }

  render() {
    const { linkTo, onClick } = this.props;

    return (
      <Link className={this.linkClass()} to={linkTo} onClick={onClick}>
        {this.linkContent()}
      </Link>
    );
  }
}

BrandLink.propTypes = {
  linkTo: PropTypes.string.isRequired,
  linkLabel: PropTypes.string.isRequired,
  linkClass: PropTypes.string,
  logo: PropTypes.string,
  content: PropTypes.object,
  logoClass: PropTypes.string,
  logoLinkClass: PropTypes.string,
  onClick: PropTypes.func
};

export default BrandLink;
