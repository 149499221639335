import React, {Component} from 'react';
import { PropTypes } from 'prop-types';
import Icon from 'shared/components/Icon';
import Dropzone from 'react-dropzone';

class DocumentUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      columns: ["name", "type"]
    };
    this.onDrop = this.onDrop.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  onDrop(files) {
    let fileArray = [];
    files.forEach(file => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileAsDataURL = reader.result;
        file.content = fileAsDataURL;
      };

      reader.readAsDataURL(file);

      fileArray = fileArray.concat(file);
    });

    let newFiles = [...this.state.files, ...fileArray];
    this.setState({
      files: newFiles
    }, this.addDocs(newFiles));
  }

  addDocs(files) {
    this.props.addDocs(files);
  }

  dropzone() {
    return (
      <section>
        <div>
          <Dropzone
            accept=".pdf,.csv,.txt"
            onDrop={this.onDrop}
            className="dropzone react-dropzone"
          >
            <p>
              Drag and drop files here
              or click to select files from your computer.
            </p>
            <Icon type={'copy fa-2x'} style="regular" />
          </Dropzone>
        </div>
      </section>
    );
  }

  uploadedFilesTable() {
    if (this.state.files.length) {
      return (
        <div className="card clear">
          <div className="table-responsive">
            <table className="table table-selectable data-table bills-table">
              <thead>
                <tr>
                  {this.tableHeaders()}
                  <th>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.tableRows()}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }

  tableHeaders() {
    const {columns} = this.state;
    return columns.map((column, i) => {
      return (
        <th
          key={column}
          className={column}
          >
          {column.charAt(0).toUpperCase() + column.slice(1)}
        </th>
      );
    });
  }

  tableRows() {
    const {files, columns} = this.state;
        if (files.length) {
      return files.map((file, i) => {
        return (
        <tr key={file.name}>
          {columns.map((column) => this.tableData(column, file))}
          <td>
            {this.deleteFile(i, file)}
          </td>
        </tr>
      );
    });
    }
  }

  tableData(column, file) {
    return (
      <td key={column}>
        {this.cellValue(file, column)}
      </td>
    );
  }

  cellValue(file, column) {
    switch (column) {
      case 'name':
        return file.name;
      case 'type':
        return file.type;
    }
  }

  deleteFile(index, file) {
    return (
      <button
        className="btn btn-danger btn-sm float-right"
        onClick={this.handleRemove}>
        <Icon type="times" />
      </button>
    );
  }

  handleRemove(index) {
    let newFiles = this.state.files.slice();
    newFiles.splice(index, 1);
    this.setState({files: newFiles});
  }

  render() {
    return (
      <div>
        {this.dropzone()}
        {this.uploadedFilesTable()}
      </div>
    );
  }
}

export default DocumentUploader;

DocumentUploader.propTypes = {
  addDocs: PropTypes.func
};
