import React from 'react';
import { PropTypes } from 'prop-types';
import Table from './Table';

class AccountsTable extends React.Component {

  columnMap() {
    return {
      'receivable_accounts.external_key': 'external_key',
      'payers.name': 'payer.name',
      'groups.name': 'group.name'
    };
  }

  columnMetadata() {
    return [
      {
        columnName: "external_key",
        displayName: this.props.content.receivable_accounts_external_key_label
      },
      {
        columnName: "payer.name",
        displayName: this.props.content.payers_name_label
      },
      {
        columnName: "group.name",
        displayName: this.props.content.group_name_label
      }
    ];
  }

  render() {
    return (
      <Table
        { ...this.props }
        actions={this.props.actions}
        showActions={this.props.showActions}
        results={this.props.data.accounts}
        totalItems={this.props.data.total_accounts}
        columnMap={this.columnMap()}
        columnMetadata={this.columnMetadata()}
        className="accounts-table"
        noDataMessage={this.props.i18n.portal.data_table.no_results} />
    );
  }
}

AccountsTable.propTypes = {
  data: PropTypes.object,
  results: PropTypes.array,
  i18n: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  showFilter: PropTypes.bool,
  columnMetadata: PropTypes.array,
  filterPlaceholder: PropTypes.string,
  actions: PropTypes.element,
  showActions: PropTypes.bool,
  onRowClick: PropTypes.func,
  isRowSelected: PropTypes.func,
  useExternal: PropTypes.bool,
  externalSetFilter: PropTypes.func,
  externalSetPage: PropTypes.func,
  externalChangeSort: PropTypes.func
};

export default AccountsTable;
