import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router';
import ReactMarkdown from 'react-markdown';
import LandingOption from './LandingOption';
import { getQueryParam } from '../../utils/urlUtils';
import FormErrors from 'shared/components/FormErrors';

class LandingPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showErrors: false
    };

    const optEnabled = props.config.one_time_payment_enabled;
    const otpLinkEnabled = props.config.otp_landing_link_enabled;
    this.optEnabled = optEnabled && otpLinkEnabled;
    this.registrationEnabled = props.config.user_registration_enabled;
    this.clearErrors = this.clearErrors.bind(this);
  }

  componentWillMount() {
    const showInviteError = getQueryParam("show_invite_error");
    if (showInviteError) {
      this.setState({showErrors: true});
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({showErrors: false});
  }

  clearErrors() {
    this.setState({showErrors: false});
  }

  loginWrapperClass() {
    let offsetClass;
    let optionsCount = 1;

    if (this.optEnabled) optionsCount++;
    if (this.registrationEnabled) optionsCount++;

    if (optionsCount == 1) offsetClass = 'offset-md-4';
    else if (optionsCount == 2) offsetClass = 'offset-md-2';

    return offsetClass;
  }

  loginOption() {
    return (
      <LandingOption
        wrapperClass={this.loginWrapperClass()}
        heading={this.props.content.landing_sign_in_heading}
        subHeading={this.props.content.landing_sign_in_sub_heading}
        linkTo="/login"
        linkLabel={this.props.content.sign_in_label} />
    );
  }

  registrationOption() {
    if (this.registrationEnabled) {
      return (
        <LandingOption
          heading={this.props.content.landing_register_heading}
          subHeading={this.props.content.landing_register_sub_heading}
          linkTo="/signup"
          linkLabel={this.props.i18n.common.register} />
      );
    }
  }

  otpOption() {
    if (this.optEnabled) {
      return (
        <LandingOption
          heading={this.props.content.landing_one_time_payment_heading}
          subHeading={this.props.content.landing_one_time_payment_sub_heading}
          linkTo="/otp"
          linkLabel={this.props.content.otp_button_text} />
      );
    }
  }

  logoLink() {
    const imgStyle = {
      width: `${this.props.content.landing_logo_width}`
    };
    if (this.props.content.logo_image_url) {
      const content = this.props.content;
      const logoUrl = content.landing_logo_image_url || content.logo_image_url;
      return (
        <Link to="/" className="logo-link"  style={imgStyle}>
          <img
            alt={this.props.content.logo_image_alt}
            longdesc={this.props.content.logo_image_description}
            className="logo"
            src={logoUrl}
          />
        </Link>
      );
    }
  }

  header() {
    return (
      <header>
        {this.logoLink()}
        <h1>{this.props.content.portal_title}</h1>
      </header>
    );
  }

  landingOptions() {
    return (
      <section className="options">
        {this.loginOption()}
        {this.registrationOption()}
        {this.otpOption()}
      </section>
    );
  }

  landingNotice() {
    if (this.props.content.landing_page_notice) {
      return (
        <ReactMarkdown
          source={this.props.content.landing_page_notice}
          className="landing-notice"
          escapeHtml={false}
          containerTagName="span" />
      );
    }
  }

  inviteErrorMessage() {
    if (this.state.showErrors) {
      const errors = [this.props.i18n.portal_mailer.invite.expired_message];
      return (
        <FormErrors
          hideHeader
          dismiss={this.clearErrors}
          errors={errors}  />
      );
    }
  }

  render() {
    return (
      <div id="portal-landing">
        {this.inviteErrorMessage()}
        {this.header()}
        {this.landingOptions()}
        {this.landingNotice()}
      </div>
    );
  }
}

LandingPage.propTypes = {
  i18n: PropTypes.object,
  config: PropTypes.object,
  clearErrors: PropTypes.func,
  content: PropTypes.object
};

export default LandingPage;
