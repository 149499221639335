import { Controller } from "stimulus";
import Swal from 'sweetalert2';

import 'sweetalert2/dist/sweetalert2.css';
import './session_timeout_controller.sass';

export default class extends Controller {
  connect() {
    setInterval(() => this.checkSession(), 300000);
  }

  checkSession() {
    let time_remaining = parseInt(this.timeRemaining);

    if (time_remaining <= 300 && time_remaining > 0) {
      this.swalWithThemeApplied.fire({
        title: 'Your session will expire in 5 minutes.<br>Do you need more time?',
        allowOutsideClick: false,
        showDenyButton: true,
        confirmButtonText: 'Extend My Session',
        denyButtonText: 'Log Out'
      }).then((result) => {
        if (result.isConfirmed) {
          this.extendSession();
        }

        if (result.isDenied) {
          this.endSession();
        }
      });
    }

    if (time_remaining <= 0) {
      window.location = '/?timeout=true';
    }

    return false;
  }

  extendSession() {
    const xhr = new XMLHttpRequest();
    xhr.open('GET',  '/refresh.json');
    xhr.responseType = 'json';
    xhr.send();
  }

  endSession() {
    $.ajax({
      url: '/logout.js',
      type: 'DELETE',
      contentType: 'application/javascript',
      data: this.requestBody,
    });
  }

  get timeRemaining() {
    let remaining = 0;

    const xhr = new XMLHttpRequest();
    xhr.open('GET', '/timeout.json', false);

    xhr.onload = () => {
      const status = xhr.status;

      if (status == 200) {
        remaining = xhr.responseText;
      }
    };

    xhr.send();

    return remaining;
  }

  get swalWithThemeApplied() {
    return Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary btn-spacing',
        denyButton: 'btn btn-danger btn-spacing'
      },
      buttonsStyling: false
    });
  }

  get requestBody() {
    const tokenName = document.querySelector('meta[name=csrf-param]').content;
    const tokenValue = document.querySelector('meta[name=csrf-token]').content;
    let request = {};
    request[tokenName] = tokenValue;
    return request;
  }
}
