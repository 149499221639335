import React from 'react';
import { PropTypes } from 'prop-types';
import NoticePopover from '../common/NoticePopover';
import ReactMarkdown from 'react-markdown';
import Link from '../common/Link';
import ImageModal from '../common/ImageModal';

class FindAccountForm extends React.Component {

  constructor(props) {
    super(props);

    const account = {
      name: '',
      first_name: '',
      last_name: '',
      account_number: '',
      email: '',
      phone: '',
      ssn_last_four: '',
      bill_external_key: ''
    };

    this.state = {
      account,
      isValid: false,
      popoverOpen: false,
      isRegistrationImageOpen: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.togglePopover = this.togglePopover.bind(this);
    this.toggleRegistrationImage = this.toggleRegistrationImage.bind(this);
  }

  togglePopover() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  heading() {
    return (
      <p className="lead">
        {this.props.i18n.portal.account.find_account}
        {this.registrationNoticeButton()}
        {this.registrationNoticeText()}
      </p>
    );
  }

  nameField() {
    if (this.props.fields.includes('payers.name')) {
      return (
        <div className="form-group row">
          <div className="col-sm-8 offset-sm-2">
            <label htmlFor="name" className="sr-only">
              {this.props.config.feature.payers_name_label}
            </label>
            <input
              required
              className="form-control form-control-lg"
              id="name"
              name="name"
              type="text"
              placeholder={this.props.config.feature.payers_name_label}
              onChange={this.handleFieldChange}
              value={this.state.account.name}
             />
          </div>
        </div>
      );
    }
  }

  firstNameField() {
    if (this.props.fields.includes('payers.first_name')) {
      return (
        <div className="form-group row">
          <div className="col-sm-8 offset-sm-2">
            <label htmlFor="first_name" className="sr-only">
              {this.props.i18n.portal.account.first_name}
            </label>
            <input
              required
              className="form-control form-control-lg"
              id="first_name"
              name="first_name"
              type="text"
              placeholder={this.props.i18n.portal.account.first_name}
              onChange={this.handleFieldChange}
              value={this.state.account.first_name}
            />
          </div>
        </div>
      );
    }
  }

  lastNameField() {
    if (this.props.fields.includes('payers.last_name')) {
      return (
        <div className="form-group row">
          <div className="col-sm-8 offset-sm-2">
            <label htmlFor="last_name" className="sr-only">
              {this.props.i18n.portal.account.last_name}
            </label>
            <input
              required
              className="form-control form-control-lg"
              id="last_name"
              name="last_name"
              type="text"
              placeholder={this.props.i18n.portal.account.last_name}
              onChange={this.handleFieldChange}
              value={this.state.account.last_name}
            />
          </div>
        </div>
      );
    }
  }

  accountNumberField() {
    const inputClass = this.props.content.registration_image_url ? 'input-group input-group-lg' : '';
    if (this.props.fields.includes('receivable_accounts.external_key')) {
      return (
        <div className="form-group row">
          <div className="col-sm-8 offset-sm-2">
            <label htmlFor="account_number" className="sr-only">
              {this.props.i18n.portal.account.account_number}
            </label>
            <div className={inputClass}>
              <input
                required
                className="form-control form-control-lg"
                id="account_number"
                name="account_number"
                type="text"
                placeholder={this.props.i18n.portal.account.account_number}
                onChange={this.handleFieldChange}
                value={this.state.account.account_number}
              />
              {this.registrationImageLink()}
            </div>
          </div>
        </div>
      );
    }
  }

  registrationImageLink() {
    if (this.props.content.registration_image_url) {
      return (
        <div className="input-group-append">
          <Link
            className="btn btn-outline-primary"
            onClick={this.toggleRegistrationImage}
            tabIndex="-1">
            <i className="fa icon-help" />
          </Link>
        </div>
      );
    }
  }

  registrationImageModal() {
    return (
      <ImageModal
        imgDescription={this.props.content.registration_image_description}
        imgAlt={this.props.content.registration_image_alt}
        isOpen={this.state.isRegistrationImageOpen}
        image={this.props.content.registration_image_url}
        toggle={this.toggleRegistrationImage} />
    );
  }

  toggleRegistrationImage() {
    this.setState({ isRegistrationImageOpen: !this.state.isRegistrationImageOpen });
  }

  emailField() {
    if (this.props.fields.includes('payers.email')) {
      return (
        <div className="form-group row">
          <div className="col-sm-8 offset-sm-2">
            <label htmlFor="email" className="sr-only">
              {this.props.i18n.common.email}
            </label>
            <input
              required
              className="form-control form-control-lg"
              id="email"
              name="email"
              type="text"
              placeholder={this.props.i18n.common.email}
              onChange={this.handleFieldChange}
              value={this.state.account.email}
            />
          </div>
        </div>
      );
    }
  }

  phoneField() {
    if (this.props.fields.includes('payers.phone')) {
      return (
        <div className="form-group row">
          <div className="col-sm-8 offset-sm-2">
            <label htmlFor="phone" className="sr-only">
              {this.props.i18n.common.phone}
            </label>
            <input
              required
              className="form-control form-control-lg"
              id="phone"
              name="phone"
              type="text"
              placeholder={this.props.i18n.common.phone}
              onChange={this.handleFieldChange}
              value={this.state.account.phone}
            />
          </div>
        </div>
      );
    }
  }

  ssnField() {
    if (this.props.fields.includes('payers.ssn_last_four')) {
      return (
        <div className="form-group row">
          <div className="col-sm-8 offset-sm-2">
            <label htmlFor="ssn_last_four" className="sr-only">
              {this.props.i18n.common.ssn_last_four}
            </label>
            <input
              required
              className="form-control form-control-lg"
              id="ssn_last_four"
              name="ssn_last_four"
              type="text"
              maxLength="4"
              placeholder={this.props.i18n.common.ssn_last_four}
              onChange={this.handleFieldChange}
              value={this.state.account.ssn_last_four}
            />
          </div>
        </div>
      );
    }
  }

  billExternalKeyField() {
    if (this.props.fields.includes('bills.external_key')) {
      return (
        <div className="form-group row">
          <div className="col-sm-8 offset-sm-2">
            <label htmlFor="bill_external_key" className="sr-only">
              {this.props.content.bills_external_key_label}
            </label>
            <input
              required
              className="form-control form-control-lg"
              id="bill_external_key"
              name="bill_external_key"
              type="text"
              placeholder={this.props.content.bills_external_key_label}
              onChange={this.handleFieldChange}
              value={this.state.account.bill_external_key}
            />
          </div>
        </div>
      );
    }
  }

  actions() {
    return (
      <div className="form-actions form-group">
        <input
          className="btn btn-lg primary-btn"
          type="submit"
          disabled={!this.state.isValid}
          value={this.props.i18n.common.continue} />
      </div>
    );
  }

  handleFieldChange(e) {
    this.setAccount({
      [e.currentTarget.name]: e.currentTarget.value
    });
  }

  setAccount(account) {
    this.setState({
      account: {
        ...this.state.account,
        ...account
      }
    }, this.validateForm);
  }

  validateForm() {
    const account = this.state.account;
    let isValid = true;

    this.props.fields.forEach((field) => {
      let fieldName = field.split('.')[1];
      if (field == "receivable_accounts.external_key") fieldName = "account_number";
      if (field == "bills.external_key") fieldName = "bill_external_key";
      if (!this.state.account[fieldName]) isValid = false;
    });

    this.setState({isValid});
  }

  handleSubmit($event) {
    $event.preventDefault();
    this.props.onSubmit(this.state.account);
  }

  registrationNoticeButton() {
    if (this.props.showNotice && this.props.content.registration_notice) {
      return (
        <Link className="btn-help registration-notice-button"onClick={this.togglePopover}>
          <span id="RegistrationNoticePopover">
            <i className="fa icon-help"/>
          </span>
        </Link>
      );
    }
  }

  registrationNoticeText() {
    if (this.props.showNotice && this.props.content.registration_notice) {
      return (
        <NoticePopover
          placement={"left"}
          isOpen={this.state.popoverOpen}
          target={"RegistrationNoticePopover"}
          toggle={this.togglePopover}
          source={this.props.content.registration_notice}
          className="payment-account-notice left"
          containerTagName="span" />
      );
    }
  }

  render() {
    return (
      <form
        onSubmit={this.handleSubmit}
        name="otpForm"
        ref="otpForm"
        noValidate="novalidate">
        {this.heading()}
        <div className="inner">
          {this.nameField()}
          {this.firstNameField()}
          {this.lastNameField()}
          {this.phoneField()}
          {this.emailField()}
          {this.ssnField()}
          {this.accountNumberField()}
          {this.billExternalKeyField()}
          {this.registrationImageModal()}
          {this.actions()}
        </div>
      </form>
    );
  }
}

FindAccountForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  config: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  showNotice: PropTypes.bool
};

export default FindAccountForm;
