import React from 'react';
import { PropTypes } from 'prop-types';
import { Popover, PopoverContent } from 'reactstrap';
import ReactMarkdown from 'react-markdown';

class NoticePopover extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Popover placement={this.props.placement} isOpen={this.props.isOpen} target={this.props.target} toggle={this.props.toggle}>
        <PopoverContent>
          <ReactMarkdown
            escapeHtml={false}
            source={this.props.source}
            className={this.props.className}
            containerTagName={this.props.containerTagName} />
        </PopoverContent>
      </Popover>
    );
  }
}

NoticePopover.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  target: PropTypes.string,
  toggle: PropTypes.func,
  source: PropTypes.string,
  placement: PropTypes.string,
  containerTagName: PropTypes.string
};

export default NoticePopover;
