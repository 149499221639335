export const SET_SPLIT                        = 'SET_SPLIT';
export const SET_CREDIT_CARD_ENABLED          = 'SET_CREDIT_CARD_ENABLED';
export const SET_SPLITS_VALID                 = 'SET_SPLITS_VALID';
export const SET_VIEW_ONLY                    = 'SET_VIEW_ONLY';
export const ADD_BILL_FILTER                  = 'ADD_BILL_FILTER';
export const REMOVE_BILL_FILTER               = 'REMOVE_BILL_FILTER';
export const CLEAR_BILL_FILTERS               = 'CLEAR_BILL_FILTERS';
export const SET_ACTIVE_TAB                   = 'SET_ACTIVE_TAB';
export const TOGGLE_MIX_KEY_MODAL             = 'TOGGLE_MIX_KEY_MODAL';
export const TOGGLE_REMOVE_ALL_CONFIRMATION_MODAL        = 'TOGGLE_REMOVE_ALL_CONFIRMATION_MODAL';
export const TOGGLE_PLASTIQ_CONFIRMATION_MODAL = 'TOGGLE_PLASTIQ_CONFIRMATION_MODAL';
export const TOGGLE_CREDIT_CARD_MODAL         = 'TOGGLE_CREDIT_CARD_MODAL';
export const SET_CONFIRMATION_SPLIT           = 'SET_CONFIRMATION_SPLIT';
export const SET_SELECTED_SPLIT               = 'SET_SELECTED_SPLIT';
export const SET_BILLS_QUERY                  = 'SET_BILLS_QUERY';
export const SET_SPLITS_QUERY                 = 'SET_SPLITS_QUERY';
export const SET_BILLS_SORT_ORDER             = 'SET_BILLS_SORT_ORDER';
export const SET_SPLITS_SORT_ORDER            = 'SET_SPLITS_SORT_ORDER';
export const SET_ACCOUNTS_QUERY               = 'SET_ACCOUNTS_QUERY';
export const SET_ACCOUNTS_SORT_ORDER          = 'SET_ACCOUNTS_SORT_ORDER';
export const SET_ACCOUNTS_PAGE                = 'SET_ACCOUNTS_PAGE';

export const FETCH_PAY_TO_ACCOUNTS            = 'FETCH_PAY_TO_ACCOUNTS';
export const FETCH_PAY_TO_ACCOUNTS_FULFILLED  = 'FETCH_PAY_TO_ACCOUNTS_FULFILLED';
export const FETCH_PAY_TO_ACCOUNTS_PENDING    = 'FETCH_PAY_TO_ACCOUNTS_PENDING';
export const FETCH_PAY_TO_ACCOUNTS_REJECTED   = 'FETCH_PAY_TO_ACCOUNTS_REJECTED';
