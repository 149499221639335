import React from 'react';
import { PropTypes } from 'prop-types';
import Button from '../../common/Button';
import RecurrenceSettings from '../../common/RecurrenceSettings';
import AccountsTable from '../../common/AccountsTable';
import AccountsModal from '../../common/AccountsModal';
import Spinner from '../../common/Spinner';
import Modal from '../../common/Modal';

class BillRecurrenceOverview extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showSettings: false,
      isAccountsModalOpen: false,
      isRemoveModalOpen: false
    };

    this.toggleSettings = this.toggleSettings.bind(this);
    this.toggleAccountsModal = this.toggleAccountsModal.bind(this);
    this.createRecurrenceAccount = this.createRecurrenceAccount.bind(this);
    this.toggleRemoveModal = this.toggleRemoveModal.bind(this);
    this.removeSelectedAccount = this.removeSelectedAccount.bind(this);
    this.toggleSelectedAccount = this.toggleSelectedAccount.bind(this);
    this.isAccountSelected = this.isAccountSelected.bind(this);
    this.filterAccounts = this.filterAccounts.bind(this);
    this.pageAccounts = this.pageAccounts.bind(this);
    this.sortAccounts = this.sortAccounts.bind(this);
  }

  componentWillMount() {
    this.props.clearRecurrenceAccounts();
    this.props.fetchRecurrenceAccounts(this.props.recurrence);
  }

  componentWillReceiveProps(nextProps) {
    this.checkForCreatedRecurrenceAccount(nextProps);
    this.checkForDeletedRecurrenceAccount(nextProps);
  }

  checkForCreatedRecurrenceAccount(nextProps) {
    if (nextProps.createdRecurrenceAccount) {
      this.props.clearCreatedRecurrenceAccount();
      this.props.fetchRecurrenceAccounts(this.props.recurrence);
    }
  }

  checkForDeletedRecurrenceAccount(nextProps) {
    if (nextProps.deletedRecurrenceAccount) {
      this.props.clearDeletedRecurrenceAccount();
      this.props.fetchRecurrenceAccounts(this.props.recurrence);
    }
  }

  header() {
    return (
      <section className="recurrence-header">
        <span className="lead">
          {this.props.recurrence.pay_method.details}
        </span>
      </section>
    );
  }

  actions() {
    return (
      <section className="actions">
        {this.returnLink()}
        {this.settingsLink()}
      </section>
    );
  }

  returnLink() {
    return (
      <Button
        className=""
        color="link"
        icon="arrow-left"
        onClick={this.props.onReturn}
      >
        {this.props.i18n.portal.payer.autopay.return_to_list}
      </Button>
    );
  }

  settingsLink() {
    const linkText = this.state.showSettings ? 'hide_settings' : 'view_settings';
    return (
      <Button
        className="float-right"
        color="link"
        icon="cog"
        onClick={this.toggleSettings}
      >
        {this.props.i18n.portal.payer.autopay[linkText]}
      </Button>
    );
  }

  toggleSettings() {
    this.setState({
      showSettings: !this.state.showSettings
    });
  }

  accountsTable() {
    if (this.props.recurrenceAccounts.data) {
      if (!this.state.showSettings) {
        return (
          <section className="recurrence-accounts">
            <AccountsTable
              selectable
              showActions
              useExternal
              data={this.props.recurrenceAccounts.data}
              columns={this.props.config.feature.portal_accounts_manage_columns}
              heading={this.props.i18n.portal.payer.autopay.autopay_accounts_heading}
              onRowClick={this.toggleSelectedAccount}
              isRowSelected={this.isAccountSelected}
              filterPlaceholder={this.props.i18n.portal.accounts_table.filter_accounts}
              filterQuery={this.state.accountsQuery}
              actions={this.accountsActions()}
              i18n={this.props.i18n}
              content={this.props.content}
              externalSetFilter={this.filterAccounts}
              externalSetPage={this.pageAccounts}
              externalChangeSort={this.sortAccounts} />
          </section>
        );
      }
    }
    else {
      return <Spinner centered size="medium" />;
    }
  }

  //
  filterAccounts(query) {
    this.setState({ accountsQuery: query });
    this.props.fetchRecurrenceAccounts(this.props.recurrence, {
      query,
      sort_order: this.state.accountsSortOrder
    });
  }

  pageAccounts(page) {
    this.props.fetchRecurrenceAccounts(this.props.recurrence, {
      page,
      query: this.state.accountsQuery,
      sort_order: this.state.accountsSortOrder
    });
  }

  sortAccounts(sortOrder) {
    this.setState({ accountsSortOrder: sortOrder });
    this.props.fetchRecurrenceAccounts(this.props.recurrence, {
      sort_order: sortOrder,
      query: this.state.accountsQuery
    });
  }

  toggleSelectedAccount(account = null) {
    const selectedAccount = this.isAccountSelected(account) ? null : account;
    this.setState({ selectedAccount });
  }

  isAccountSelected(account) {
    if (account && this.state.selectedAccount) {
      return account.id == this.state.selectedAccount.id;
    }
  }

  accountsActions() {
    return (
      <div>
        {this.addAccountButton()}{' '}
        {this.removeAccountButton()}
      </div>
    );
  }

  addAccountButton() {
    return (
      <Button icon="plus-circle" onClick={this.toggleAccountsModal}>
        {this.props.i18n.common.add}
      </Button>
    );
  }

  removeAccountButton() {
    if (this.state.selectedAccount) {
      return (
        <Button icon="minus-circle" onClick={this.toggleRemoveModal}>
          {this.props.i18n.common.remove}
        </Button>
      );
    }
  }

  toggleAccountsModal() {
    this.setState({ isAccountsModalOpen: !this.state.isAccountsModalOpen });
  }

  accountsModal() {
    const fetchParams = { available_for_recurrence: true };

    return (
      <AccountsModal
        accounts={this.props.portalAccounts}
        clearAccounts={this.props.clearPortalAccounts}
        config={this.props.config}
        content={this.props.content}
        fetchAccounts={this.props.fetchPortalAccounts}
        fetchParams={fetchParams}
        i18n={this.props.i18n}
        isOpen={this.state.isAccountsModalOpen}
        onRowClick={this.createRecurrenceAccount}
        toggle={this.toggleAccountsModal}
      />
    );
  }

  createRecurrenceAccount(portalAccount) {
    this.toggleAccountsModal();
    this.props.createRecurrenceAccount(this.props.recurrence, portalAccount);
  }
  //

  recurrenceSettings() {
    if (this.state.showSettings) {
      return (
        <RecurrenceSettings
          recurrence={this.props.recurrence}
          onEdit={this.props.onEdit}
          onRemove={this.props.onRemove}
          i18n={this.props.i18n}
          content={this.props.content} />
      );
    }
  }

  removeAccountModal() {
    if (this.state.selectedAccount) {
      return (
        <Modal
          showClose
          showConfirm
          title={this.props.i18n.portal.modals.confirm_remove_header}
          message={this.props.i18n.portal.modals.confirm_remove_message}
          confirmLabel={this.props.i18n.common.remove}
          confirmClassName="danger"
          closeLabel={this.props.i18n.common.close}
          onConfirm={this.removeSelectedAccount}
          isOpen={this.state.isRemoveModalOpen}
          toggle={this.toggleRemoveModal} />
      );
    }
  }

  toggleRemoveModal() {
    this.setState({ isRemoveModalOpen: !this.state.isRemoveModalOpen });
  }

  removeSelectedAccount() {
    this.toggleRemoveModal();
    this.toggleSelectedAccount();
    this.props.deleteRecurrenceAccount(this.props.recurrence, this.state.selectedAccount);
  }

  render() {
    return (
      <div className="manage-bill-recurrence">
        {this.header()}
        {this.actions()}
        {this.accountsTable()}
        {this.recurrenceSettings()}
        {this.accountsModal()}
        {this.removeAccountModal()}
      </div>
    );
  }
}

BillRecurrenceOverview.propTypes = {
  recurrence: PropTypes.object.isRequired,
  recurrenceAccounts: PropTypes.object.isRequired,
  clearRecurrenceAccounts: PropTypes.func.isRequired,
  fetchRecurrenceAccounts: PropTypes.func.isRequired,
  createRecurrenceAccount: PropTypes.func.isRequired,
  deleteRecurrenceAccount: PropTypes.func.isRequired,
  clearCreatedRecurrenceAccount: PropTypes.func.isRequired,
  clearDeletedRecurrenceAccount: PropTypes.func.isRequired,
  fetchPortalAccounts: PropTypes.func.isRequired,
  clearPortalAccounts: PropTypes.func.isRequired,
  portalAccounts: PropTypes.object.isRequired,
  onReturn: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired
};

export default BillRecurrenceOverview;
