import React from 'react';
import { PropTypes } from 'prop-types';
import Icon from 'shared/components/Icon';

class Link extends React.Component {

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    if (this.props.stopPropagation) e.stopPropagation();
    if (this.props.onClick) {
      this.props.noEvent ? this.props.onClick() : this.props.onClick(e);
    }
  }

  icon() {
    if (this.props.icon) {
      return <Icon type={this.props.icon} />;
    }
  }

  render() {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
         href="#"
         role="button"
         className={this.props.className}
         disabled={this.props.disabled}
         onClick={this.handleClick}
         tabIndex={this.props.tabIndex}
      >
        {this.icon()}{' '}
        {this.props.children}
      </a>
    );
  }
}

Link.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  tabIndex: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.string,
  noEvent: PropTypes.bool,
  stopPropagation: PropTypes.bool,
  disabled: PropTypes.bool
};

export default Link;
