import React from 'react';
import { PropTypes } from 'prop-types';

class TabSet extends React.Component {
  containerClasses() {
    const classes = ['tabset-themed'];
    if (this.props.className) classes.push(this.props.className);
    return classes.join(' ');
  }

  tabsClasses() {
    const classes = ['nav', 'nav-tabs'];
    if (this.props.card) classes.push('card-header-tabs');
    if (this.props.justified) classes.push('nav-justified');
    if (this.props.pills) classes.push('nav-pills');
    return classes.join(' ');
  }

  render() {
      //<div className={this.containerClasses()}>
      //</div>
    return (
        <ul className={this.tabsClasses()}>
          {this.props.children}
        </ul>
    );
  }
}

TabSet.propTypes = {
  card: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  justified: PropTypes.bool,
  pills: PropTypes.bool
};

export default TabSet;
