// Resource types
export * from './resources/payment/types';
export * from './resources/split/types';

// Screen types
export * from './screens/registration/types';
export * from './screens/dashboard/types';
export * from './screens/bill_payment/types';
export * from './screens/account/types';

// Shared types
export const RESET_SCREEN                 = 'RESET_SCREEN';
export const CLEAR_ERRORS                 = 'CLEAR_ERRORS';
export const SET_ERRORS                   = 'SET_ERRORS';
export const SET_CURRENT_STEP             = 'SET_CURRENT_STEP';
export const SET_ACCOUNT                  = 'SET_ACCOUNT';
export const SET_PAYMENT                  = 'SET_PAYMENT';
export const TOGGLE_SWITCH_ACCOUNT_MODAL  = 'TOGGLE_SWITCH_ACCOUNT_MODAL';

//
// TODO: Move to appropriate state types
//
export const FETCH_CONFIG                 = 'FETCH_CONFIG';
export const FETCH_CONFIG_PENDING         = 'FETCH_CONFIG_PENDING';
export const FETCH_CONFIG_FULFILLED       = 'FETCH_CONFIG_FULFILLED';

export const UPDATE_USER                  = 'UPDATE_USER';
export const UPDATE_USER_FULFILLED        = 'UPDATE_USER_FULFILLED';
export const UPDATE_USER_REJECTED         = 'UPDATE_USER_REJECTED';

export const LOGIN                        = 'LOGIN';
export const LOGIN_FULFILLED              = 'LOGIN_FULFILLED';
export const LOGIN_PENDING                = 'LOGIN_PENDING';
export const LOGIN_REJECTED               = 'LOGIN_REJECTED';

export const RESET_PASSWORD               = 'RESET_PASSWORD';
export const RESET_PASSWORD_FULFILLED     = 'RESET_PASSWORD_FULFILLED';
export const RESET_PASSWORD_PENDING       = 'RESET_PASSWORD_PENDING';
export const RESET_PASSWORD_REJECTED      = 'RESET_PASSWORD_REJECTED';

export const UPDATE_PASSWORD              = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_FULFILLED    = 'UPDATE_PASSWORD_FULFILLED';
export const UPDATE_PASSWORD_PENDING      = 'UPDATE_PASSWORD_PENDING';
export const UPDATE_PASSWORD_REJECTED     = 'UPDATE_PASSWORD_REJECTED';

export const SEND_EMAIL_CONFIRMATION              = 'SEND_EMAIL_CONFIRMATION';
export const SEND_EMAIL_CONFIRMATION_FULFILLED    = 'SEND_EMAIL_CONFIRMATION_FULFILLED';
export const SEND_EMAIL_CONFIRMATION_PENDING      = 'SEND_EMAIL_CONFIRMATION_PENDING';
export const SEND_EMAIL_CONFIRMATIO_REJECTED      = 'SEND_EMAIL_CONFIRMATIO_REJECTED';

export const CLEAR_PASSWORD_RESET         = 'CLEAR_PASSWORD_RESET';

export const CLEAR_UPDATED_USER_CHECK     = 'CLEAR_UPDATED_USER_CHECK';

export const LOGOUT                       = 'LOGOUT';
export const LOGOUT_FULFILLED             = 'LOGOUT_FULFILLED';
export const LOGOUT_PENDING               = 'LOGOUT_PENDING';
export const LOGOUT_REJECTED              = 'LOGOUT_REJECTED';

export const SESSION_TIMEOUT              = 'SESSION_TIMEOUT';
export const SESSION_TIMEOUT_FULFILLED    = 'SESSION_TIMEOUT_FULFILLED';
export const SESSION_TIMEOUT_PENDING      = 'SESSION_TIMEOUT_PENDING';
export const SESSION_TIMEOUT_REJECTED     = 'SESSION_TIMEOUT_REJECTED';

export const FETCH_BILLS_OVERVIEW            = 'FETCH_BILLS_OVERVIEW';
export const FETCH_BILLS_OVERVIEW_FULFILLED  = 'FETCH_BILLS_OVERVIEW_FULFILLED';
export const FETCH_BILLS_OVERVIEW_PENDING    = 'FETCH_BILLS_OVERVIEW_PENDING';
export const FETCH_BILLS_OVERVIEW_REJECTED   = 'FETCH_BILLS_OVERVIEW_REJECTED';

export const FETCH_BILLS                  = 'FETCH_BILLS';
export const FETCH_BILLS_FULFILLED        = 'FETCH_BILLS_FULFILLED';
export const FETCH_BILLS_PENDING          = 'FETCH_BILLS_PENDING';
export const FETCH_BILLS_REJECTED         = 'FETCH_BILLS_REJECTED';

export const CLEAR_BILLS_DATA             = 'CLEAR_BILLS_DATA';

export const SELECT_BILLS                 = 'SELECT_BILLS';
export const SELECT_BILLS_FULFILLED       = 'SELECT_BILLS_FULFILLED';
export const SELECT_BILLS_PENDING         = 'SELECT_BILLS_PENDING';
export const SELECT_BILLS_REJECTED        = 'SELECT_BILLS_REJECTED';

export const CLEAR_SELECTED_BILLS         = 'CLEAR_SELECTED_BILLS';

export const FETCH_ACCOUNTS               = 'FETCH_ACCOUNTS';
export const FETCH_ACCOUNTS_FULFILLED     = 'FETCH_ACCOUNTS_FULFILLED';
export const FETCH_ACCOUNTS_PENDING       = 'FETCH_ACCOUNTS_PENDING';
export const FETCH_ACCOUNTS_REJECTED      = 'FETCH_ACCOUNTS_REJECTED';

export const CLEAR_ACCOUNTS               = 'CLEAR_ACCOUNTS';

export const FETCH_RECEIVABLE_ACCOUNT           = 'FETCH_RECEIVABLE_ACCOUNT';
export const FETCH_RECEIVABLE_ACCOUNT_FULFILLED = 'FETCH_RECEIVABLE_ACCOUNT_FULFILLED';

export const FETCH_PAYMENTS               = 'FETCH_PAYMENTS';
export const FETCH_PAYMENTS_PENDING       = 'FETCH_PAYMENTS_PENDING';
export const FETCH_PAYMENTS_FULFILLED     = 'FETCH_PAYMENTS_FULFILLED';
export const FETCH_PAYMENTS_REJECTED      = 'FETCH_PAYMENTS_REJECTED';

export const FETCH_OTP_BANK_NAME            = 'FETCH_OTP_BANK_NAME';
export const FETCH_OTP_BANK_NAME_PENDING    = 'FETCH_OTP_BANK_NAME_PENDING';
export const FETCH_OTP_BANK_NAME_FULFILLED  = 'FETCH_OTP_BANK_NAME_FULFILLED';
export const FETCH_OTP_BANK_NAME_REJECTED   = 'FETCH_OTP_BANK_NAME_REJECTED';

export const CLEAR_PAYMENTS               = 'CLEAR_PAYMENTS';
export const CLEAR_PAYMENT                = 'CLEAR_PAYMENT';
export const CLEAR_SAVED_PAYMENT          = 'CLEAR_SAVED_PAYMENT';

export const SAVE_PAYMENT                 = 'SAVE_PAYMENT';
export const SAVE_PAYMENT_PENDING         = 'SAVE_PAYMENT_PENDING';
export const SAVE_PAYMENT_FULFILLED       = 'SAVE_PAYMENT_FULFILLED';
export const SAVE_PAYMENT_REJECTED        = 'SAVE_PAYMENT_REJECTED';

export const FETCH_PAY_METHODS            = 'FETCH_PAY_METHODS';
export const FETCH_PAY_METHODS_FULFILLED  = 'FETCH_PAY_METHODS_FULFILLED';
export const FETCH_PAY_METHODS_PENDING    = 'FETCH_PAY_METHODS_PENDING';
export const FETCH_PAY_METHODS_REJECTED   = 'FETCH_PAY_METHODS_REJECTED';

export const SAVE_PAY_METHOD              = 'SAVE_PAY_METHOD';
export const SAVE_PAY_METHOD_FULFILLED    = 'SAVE_PAY_METHOD_FULFILLED';
export const SAVE_PAY_METHOD_PENDING      = 'SAVE_PAY_METHOD_PENDING';
export const SAVE_PAY_METHOD_REJECTED     = 'SAVE_PAY_METHOD_REJECTED';

export const CLEAR_SAVED_PAY_METHOD       = 'CLEAR_SAVED_PAY_METHOD';

export const CLEAR_DOCS_ADDED             = 'CLEAR_DOCS_ADDED';

export const DELETE_PAY_METHOD            = 'DELETE_PAY_METHOD';
export const DELETE_PAY_METHOD_PENDING    = 'DELETE_PAY_METHOD_PENDING';
export const DELETE_PAY_METHOD_FULFILLED  = 'DELETE_PAY_METHOD_FULFILLED';
export const DELETE_PAY_METHOD_REJECTED   = 'DELETE_PAY_METHOD_REJECTED';

export const CLEAR_DELETED_PAY_METHOD        = 'CLEAR_DELETED_PAY_METHOD';

export const FETCH_PORTAL_USERS            = 'FETCH_PORTAL_USERS';
export const FETCH_PORTAL_USERS_FULFILLED  = 'FETCH_PORTAL_USERS_FULFILLED';
export const FETCH_PORTAL_USERS_PENDING    = 'FETCH_PORTAL_USERS_PENDING';
export const FETCH_PORTAL_USERS_REJECTED   = 'FETCH_PORTAL_USERS_REJECTED';

export const FETCH_PAY_METHOD_ASSIGNMENTS            = 'FETCH_PAY_METHOD_ASSIGNMENTS';
export const FETCH_PAY_METHOD_ASSIGNMENTS_FULFILLED  = 'FETCH_PAY_METHOD_ASSIGNMENTS_FULFILLED';
export const FETCH_PAY_METHOD_ASSIGNMENTS_PENDING    = 'FETCH_PAY_METHOD_ASSIGNMENTS_PENDING';
export const FETCH_PAY_METHOD_ASSIGNMENTS_REJECTED   = 'FETCH_PAY_METHOD_ASSIGNMENTS_REJECTED';

export const CREATE_PORTAL_AGREEMENT            = 'CREATE_PORTAL_AGREEMENT';
export const CREATE_PORTAL_AGREEMENT_FULFILLED  = 'CREATE_PORTAL_AGREEMENT_FULFILLED';
export const CREATE_PORTAL_AGREEMENT_PENDING    = 'CREATE_PORTAL_AGREEMENT_PENDING';
export const CREATE_PORTAL_AGREEMENT_REJECTED   = 'CREATE_PORTAL_AGREEMENT_REJECTED';

export const CREATE_PORTAL_DOCUMENT            = 'CREATE_PORTAL_DOCUMENT';
export const CREATE_PORTAL_DOCUMENT_FULFILLED  = 'CREATE_PORTAL_DOCUMENT_FULFILLED';
export const CREATE_PORTAL_DOCUMENT_PENDING    = 'CREATE_PORTAL_DOCUMENT_PENDING';
export const CREATE_PORTAL_DOCUMENT_REJECTED   = 'CREATE_PORTAL_DOCUMENT_REJECTED';

export const DELETE_PORTAL_DOCUMENT            = 'DELETE_PORTAL_DOCUMENT';
export const DELETE_PORTAL_DOCUMENT_FULFILLED  = 'DELETE_PORTAL_DOCUMENT_FULFILLED';
export const DELETE_PORTAL_DOCUMENT_PENDING    = 'DELETE_PORTAL_DOCUMENT_PENDING';
export const DELETE_PORTAL_DOCUMENT_REJECTED   = 'DELETE_PORTAL_DOCUMENT_REJECTED';

export const CLEAR_PAY_METHOD_ASSIGNMENTS   = 'CLEAR_PAY_METHOD_ASSIGNMENTS';

export const CREATE_PAY_METHOD_ASSIGNMENT            = 'CREATE_PAY_METHOD_ASSIGNMENT';
export const CREATE_PAY_METHOD_ASSIGNMENT_FULFILLED  = 'CREATE_PAY_METHOD_ASSIGNMENT_FULFILLED';
export const CREATE_PAY_METHOD_ASSIGNMENT_PENDING    = 'CREATE_PAY_METHOD_ASSIGNMENT_PENDING';
export const CREATE_PAY_METHOD_ASSIGNMENT_REJECTED   = 'CREATE_PAY_METHOD_ASSIGNMENT_REJECTED';

export const DELETE_PAY_METHOD_ASSIGNMENT            = 'DELETE_PAY_METHOD_ASSIGNMENT';
export const DELETE_PAY_METHOD_ASSIGNMENT_FULFILLED  = 'DELETE_PAY_METHOD_ASSIGNMENT_FULFILLED';
export const DELETE_PAY_METHOD_ASSIGNMENT_PENDING    = 'DELETE_PAY_METHOD_ASSIGNMENT_PENDING';
export const DELETE_PAY_METHOD_ASSIGNMENT_REJECTED   = 'DELETE_PAY_METHOD_ASSIGNMENT_REJECTED';

export const FETCH_BILL_RECURRENCE            = 'FETCH_BILL_RECURRENCE';
export const FETCH_BILL_RECURRENCE_PENDING    = 'FETCH_BILL_RECURRENCE_PENDING';
export const FETCH_BILL_RECURRENCE_FULFILLED  = 'FETCH_BILL_RECURRENCE_FULFILLED';
export const FETCH_BILL_RECURRENCE_REJECTED   = 'FETCH_BILL_RECURRENCE_REJECTED';

export const FETCH_BILL_RECURRENCES            = 'FETCH_BILL_RECURRENCES';
export const FETCH_BILL_RECURRENCES_PENDING    = 'FETCH_BILL_RECURRENCES_PENDING';
export const FETCH_BILL_RECURRENCES_FULFILLED  = 'FETCH_BILL_RECURRENCES_FULFILLED';
export const FETCH_BILL_RECURRENCES_REJECTED   = 'FETCH_BILL_RECURRENCES_REJECTED';

export const CLEAR_BILL_RECURRENCES            = 'CLEAR_BILL_RECURRENCES';

export const FETCH_RECURRENCE_ACCOUNTS           = 'FETCH_RECURRENCE_ACCOUNTS';
export const FETCH_RECURRENCE_ACCOUNTS_PENDING   = 'FETCH_RECURRENCE_ACCOUNTS_PENDING';
export const FETCH_RECURRENCE_ACCOUNTS_FULFILLED = 'FETCH_RECURRENCE_ACCOUNTS_FULFILLED';
export const FETCH_RECURRENCE_ACCOUNTS_REJECTED  = 'FETCH_RECURRENCE_ACCOUNTS_REJECTED';

export const CLEAR_RECURRENCE_ACCOUNTS       = 'CLEAR_RECURRENCE_ACCOUNTS';

export const SAVE_BILL_RECURRENCE            = 'SAVE_BILL_RECURRENCE';
export const SAVE_BILL_RECURRENCE_PENDING    = 'SAVE_BILL_RECURRENCE_PENDING';
export const SAVE_BILL_RECURRENCE_FULFILLED  = 'SAVE_BILL_RECURRENCE_FULFILLED';
export const SAVE_BILL_RECURRENCE_REJECTED   = 'SAVE_BILL_RECURRENCE_REJECTED';

export const CLEAR_SAVED_BILL_RECURRENCE     = 'CLEAR_SAVED_BILL_RECURRENCE';

export const FETCH_PORTAL_ACCOUNTS            = 'FETCH_PORTAL_ACCOUNTS';
export const FETCH_PORTAL_ACCOUNTS_PENDING    = 'FETCH_PORTAL_ACCOUNTS_PENDING';
export const FETCH_PORTAL_ACCOUNTS_FULFILLED  = 'FETCH_PORTAL_ACCOUNTS_FULFILLED';
export const FETCH_PORTAL_ACCOUNTS_REJECTED   = 'FETCH_PORTAL_ACCOUNTS_REJECTED';

export const CLEAR_PORTAL_ACCOUNTS            = 'CLEAR_PORTAL_ACCOUNTS';

export const CREATE_RECURRENCE_ACCOUNT            = 'CREATE_RECURRENCE_ACCOUNT';
export const CREATE_RECURRENCE_ACCOUNT_PENDING    = 'CREATE_RECURRENCE_ACCOUNT_PENDING';
export const CREATE_RECURRENCE_ACCOUNT_FULFILLED  = 'CREATE_RECURRENCE_ACCOUNT_FULFILLED';
export const CREATE_RECURRENCE_ACCOUNT_REJECTED   = 'CREATE_RECURRENCE_ACCOUNT_REJECTED';

export const CLEAR_CREATED_RECURRENCE_ACCOUNT     = 'CLEAR_CREATED_RECURRENCE_ACCOUNT';

export const DELETE_RECURRENCE_ACCOUNT            = 'DELETE_RECURRENCE_ACCOUNT';
export const DELETE_RECURRENCE_ACCOUNT_PENDING    = 'DELETE_RECURRENCE_ACCOUNT_PENDING';
export const DELETE_RECURRENCE_ACCOUNT_FULFILLED  = 'DELETE_RECURRENCE_ACCOUNT_FULFILLED';
export const DELETE_RECURRENCE_ACCOUNT_REJECTED   = 'DELETE_RECURRENCE_ACCOUNT_REJECTED';

export const CLEAR_DELETED_RECURRENCE_ACCOUNT  = 'CLEAR_DELETED_RECURRENCE_ACCOUNT';

export const DELETE_BILL_RECURRENCE            = 'DELETE_BILL_RECURRENCE';
export const DELETE_BILL_RECURRENCE_PENDING    = 'DELETE_BILL_RECURRENCE_PENDING';
export const DELETE_BILL_RECURRENCE_FULFILLED  = 'DELETE_BILL_RECURRENCE_FULFILLED';
export const DELETE_BILL_RECURRENCE_REJECTED   = 'DELETE_BILL_RECURRENCE_REJECTED';

export const CLEAR_DELETED_BILL_RECURRENCE   = 'CLEAR_DELETED_BILL_RECURRENCE';

export const EDIT_BILL_RECURRENCE            = 'EDIT_BILL_RECURRENCE';
export const EDIT_BILL_RECURRENCE_PENDING    = 'EDIT_BILL_RECURRENCE_PENDING';
export const EDIT_BILL_RECURRENCE_FULFILLED  = 'EDIT_BILL_RECURRENCE_FULFILLED';
export const EDIT_BILL_RECURRENCE_REJECTED   = 'EDIT_BILL_RECURRENCE_REJECTED';

export const CREATE_ACCOUNT_RECURRENCE            = 'CREATE_ACCOUNT_RECURRENCE';
export const CREATE_ACCOUNT_RECURRENCE_PENDING    = 'CREATE_ACCOUNT_RECURRENCE_PENDING';
export const CREATE_ACCOUNT_RECURRENCE_FULFILLED  = 'CREATE_ACCOUNT_RECURRENCE_FULFILLED';
export const CREATE_ACCOUNT_RECURRENCE_REJECTED   = 'CREATE_ACCOUNT_RECURRENCE_REJECTED';

export const CLEAR_CREATED_ACCOUNT_RECURRENCE     = 'CLEAR_CREATED_ACCOUNT_RECURRENCE';

export const DELETE_ACCOUNT_RECURRENCE            = 'DELETE_ACCOUNT_RECURRENCE';
export const DELETE_ACCOUNT_RECURRENCE_PENDING    = 'DELETE_ACCOUNT_RECURRENCE_PENDING';
export const DELETE_ACCOUNT_RECURRENCE_FULFILLED  = 'DELETE_ACCOUNT_RECURRENCE_FULFILLED';
export const DELETE_ACCOUNT_RECURRENCE_REJECTED   = 'DELETE_ACCOUNT_RECURRENCE_REJECTED';

export const CLEAR_DELETED_ACCOUNT_RECURRENCE     = 'CLEAR_DELETED_ACCOUNT_RECURRENCE';

export const FETCH_BILL_DOCUMENTS                 = 'FETCH_BILL_DOCUMENTS';
export const FETCH_BILL_DOCUMENTS_FULFILLED       = 'FETCH_BILL_DOCUMENTS_FULFILLED';
export const FETCH_BILL_DOCUMENTS_PENDING         = 'FETCH_BILL_DOCUMENTS_PENDING';
export const FETCH_BILL_DOCUMENTS_REJECTED        = 'FETCH_BILL_DOCUMENTS_REJECTED';

export const CLEAR_BILL_DOCUMENTS                 = 'CLEAR_BILL_DOCUMENTS';

export const FETCH_EXTERNAL_BILLS                 = 'FETCH_EXTERNAL_BILLS';
export const FETCH_EXTERNAL_BILLS_FULFILLED       = 'FETCH_EXTERNAL_BILLS_FULFILLED';
export const FETCH_EXTERNAL_BILLS_PENDING         = 'FETCH_EXTERNAL_BILLS_PENDING';
export const FETCH_EXTERNAL_BILLS_REJECTED        = 'FETCH_EXTERNAL_BILLS_REJECTED';

export const FETCH_EXTERNAL_PAYMENTS              = 'FETCH_EXTERNAL_PAYMENTS';
export const FETCH_EXTERNAL_PAYMENTS_FULFILLED    = 'FETCH_EXTERNAL_PAYMENTS_FULFILLED';
export const FETCH_EXTERNAL_PAYMENTS_PENDING      = 'FETCH_EXTERNAL_PAYMENTS_PENDING';
export const FETCH_EXTERNAL_PAYMENTS_REJECTED     = 'FETCH_EXTERNAL_PAYMENTS_REJECTED';

export const FETCH_EXTERNAL_PAYMENT               = 'FETCH_EXTERNAL_PAYMENT';
export const FETCH_EXTERNAL_PAYMENT_FULFILLED     = 'FETCH_EXTERNAL_PAYMENT_FULFILLED';
export const FETCH_EXTERNAL_PAYMENT_PENDING       = 'FETCH_EXTERNAL_PAYMENT_PENDING';
export const FETCH_EXTERNAL_PAYMENT_REJECTED      = 'FETCH_EXTERNAL_PAYMENT_REJECTED';

export const CLEAR_EXTERNAL_PAYMENT               = 'CLEAR_EXTERNAL_PAYMENT';

export const DELETE_PAYMENT                       = 'DELETE_PAYMENT';
export const DELETE_PAYMENT_PENDING               = 'DELETE_PAYMENT_PENDING';
export const DELETE_PAYMENT_FULFILLED             = 'DELETE_PAYMENT_FULFILLED';
export const DELETE_PAYMENT_REJECTED              = 'DELETE_PAYMENT_REJECTED';

export const CLEAR_DELETED_PAYMENT                = 'CLEAR_DELETED_PAYMENT';

export const CREATE_STRIPE_INTENT                 = 'CREATE_STRIPE_INTENT';
export const CREATE_STRIPE_INTENT_FULFILLED       = 'CREATE_STRIPE_INTENT_FULFILLED';
export const CREATE_STRIPE_INTENT_PENDING         = 'CREATE_STRIPE_INTENT_PENDING';
export const CREATE_STRIPE_INTENT_REJECTED        = 'CREATE_STRIPE_INTENT_REJECTED';
