import React from 'react';
import { PropTypes } from 'prop-types';
import Link from '../common/Link';
import Icon from 'shared/components/Icon';

class UserDropdown extends React.Component {

  switchAccountLinkItem() {
    if (this.props.currentUser.portal_user.portal_divisions.length) {
      return (
        <Link className="dropdown-item" noEvent onClick={this.props.toggleSwitchAccountModal}>
          {this.props.i18n.portal.payer.menu.switch_account}
        </Link>
      );
    }
  }

  logoutLinkItem() {
    return (
      <Link className="dropdown-item" noEvent onClick={this.props.logout}>
        {this.props.content.sign_out_label}
      </Link>
    );
  }

  exitLinkItem() {
    if (this.props.config.exit_url) {
      return (
        <Link className="dropdown-item" onClick={this.props.exit}>
          {this.props.content.exit_label}
        </Link>
      );
    }
  }

  dropdownContents() {
    if (!this.props.config.disable_logout) {
      return (
        <div className="dropdown-menu" aria-labelledby="userDropdownToggle">
          {this.switchAccountLinkItem()}
          {this.logoutLinkItem()}
          {this.exitLinkItem()}
        </div>
      );
    }
  }

  tabIndex() {
    return this.props.focusable ? 0 : -1;
  }

  toggleWithoutPayerInfo() {
    return (
      <div>
        <i className="user-icon fa fa-fw" />
        {this.props.currentUser.name}{' '}
      </div>
    );
  }

  toggleWithPayerInfo() {
    return (
      <div className="d-flex flex-column">
        <div className="portal-division">
          {this.props.currentUser.portal_division.name}
        </div>
        <div className="user-info">
          <Icon type="user" />
          {this.props.currentUser.name}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="dropdown d-flex justify-content-end user-dropdown">
        <button
          aria-expanded="false"
          aria-haspopup="true"
          className="dropdown-toggle d-flex align-items-center user-link"
          data-toggle="dropdown"
          id="userDropdownToggle"
          tabIndex={this.tabIndex()}
          type="button"
        >
          {this.props.showPayerInfo ?
            this.toggleWithPayerInfo() :
            this.toggleWithoutPayerInfo()}
        </button>
        {this.dropdownContents()}
      </div>
    );
  }
}

UserDropdown.propTypes = {
  config: PropTypes.object,
  showPayerInfo: PropTypes.bool,
  currentUser: PropTypes.object,
  toggleSwitchAccountModal: PropTypes.func,
  content: PropTypes.object,
  i18n: PropTypes.object,
  exit: PropTypes.func,
  logout: PropTypes.func,
  focusable: PropTypes.bool
};

UserDropdown.defaultProps = {
  focusable: true
};

export default UserDropdown;
