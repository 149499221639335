import React, { Fragment } from 'react';
import { PropTypes } from 'prop-types';
import Modal from './Modal';
import PayMethodForm from './PayMethodForm';
import FormErrors from 'shared/components/FormErrors';

class PayMethodModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isNewPayMethodValid: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePayMethodChange = this.handlePayMethodChange.bind(this);

    this.modal = React.createRef();
  }

  content() {
    return (
      <Fragment>
        {this.formErrors()}
        {this.payMethodForm()}
      </Fragment>
    );
  }

  formErrors() {
    return (
      <FormErrors
        errors={this.props.errors}
        dismiss={this.props.clearErrors} />
    );
  }

  payMethodForm() {
    return (
      <form
        className="form-themed"
        onSubmit={this.handleSubmit}
        noValidate="noValidate">
        <PayMethodForm
          newPayMethodMode
          ref="PayMethodForm"
          creditCardEnabled={this.props.creditCardEnabled}
          plastiqEnabled={this.props.plastiqEnabled}
          onChange={this.handlePayMethodChange}
          onError={this.props.onError}
          clearErrors={this.props.clearErrors}
          labelClass="col-form-label col-sm-5 text-sm-right"
          fieldClass="col-sm-6"
          i18n={this.props.i18n}
          config={this.props.config}
          content={this.props.content}
          abilities={this.props.abilities}
          createStripeIntent={this.props.createStripeIntent}
          stripeEnabled={this.props.config.pay_method_config.stripe_enabled}
          stripeIntent={this.props.stripeIntent}
        />
        <input type="submit" className="d-none" />
      </form>
    );
  }

  handlePayMethodChange(data) {
    this.setState({
      newPayMethod: data.newPayMethod,
      isNewPayMethodValid: data.isNewPayMethodValid
    });
  }

  handleSubmit(e) {
    if (e) e.preventDefault();
    this.refs.PayMethodForm.registerNewPayMethod(this.props.onSubmit);
  }

  render() {
    return (
      <Modal
        ref={this.modal}
        showClose
        showConfirm
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        errors={this.props.errors}
        className="pay-methods-modal"
        title={this.props.i18n.portal.modals.pay_methods.new_pay_method}
        message={this.content()}
        closeLabel={this.props.i18n.common.cancel}
        confirmLabel={this.props.i18n.common.submit}
        confirmEnabled={this.state.isNewPayMethodValid}
        onConfirm={this.handleSubmit} />
    );
  }
}

PayMethodModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  errors: PropTypes.array,
  clearErrors: PropTypes.func,
  onError: PropTypes.func,
  onSubmit: PropTypes.func,
  i18n: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  abilities: PropTypes.object.isRequired,
  creditCardEnabled: PropTypes.bool,
  plastiqEnabled: PropTypes.bool,
  createStripeIntent: PropTypes.func,
  stripeIntent: PropTypes.string,
};

export default PayMethodModal;

