import { http } from 'shared/api';

class ResetPasswordAPI {
  static create(email) {
    return http.post('password_resets', {
      user: { email: email }
    });
  }

  static update(token, user) {
    return http.put(`password_resets/${token}`, {
      user
    });
  }
}

export default ResetPasswordAPI;
