import React from 'react';
import { PropTypes } from 'prop-types';
import Table from './Table';
import Spinner from './Spinner';

class UsersTable extends React.Component {

  columns() {
    return ["name", "email"];
  }

  columnMetadata() {
    return [
      {
        "columnName": "name",
        "displayName": this.props.i18n.common.name
      },
      {
        "columnName": "email",
        "displayName": this.props.i18n.common.email
      }
    ];
  }

  render() {
    if (this.props.results) {
      return (
        <Table
          { ...this.props }
          columns={this.columns()}
          columnMetadata={this.columnMetadata()}
          className="users-table"
          filterPlaceholder={this.props.i18n.common.filter}
          noDataMessage={this.props.i18n.portal.data_table.no_results} />
      );
    }
    else {
      return <Spinner centered size="medium" />;
    }
  }
}

UsersTable.propTypes = {
  results: PropTypes.array,
  columns: PropTypes.array,
  i18n: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired
};

export default UsersTable;
