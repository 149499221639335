import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

class AccountDetail extends Component {
  render() {
    if (this.props.show) {
      return (
        <tr>
          <td className="td-label">{this.props.label}</td>
          <td className="td-value amount-value">{this.props.value}</td>
        </tr>
      );
    }
    else {
      return null;
    }
  }
}

AccountDetail.propTypes = {
  show: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string
};

export default AccountDetail;
