import React from 'react';
import { PropTypes } from 'prop-types';
import AccountDetail from './AccountDetail';
import accounting from 'accounting';
import Link from '../common/Link';
import { Popover, PopoverTitle, PopoverContent } from 'reactstrap';
import Button from '../common/Button';
import NoticePopover from '../common/NoticePopover';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';

class AccountDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false
    };
    this.togglePopover = this.togglePopover.bind(this);
  }

  togglePopover() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  accountNoticeButton() {
    if (this.props.showNotice && this.props.content.payment_account_notice) {
      return (
        <Link className="btn-help"onClick={this.togglePopover}>
          <span id="AccountNoticePopover">
            <i className="fa icon-help"></i>
          </span>
        </Link>
      );
    }
  }

  accountNoticeText() {
    if (this.props.showNotice && this.props.content.payment_account_notice) {
      return (
        <NoticePopover
          placement={"left"}
          isOpen={this.state.popoverOpen}
          target={"AccountNoticePopover"}
          toggle={this.togglePopover}
          source={this.props.content.payment_account_notice}
          className="payment-account-notice left"
          containerTagName="span" />
      );
    }
  }

  detailsTable() {
    return (
      <table className="account-info-table">
        <thead>
          {this.accountLabel()}
        </thead>
        <tbody>
          <AccountDetail
            show={this.props.account.payoff_amount != null}
            label={this.props.config.feature.receivable_accounts_balance_label}
            value={accounting.formatMoney(this.props.account.balance)} />

          <AccountDetail
            show={this.props.account.payment_amount != null}
            label={this.props.config.feature.receivable_accounts_payment_amount_label}
            value={accounting.formatMoney(this.props.account.payment_amount)} />

          <AccountDetail
            show={this.props.account.due_amount != null}
            label={this.props.config.feature.receivable_accounts_due_amount_label}
            value={accounting.formatMoney(this.props.account.due_amount)} />

          <AccountDetail
            show={this.props.account.due_date != null}
            label={this.props.config.feature.receivable_accounts_due_date_label}
            value={moment(this.props.account.due_date).format("MM/DD/YYYY")} />
        </tbody>
      </table>
    );
  }

  accountLabel() {
    if (this.props.account && this.props.showLabel) {
      return (
        <tr className="account-label">
          <td colSpan="2">
            <strong>{this.props.account.account_label}</strong>
          </td>
        </tr>
      );
    }
  }

  render() {
    return (
      <div className="account-info">
        {this.accountNoticeButton()}
        {this.accountNoticeText()}
        {this.detailsTable()}
      </div>
    );
  }
}

AccountDetails.propTypes = {
  account: PropTypes.object.isRequired,
  showLabel: PropTypes.bool,
  showNotice: PropTypes.bool,
  content: PropTypes.object,
  config: PropTypes.object.isRequired
};

export default AccountDetails;
