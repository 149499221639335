/* eslint-disable react/display-name, react/no-multi-comp */
import React from 'react';
import { PropTypes } from 'prop-types';
import Griddle from 'griddle-react';
import Filter from './Filter';
import Pagination from 'shared/components/Pagination';
import { _invert } from '../../utils/enumerable';
import format from '../../utils/formatUtils';

class Table extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      columnMap: this.props.columnMap
    };

    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.externalSetFilter = this.externalSetFilter.bind(this);
    this.externalChangeSort = this.externalChangeSort.bind(this);
    this.updatePagination = this.updatePagination.bind(this);
  }

  filter() {
    if (this.props.showFilter) {
      return (
        <Filter
          placeholder={this.props.filterPlaceholder}
          onChange={this.handleFilterChange}
          showDateFilter={this.props.showDateFilter}
          i18n={this.props.i18n}
          filterQuery={this.props.filterQuery} />
      );
    }
  }

  handleFilterChange(query) {
    if (this.props.useExternal) {
      this.props.externalSetFilter(query);
    }
    else {
      this.refs.Griddle.setFilter(query);
      setTimeout(this.updatePagination, 1);
    }
  }

  updatePagination() {
    const griddleState = this.refs.Griddle.state;
    const filteredResults = griddleState.filteredResults;
    const totalItems = filteredResults ? filteredResults.length : this.props.results.length;
    this.refs.Pagination.setCurrentPage(griddleState.page);
    this.refs.Pagination.setTotalItems(totalItems);
  }

  handleRowClick(row) {
    if (this.props.onRowClick) {
      this.props.onRowClick(row.props.data);
    }
  }

  getColumns() {
    if (this.props.columnMap) {
      return this.props.columns.map((column) => {
        return this.state.columnMap[column];
      });
    }
    else {
      return this.props.columns;
    }
  }

  externalChangeSort(column, sortAscending) {
    const sortColumn = _invert(this.state.columnMap)[column];
    const sortDirection = sortAscending ? 'ASC' : 'DESC';
    const sortOrder = `${sortColumn} ${sortDirection}`;
    const newSortOrder = (this.props.data.sort_order == `${sortColumn} DESC`) ? null : sortOrder;
    this.props.externalChangeSort(newSortOrder);
  }

  externalSetFilter(query) {
    // disabled
  }

  externalSetPageSize() {
    // disabled
  }

  getExternalSortColumn() {
    const sortOrder = this.props.data.sort_order;
    if (sortOrder) {
      const column = sortOrder.split(' ')[0];
      return this.state.columnMap[column];
    }
  }

  getExternalSortAscending() {
    const sortOrder = this.props.data.sort_order;
    return sortOrder ? (sortOrder.split(' ')[1] == "ASC") : false;
  }

  getCurrentPage() {
    return this.props.useExternal ? (this.props.data.current_page || 1) : 1;
  }

  getTotalItems() {
    return this.props.useExternal ? this.props.totalItems : this.props.results.length;
  }

  getMaxPage() {
    return this.props.useExternal ? this.props.data.items_per_page : 5;
  }

  getColumnMetadata() {
    return this.props.columnMetadata.map((metadata) => {
      const customCompareFn = this.props.useExternal ? (() => { }) : null;
      const customComponent = this.getColumnComponent(metadata);
      return {
        ...metadata,
        customCompareFn,
        customComponent
      };
    });
  }

  getColumnComponent(metadata) {
    if (metadata.customComponent) {
      return metadata.customComponent;
    }
    else {
      switch (metadata.format) {
        case "date":
          return this.dateComponent();
        case "currency":
          return this.currencyComponent();
        default:
          return null;
      }
    }
  }

  dateComponent() {
    return class extends React.Component {
      render() {
        return <span>{format.date(this.props.data)}</span>;
      }
    };
  }

  currencyComponent() {
    return class extends React.Component {
      render() {
        return <span>{format.currency(this.props.data)}</span>;
      }
    };
  }

  table() {
    const useExternal = this.props.useExternal;
    return (
      <div className="card clear">
        <div className="table-responsive">
          <Griddle
            {...this.props}
            ref="Griddle"
            columns={this.getColumns()}
            columnMetadata={this.getColumnMetadata()}
            onRowClick={this.handleRowClick}
            rowMetadata={this.getRowMetadata()}
            showPager={false}
            showFilter={false}
            useGriddleStyles={false}
            tableClassName={this.getTableClassName()}
            sortAscendingClassName="sort-asc"
            sortDescendingClassName="sort-desc"
            sortAscendingComponent={null}
            sortDescendingComponent={null}
            externalSetPageSize={this.externalSetPageSize}
            customNoDataComponent={this.noDataComponent()}
            externalSetFilter={this.externalSetFilter}
            externalChangeSort={this.externalChangeSort}
            externalSortColumn={useExternal && this.getExternalSortColumn()}
            externalSortAscending={useExternal && this.getExternalSortAscending()}
            externalCurrentPage={useExternal && this.getCurrentPage()}
            externalMaxPage={useExternal && this.getMaxPage()} />
        </div>
      </div>
    );
  }

  pagination() {
    if (!this.props.useExternal || (this.props.useExternal && this.props.data)) {
      return (
        <Pagination
          ref="Pagination"
          totalItems={this.getTotalItems()}
          itemsPerPage={this.getMaxPage()}
          currentPage={this.getCurrentPage()}
          onPageChange={this.handlePageChange} />
      );
    }
  }

  handlePageChange(data) {
    const page = this.props.useExternal ? data.selected : data.selected - 1;
    this.refs.Griddle.setPage(page);
  }

  getRowMetadata() {
    return {
      bodyCssClassName: (rowData) => {
        if (this.props.isRowSelected && this.props.isRowSelected(rowData)) {
          return "selected";
        }
      }
    };
  }

  getTableClassName() {
    const selectableClass = this.props.selectable ? 'table-selectable' : '';
    return `table data-table ${this.props.className} ${selectableClass}`;
  }

  noDataComponent() {
    const noDataMessage = this.props.noDataMessage;
    return class extends React.Component {
      render() {
        return <div className="no-results">{noDataMessage}</div>;
      }
    };
  }

  heading() {
    if (this.props.heading) {
      return <h1>{this.props.heading}</h1>;
    }
  }

  actions() {
    if (this.props.actions && this.props.showActions) {
      return (
        <span className={`actions-toolbar actions-${this.props.actionsAlign}`}>
          {this.props.actions}
        </span>
      );
    }
  }

  header() {
    if (this.props.heading || this.props.actions) {
      return (
        <section className="header-section">
          {this.heading()}
          {this.actions()}
        </section>
      );
    }
  }

  render() {
    return (
      <section className="data-table-section">
        <h1 className="sr-only">{this.props.heading} {this.props.i18n.portal.ada_headers.table}</h1>
        {this.header()}
        {this.filter()}
        {this.table()}
        {this.pagination()}
      </section>
    );
  }
}

Table.defaultProps = {
  showFilter: true,
  actionsAlign: "right"
};

Table.propTypes = {
  results: PropTypes.array,
  data: PropTypes.object,
  columns: PropTypes.array.isRequired,
  columnMap: PropTypes.object,
  className: PropTypes.string,
  heading: PropTypes.string,
  selectable: PropTypes.bool,
  showFilter: PropTypes.bool,
  columnMetadata: PropTypes.array,
  showDateFilter: PropTypes.bool,
  filterQuery: PropTypes.string,
  filterPlaceholder: PropTypes.string,
  totalItems: PropTypes.number,
  noDataMessage: PropTypes.string,
  actions: PropTypes.element,
  actionsAlign: PropTypes.oneOf(['left', 'right']),
  showActions: PropTypes.bool,
  onRowClick: PropTypes.func,
  isRowSelected: PropTypes.func,
  useExternal: PropTypes.bool,
  externalSetFilter: PropTypes.func,
  externalSetPage: PropTypes.func,
  externalChangeSort: PropTypes.func,
  i18n: PropTypes.object.isRequired
};

export default Table;
