import React from 'react';
import { PropTypes } from 'prop-types';
import Table from './Table';
import Icon from 'shared/components/Icon';
import Spinner from './Spinner';

class PayMethodsTable extends React.Component {

  columns() {
    const columns = ["type_name", "details", "expiration_info"];
    if (this.props.config.pay_method_config.show_pay_method_payee_field) columns.push("payee_name");
    if (this.props.showDefaultColumn) columns.push("default");
    return columns;
  }

  columnMetadata() {
    return [
      {
        "columnName": "type_name",
        "displayName": this.props.i18n.common.type,
        "customComponent": this.typeComponent()
      },
      {
        "columnName": "details",
        "displayName": this.props.i18n.common.details
      },
      {
        "columnName": "payee_name",
        "displayName": this.props.i18n.common.name_on_account
      },
      {
        "columnName": "expiration_info",
        "displayName": this.props.i18n.common.expires,
        "customComponent": this.expirationComponent()
      },
      {
        "columnName": "default",
        "displayName": " ",
        "customComponent": this.defaultComponent()
      }
    ];
  }

  typeComponent() {
    const content = this.props.content;
    // eslint-disable-next-line react/display-name, react/no-multi-comp
    return class extends React.Component {
      getPayMethodIcon() {
        // eslint-disable-next-line react/prop-types
        switch (this.props.rowData.type) {
          case "AchPayMethod":
            return "bank";
          case "CreditCardPayMethod":
            return "credit-card";
          case "CheckPayMethod":
            return "";
          case "PlastiqPayMethod":
            return "credit-card far";
        }
      }

      getPayMethodName() {
        // eslint-disable-next-line react/prop-types
        switch (this.props.rowData.type) {
          case "AchPayMethod":
            return content.ach_label;
          case "CreditCardPayMethod":
            return content.credit_card_label;
          case "CheckPayMethod":
            return content.check_label;
          case "PlastiqPayMethod":
            return content.plastiq_label;
        }
      }

      render() {
        // eslint-disable-next-line react/prop-types
        const typeName = this.props.rowData.type_name;
        return (
          <div>
            <span className="text-muted">
              <Icon type={this.getPayMethodIcon()} />{' '}
            </span>
            {this.getPayMethodName()}
          </div>
        );
      }
    };
  }

  defaultComponent() {
    return ({ rowData }) => {
      if (rowData.default) {
        return (
          <span className="text-muted">
            <Icon type="check" />{' '}
            {this.props.i18n.common.default}
          </span>
        );
      }
      else {
        return null;
      }
    };
  }

  expirationComponent() {
    return ({ rowData  }) => {
      const { is_expired, expiration_info } = rowData;

      if (is_expired) {
        return (
          <span className="text-red">
            {expiration_info}
          </span>
        );
      }
      else {
        return (
          <span>{expiration_info}</span>
        );
      }
    };
  }

  render() {
    if (this.props.results) {
      return (
        <Table
          { ...this.props }
          columns={this.columns()}
          columnMetadata={this.columnMetadata()}
          className="pay-methods-table"
          initialSort="id"
          filterPlaceholder={this.props.i18n.common.filter}
          noDataMessage={this.props.i18n.portal.data_table.no_results} />
      );
    }
    else {
      return <Spinner centered size="medium" />;
    }
  }
}

PayMethodsTable.propTypes = {
  results: PropTypes.array,
  columns: PropTypes.array,
  showDefaultColumn: PropTypes.bool,
  i18n: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired
};

export default PayMethodsTable;
