export const FETCH_PAYMENT                    = 'FETCH_PAYMENT';
export const FETCH_PAYMENT_FULFILLED          = 'FETCH_PAYMENT_FULFILLED';
export const FETCH_PAYMENT_PENDING            = 'FETCH_PAYMENT_PENDING';
export const FETCH_PAYMENT_REJECTED           = 'FETCH_PAYMENT_REJECTED';

export const FETCH_STAGED_PAYMENT             = 'FETCH_STAGED_PAYMENT';
export const FETCH_STAGED_PAYMENT_FULFILLED   = 'FETCH_STAGED_PAYMENT_FULFILLED';
export const FETCH_STAGED_PAYMENT_PENDING     = 'FETCH_STAGED_PAYMENT_PENDING';
export const FETCH_STAGED_PAYMENT_REJECTED    = 'FETCH_STAGED_PAYMENT_REJECTED';

export const CONFIRM_PAYMENT                  = 'CONFIRM_PAYMENT';
export const CONFIRM_PAYMENT_FULFILLED        = 'CONFIRM_PAYMENT_FULFILLED';
export const CONFIRM_PAYMENT_PENDING          = 'CONFIRM_PAYMENT_PENDING';
export const CONFIRM_PAYMENT_REJECTED         = 'CONFIRM_PAYMENT_REJECTED';

export const UPDATE_PAYMENT                   = 'UPDATE_PAYMENT';
export const UPDATE_PAYMENT_FULFILLED         = 'UPDATE_PAYMENT_FULFILLED';
export const UPDATE_PAYMENT_PENDING           = 'UPDATE_PAYMENT_PENDING';
export const UPDATE_PAYMENT_REJECTED          = 'UPDATE_PAYMENT_REJECTED';
