import React from 'react';
import { PropTypes } from 'prop-types';
import zxcvbn from 'zxcvbn';

import Link from './Link';
import NoticePopover from './NoticePopover';

const scoreClasses = [
  'text-danger',
  'text-danger',
  'text-warning',
  'text-warning',
  'text-success',
];

const scoreBackgroundClasses = [
  'bg-danger',
  'bg-danger',
  'bg-warning',
  'bg-warning',
  'bg-success',
];

class PasswordStrength extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: '',
      password: '',
      popoverOpen: false,
      score: 0,
    };

    this.setPassword = this.setPassword.bind(this);
    this.togglePopover = this.togglePopover.bind(this);
  }

  setPassword(event) {
    const { changeCallback, labels } = this.props;

    const password = event.target.value;
    const score =  zxcvbn(password).score;

    const containsDigits = /[0-9]/.test(password);
    const containsUpper = /[A-Z]/.test(password);
    const containsLower = /[a-z]/.test(password);

    let { message } = this.state;
    let isValid = false;

    if (password.length == 0) {
      message = '';
    } else if (password.length < 8) {
      message = labels.min_length;
    } else if (!containsDigits || !containsUpper || !containsLower) {
      message = labels.special_character;
    } else {
      message = '';
      isValid = true;
    }

    this.setState({ message, password, score }, function() {
      changeCallback({ isValid, password, message });
    });
  }

  scoreWords() {
    const { labels } = this.props;

    return [
      labels.weak,
      labels.okay,
      labels.good,
      labels.strong,
      labels.stronger,
    ];
  }

  scoreWord() {
    const { password, score } = this.state;

    if (password.length == 0) return;

    return (
      <div className="input-group-append">
        <div className={`input-group-text text-uppercase ${scoreClasses[score]}`}>
          {this.scoreWords()[score]}
        </div>
      </div>
    );
  }

  notice() {
    const { content } = this.props;
    const { popoverOpen } = this.state;

    if (!content.password_notice) return;

    return (
      <>
        <div className="input-group-append">
          <Link
            className="btn btn-outline-primary"
            onClick={this.togglePopover}
          >
            <span id="passwordNoticePopover">
              <i className="fa icon-help"></i>
            </span>
          </Link>
        </div>

        <NoticePopover
          placement={"top"}
          isOpen={popoverOpen}
          target={"passwordNoticePopover"}
          toggle={this.togglePopover}
          source={content.password_notice}
          className="password-notice top"
          containerTagName="span" />
      </>
    );
  }

  togglePopover() {
    this.setState({ popoverOpen: !this.state.popoverOpen });
  }

  render() {
    const { inputProps } = this.props;
    const { message, password, score } = this.state;

    return (
      <>
        <div className="input-group">
          <input
            className="form-control"
            type="password"
            onChange={this.setPassword}
            value={password}
            {...inputProps}
          />

          {this.scoreWord()}
          {this.notice()}
        </div>

        <div className="progress mt-1" style={{ height: '4px' }}>
          <div
            className={`progress-bar ${scoreBackgroundClasses[score]}`}
            style={
              password.length > 0 ?
                { width: `${(score + 1) / 5 * 100}%` } :
                {}
            }
            role="progressbar"
            aria-valuenow={password.length > 0 ? score + 1 : 0}
            aria-valuemin="0"
            aria-valuemax="5"></div>
        </div>

        {message.length > 0 ?
          <small className="form-text text-muted">{message}</small> :
          null}
      </>
    );
  }
}

PasswordStrength.defaultProps = {
  value: '',
};

PasswordStrength.propTypes = {
  changeCallback: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
  inputProps: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
};

export default PasswordStrength;
