import React from 'react';
import { PropTypes } from 'prop-types';

class NavbarToggle extends React.Component {
  render() {
    const { onClick, target } = this.props;

    return (
      <button
        className="navbar-toggler"
        type="button"
        data-toggle={target ? 'collapse' : null}
        data-target={target ? `#${target}` : null}
        aria-controls={target}
        aria-expanded={target ? 'false' : null}
        aria-label="Toggle navigation"
        onClick={onClick}
      >
        <span className="navbar-toggler-icon"></span>
      </button>
    );
  }
}

NavbarToggle.defaultProps = {
  onClick: () => {},
};

NavbarToggle.propTypes = {
  onClick: PropTypes.func,
  target: PropTypes.string,
};

export default NavbarToggle;
