import React from 'react';
import { PropTypes } from 'prop-types';
import Modal from './Modal';
import AccountsTable from './AccountsTable';
import Spinner from './Spinner';

class AccountsModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      accountsQuery: null,
      accountsSortOrder: null
    };
    this.filterAccounts = this.filterAccounts.bind(this);
    this.pageAccounts = this.pageAccounts.bind(this);
    this.sortAccounts = this.sortAccounts.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.isOpen && nextProps.isOpen) {
      this.clearFilters();
      this.props.clearAccounts();
      this.props.fetchAccounts(this.props.fetchParams);
    }
  }

  clearFilters() {
    this.setState({
      accountsQuery: null,
      accountsSortOrder: null
    });
  }

  filterAccounts(query) {
    this.setState({ accountsQuery: query });
    this.props.fetchAccounts({
      ...this.props.fetchParams,
      query,
      sort_order: this.state.accountsSortOrder
    });
  }

  pageAccounts(page) {
    this.props.fetchAccounts({
      ...this.props.fetchParams,
      page,
      query: this.state.accountsQuery,
      sort_order: this.state.accountsSortOrder
    });
  }

  sortAccounts(sortOrder) {
    this.setState({ accountsSortOrder: sortOrder });
    this.props.fetchAccounts({
      ...this.props.fetchParams,
      sort_order: sortOrder,
      query: this.state.accountsQuery
    });
  }

  accountsTable() {
    if (this.props.accounts.data) {
      return (
        <AccountsTable
          selectable
          useExternal
          data={this.props.accounts.data}
          columns={this.props.config.feature.portal_account_assignments_manage_columns}
          onRowClick={this.props.onRowClick}
          filterPlaceholder={this.props.i18n.portal.accounts_table.filter_accounts}
          filterQuery={this.state.accountsQuery}
          i18n={this.props.i18n}
          content={this.props.content}
          externalSetFilter={this.filterAccounts}
          externalSetPage={this.pageAccounts}
          externalChangeSort={this.sortAccounts} />
      );
    }
    else {
      return <Spinner centered size="medium" />;
    }
  }

  render() {
    return (
      <Modal
        showClose
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className="accounts-modal"
        title={this.props.i18n.portal.otp.select_account}
        message={this.accountsTable()}
        closeLabel={this.props.i18n.common.cancel} />
    );
  }
}

AccountsModal.propTypes = {
  accounts: PropTypes.object.isRequired,
  clearAccounts: PropTypes.func,
  fetchAccounts: PropTypes.func,
  fetchParams: PropTypes.object,
  onRowClick: PropTypes.func,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  i18n: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired
};

export default AccountsModal;
