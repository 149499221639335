import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router';

class ResetMessage extends React.Component {

  resetMessageFormFooter() {
    return (
      <footer className="centered">
        <Link to="/login" className="btn btn-link">
          {this.props.content.i18n.portal.password_reset.return_to_sign_in}
        </Link>
      </footer>
    );
  }

  render() {
    return (
      <div>
      <h1>{this.props.content.i18n.common.thanks}</h1>
      <p className="lead">{this.props.content.i18n.portal.password_reset.check_email}</p>
        {this.resetMessageFormFooter()}
      </div>
    );
  }
}

ResetMessage.propTypes = {
  i18n: PropTypes.object,
  config: PropTypes.object,
  content: PropTypes.object
};

export default ResetMessage;
