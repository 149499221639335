import React from 'react';
import { PropTypes } from 'prop-types';
import Table from './Table';
import Spinner from './Spinner';
import Link from './Link';

class ExternalPaymentsTable extends React.Component {

  columnMap() {
    return {
      'external_payments.payment_date':       'payment_date',
      'receivable_accounts.external_key':     'receivable_account.external_key',
      'payers.name':                          'receivable_account.payer.name',
      'external_payments.external_type':      'external_type',
      'external_payments.external_reference': 'external_reference',
      'external_payments.amount':             'amount'
    };
  }

  columnMetadata() {
    return [
      {
        columnName: "payment_date",
        displayName: this.props.i18n.common.date,
        format: "date"
      },
      {
        columnName: "receivable_account.external_key",
        displayName: this.props.content.receivable_accounts_external_key_label
      },
      {
        columnName: "receivable_account.payer.name",
        displayName: this.props.content.payers_name_label
      },
      {
        columnName: "external_type",
        displayName: this.props.content.external_payments_external_type_label
      },
      {
        columnName: "external_reference",
        displayName: this.props.content.external_payments_external_reference_label
      },
      {
        columnName: "amount",
        displayName: this.props.i18n.common.amount,
        cssClassName: 'right',
        format: "currency"
      }
    ];
  }

  render() {
    if (this.props.useExternal && !this.props.data) {
      return <Spinner centered size="medium" />;
    }
    else {
      return (
        <Table
          { ...this.props }
          showDateFilter
          results={this.props.data.external_payments || []}
          totalItems={this.props.data.total_items}
          columns={this.props.config.feature.portal_external_payments_columns}
          columnMap={this.columnMap()}
          columnMetadata={this.columnMetadata()}
          className="external-payments-table"
          filterPlaceholder={this.props.i18n.portal.external_payments_table.filter_payments}
          noDataMessage={this.props.i18n.portal.data_table.no_results} />
      );
    }
  }
}

ExternalPaymentsTable.propTypes = {
  data: PropTypes.object,
  i18n: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  showBillExternalKeyAsLink: PropTypes.bool,
  onBillExternalKeyClick: PropTypes.func,
  useExternal: PropTypes.bool,
  externalSetFilter: PropTypes.func,
  externalSetPage: PropTypes.func,
  externalChangeSort: PropTypes.func
};

export default ExternalPaymentsTable;
