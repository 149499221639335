import { http } from 'shared/api';

class PortalAgreementAPI {

  static createPortalAgreement(portalUser) {
    return http.post(`portal_users/${portalUser.id}/portal_agreement`);
  }
}

export default PortalAgreementAPI;
