import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router';
import ResetMessage from './ResetMessage';

class ForgotPasswordPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      submitDisabled: true
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    this.props.clearPasswordReset();
  }

  resetFooter() {
    return (
      <footer className="centered">
        <Link to="/login" className="btn btn-link">
          Sign In
        </Link>
      </footer>
    );
  }

  handleSubmit($event) {
    $event.preventDefault();
    const email = this.refs.email.value;
    this.props.reset(email);
  }

  handleChange() {
    if (this.refs.email.value.length > 0) {
      this.setState({submitDisabled: false});
    }
    else {
      this.setState({submitDisabled: true});
    }
  }

  resetForm() {
    return (
      <form
        onSubmit={this.handleSubmit}
        className="form-forgot-password landing-form"
        name="resetForm"
        ref="resetForm"
        noValidate="novalidate">

        <h1>{this.props.content.portal_title}</h1>
        <p className="lead">{this.props.i18n.portal.password_reset.reset_password}</p>
        <section className="inputs">
          <div className="inner">
            <input
              required
              className="form-control form-control-lg"
              name="email"
              ref="email"
              placeholder={this.props.i18n.common.email_address}
              size="30"
              type="email"
              onChange={this.handleChange}
            />
            <input
              className="btn primary-btn btn-lg btn-block"
              disabled={this.state.submitDisabled}
              type="submit"
              value={this.props.i18n.portal.password_reset.reset_password} />
          </div>
        </section>

        {this.resetFooter()}
      </form>
    );
  }

  resetMessage() {
    return (
      <ResetMessage content={this.props} />
    );
  }

  render() {
    return (
      <div id="portal-login">
        <div className="card panel-landing">
          <div className="card-body">
            {this.props.resetPassword ? this.resetMessage() : this.resetForm()}
          </div>
        </div>
      </div>
    );
  }
}

ForgotPasswordPage.propTypes = {
  i18n: PropTypes.object,
  config: PropTypes.object,
  content: PropTypes.object,
  reset: PropTypes.func,
  resetPassword: PropTypes.bool,
  clearPasswordReset: PropTypes.func
};

export default ForgotPasswordPage;
