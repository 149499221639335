export const FETCH_SPLITS                     = 'FETCH_SPLITS';
export const FETCH_SPLITS_FULFILLED           = 'FETCH_SPLITS_FULFILLED';
export const FETCH_SPLITS_PENDING             = 'FETCH_SPLITS_PENDING';
export const FETCH_SPLITS_REJECTED            = 'FETCH_SPLITS_REJECTED';

export const SAVE_SPLIT                       = 'SAVE_SPLIT';
export const SAVE_SPLIT_FULFILLED             = 'SAVE_SPLIT_FULFILLED';
export const SAVE_SPLIT_PENDING               = 'SAVE_SPLIT_PENDING';
export const SAVE_SPLIT_REJECTED              = 'SAVE_SPLIT_REJECTED';

export const SAVE_SPLITS                       = 'SAVE_SPLITS';
export const SAVE_SPLITS_FULFILLED             = 'SAVE_SPLITS_FULFILLED';
export const SAVE_SPLITS_PENDING               = 'SAVE_SPLITS_PENDING';
export const SAVE_SPLITS_REJECTED              = 'SAVE_SPLITS_REJECTED';

export const UPDATE_SPLIT                     = 'UPDATE_SPLIT';
export const UPDATE_SPLIT_FULFILLED           = 'UPDATE_SPLIT_FULFILLED';
export const UPDATE_SPLIT_PENDING             = 'UPDATE_SPLIT_PENDING';
export const UPDATE_SPLIT_REJECTED            = 'UPDATE_SPLIT_REJECTED';

export const DELETE_SPLIT                     = 'DELETE_SPLIT';
export const DELETE_SPLIT_FULFILLED           = 'DELETE_SPLIT_FULFILLED';
export const DELETE_SPLIT_PENDING             = 'DELETE_SPLIT_PENDING';
export const DELETE_SPLIT_REJECTED            = 'DELETE_SPLIT_REJECTED';

export const DELETE_ALL_SPLITS                = 'DELETE_ALL_SPLITS';
export const DELETE_ALL_SPLITS_FULFILLED      = 'DELETE_ALL_SPLITS_FULFILLED';
export const DELETE_ALL_SPLITS_PENDING        = 'DELETE_ALL_SPLITS_PENDING';
export const DELETE_ALL_SPLITS_REJECTED       = 'DELETE_ALL_SPLITS_REJECTED';
