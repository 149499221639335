import React from 'react';
import { PropTypes } from 'prop-types';
import RecurrenceForm from '../../common/RecurrenceForm';

class BillRecurrenceForm extends React.Component {

  render() {
    return (
      <RecurrenceForm
        requirePayMethod
        showPaymentBreakdownOption
        {...this.props} />
    );
  }
}

BillRecurrenceForm.propTypes = RecurrenceForm.propTypes;

export default BillRecurrenceForm;
