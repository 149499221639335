import React from 'react';
import { PropTypes } from 'prop-types';
import Table from './Table';
import Spinner from './Spinner';
import Link from './Link';

class ExternalBillsTable extends React.Component {

  columnMap() {
    return {
      'external_bills.origin_date': 'origin_date',
      'receivable_accounts.external_key': 'receivable_account.external_key',
      'payers.name': 'receivable_account.payer.name',
      'external_bills.external_key': 'external_key',
      'external_bills.amount': 'amount'
    };
  }

  columnMetadata() {
    return [
      {
        columnName: "origin_date",
        displayName: this.props.i18n.common.date,
        format: "date"
      },
      {
        columnName: "receivable_account.external_key",
        displayName: this.props.content.receivable_accounts_external_key_label
      },
      {
        columnName: "receivable_account.payer.name",
        displayName: this.props.content.payers_name_label
      },
      {
        columnName: "external_key",
        displayName: this.props.content.bills_external_key_label,
        customComponent: this.externalKeyComponent()
      },
      {
        columnName: "amount",
        displayName: this.props.i18n.common.amount,
        cssClassName: 'right',
        format: "currency"
      }
    ];
  }

  columns() {
    return Object.keys(this.columnMap());
  }

  externalKeyComponent() {
    const showAsLink = this.props.showBillExternalKeyAsLink;
    const onClick = this.props.onBillExternalKeyClick;
    // eslint-disable-next-line react/display-name, react/no-multi-comp
    return class extends React.Component {
      constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
      }

      handleClick() {
        // eslint-disable-next-line react/prop-types
        onClick(this.props.rowData);
      }

      render() {
        // eslint-disable-next-line react/prop-types
        if (showAsLink && this.props.rowData.bill_documents_accessible) {
          return <Link onClick={this.handleClick}>{this.props.data}</Link>;
        }
        else {
          return <span>{this.props.data}</span>;
        }
      }
    };
  }

  results() {
    if (this.props.useExternal) {
      return this.props.data.external_bills || [];
    }
    else {
      return this.props.results || [];
    }
  }

  totalItems() {
    if (this.props.useExternal) {
      return this.props.data.total_items;
    }
    else {
      return this.props.results ? this.props.results.length : 0;
    }
  }

  render() {
    if (this.props.useExternal && !this.props.data) {
      return <Spinner centered size="medium" />;
    }
    else {
      return (
        <Table
          { ...this.props }
          results={this.results()}
          totalItems={this.totalItems()}
          columns={this.columns()}
          columnMap={this.columnMap()}
          columnMetadata={this.columnMetadata()}
          className="external-bills-table"
          showDateFilter={this.props.useExternal}
          filterPlaceholder={this.props.i18n.portal.external_bills_table.filter_bills}
          noDataMessage={this.props.i18n.portal.data_table.no_results} />
      );
    }
  }
}

ExternalBillsTable.propTypes = {
  data: PropTypes.object,
  results: PropTypes.array,
  i18n: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  showBillExternalKeyAsLink: PropTypes.bool,
  onBillExternalKeyClick: PropTypes.func,
  useExternal: PropTypes.bool,
  externalSetFilter: PropTypes.func,
  externalSetPage: PropTypes.func,
  externalChangeSort: PropTypes.func
};

export default ExternalBillsTable;
