import React from 'react';
import { PropTypes } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../state/actions';
import Layout from './common/Layout';
import { http } from 'shared/api';
import { getQueryParam } from '../utils/urlUtils';
import { withToastManager } from 'react-toast-notifications';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.startSessionTimer();
    this.interceptErrors();
    window.App = this;
  }

  componentDidMount() {
    const passwordResetToken = getQueryParam("password_reset_token");
    if (passwordResetToken) {
      this.context.router.push('/reset_password');
    }
  }

  componentDidUpdate(prevProps) {
    const route = this.props.children.props.route;
    const isLanding = route.landing == true;
    const isPasswordReset = route.path == 'reset_password';
    if (isLanding && this.props.currentUser && !isPasswordReset) {
      this.handleLogin();
    }
    else if (!isLanding && !this.props.currentUser) {
      this.handleLogout();
    }
    this.handleAddPaymentMethod(prevProps);
  }

  handleAddPaymentMethod(prevProps) {
    if (!prevProps.savedPayMethod && this.props.savedPayMethod) {
      const { savedPayMethod, toastManager, i18n } = this.props;
      const { details } = savedPayMethod;
      toastManager.add(`${i18n.portal.pay_method_success} ${details}`, {
        appearance: 'success',
        autoDismiss: true,
      });
    }
  }


  handleLogin() {
    this.context.router.push('/payer');
    if (this.userSpecifiedLocale()) return;

    if (this.props.currentUser.portal_user.portal_divisions.length) {
      this.handlePortalDivisionChange();
    }

    if (this.props.currentUser.portal_user.portal_locale) {
      this.handlePortalLocaleChange();
    }
  }

  userSpecifiedLocale() {
    const portal_locale = window.location.pathname.split('/')[2];
    return portal_locale === this.props.config.locale;
  }

  handleLogout() {
    this.context.router.push('/login');
    if (this.props.config.portal_division) {
      this.props.setConfigPending();
      window.location.reload(true);
    }
  }

  handlePortalDivisionChange() {
    const configDivision = this.props.config.portal_division;
    const userDivision = this.props.currentUser.portal_division;
    if (!configDivision || configDivision.id != userDivision.id) {
      this.props.setConfigPending();
      window.location.reload(true);
    }
  }

  handlePortalLocaleChange() {
    const configLocale = this.props.config.portal_locale;
    const userLocale = this.props.currentUser.portal_user.portal_locale;

    if (configLocale.id !== userLocale.id) {
      this.props.setConfigPending();
      window.location.reload(true);
    }
  }

  startSessionTimer() {
    setInterval(this.checkSessionTimeout.bind(this), 60000);
  }

  checkSessionTimeout() {
    if (this.props.currentUser) {
      const timeoutUrl = this.props.config.timeout_url;
      const redirect = timeoutUrl ? function() { window.location = timeoutUrl; } : null;
      this.props.checkSessionTimeout(redirect);
    }
  }

  interceptErrors() {
    http.interceptors.response.use(undefined, (error) => {
      const errorUrl = this.props.config.error_url;
      if (errorUrl && error.response.status != 422) {
        window.location = errorUrl;
      }
      else {
        return Promise.reject(error);
      }
    });
  }

  render() {
    return (
      <Layout {...this.props}>
        {this.props.children}
      </Layout>
    );
  }
}

App.contextTypes = {
  router: PropTypes.object
};

App.propTypes = {
  children: PropTypes.object.isRequired,
  checkSessionTimeout: PropTypes.func,
  currentUser: PropTypes.object,
  setConfigPending: PropTypes.func,
  config: PropTypes.object,
  toastManager: PropTypes.object.isRequired,
  savedPayMethod: PropTypes.object,
  i18n: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}

function mapDispachToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispachToProps)(withToastManager(App));
