import React from 'react';
import { PropTypes } from 'prop-types';
import { Link, hashHistory } from 'react-router';
import BillRecurrenceForm from './BillRecurrenceForm';
import BillRecurrencesTable from './BillRecurrencesTable';
import BillRecurrenceOverview from './BillRecurrenceOverview';
import Spinner from '../../common/Spinner';
import Button from '../../common/Button';
import Modal from '../../common/Modal';

class BillRecurrencePage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showForm: false,
      allowFormCancel: false,
      selectedRecurrence: null,
      isRemoveModalOpen: false,
      isSubmitDisabled: false
    };

    this.selectRecurrence = this.selectRecurrence.bind(this);
    this.filterRecurrences = this.filterRecurrences.bind(this);
    this.pageRecurrences = this.pageRecurrences.bind(this);
    this.sortRecurrences = this.sortRecurrences.bind(this);
    this.toggleForm = this.toggleForm.bind(this);
    this.toggleSelectedRecurrence = this.toggleSelectedRecurrence.bind(this);
    this.editRecurrence = this.editRecurrence.bind(this);
    this.toggleRemoveModal = this.toggleRemoveModal.bind(this);
    this.removeSelectedRecurrence = this.removeSelectedRecurrence.bind(this);
    this.returnToBillRecurrencesList = this.returnToBillRecurrencesList.bind(this);
    this.saveRecurrence = this.saveRecurrence.bind(this);
  }

  componentWillMount() {
    this.authorize();
    this.props.clearBillRecurrences();
    this.props.fetchBillRecurrences();
    this.props.fetchPayMethods({
      bill_recurrence_allowed: true
    });
  }

  componentWillReceiveProps(nextProps) {
    this.checkForRecurrences(nextProps);
    this.checkForSavedRecurrence(nextProps);
    this.checkForDeletedRecurrence(nextProps);
  }

  authorize() {
    const enabled = this.props.config.bill_recurrence_enabled;
    const hasAccounts = this.props.currentUser.portal_user.has_bill_recurrence_enabled_accounts;
    const autopayEnabled = enabled && hasAccounts;
    if (!autopayEnabled) {
      hashHistory.push('/payer');
    }
  }

  checkForRecurrences(nextProps) {
    const data = nextProps.billRecurrences.data;
    const hasRecurrences = data ? (data.total_recurrences > 0) : false;
    const initialScreen =  !this.state.selectedRecurrence && !this.state.showForm;
    if (initialScreen && data && !hasRecurrences && !this.state.recurrencesQuery) {
      this.setState({
        showForm: true,
        allowFormCancel: false,
        isSubmitDisabled: false
      });
    }
  }

  checkForSavedRecurrence(nextProps) {
    if (nextProps.savedBillRecurrence) {
      this.setState({
        showForm: false,
        selectedRecurrence: nextProps.savedBillRecurrence,
        isSubmitDisabled: false
      });
      this.props.fetchBillRecurrences();
      this.props.clearSavedBillRecurrence();
    }
  }

  checkForDeletedRecurrence(nextProps) {
    if (nextProps.deletedBillRecurrence) {
      this.setState({
        isRemoveModalOpen: false,
        selectedRecurrence: null,
        isSubmitDisabled: false
      });
      this.props.fetchBillRecurrences();
      this.props.clearDeletedBillRecurrence();
    }
  }

  recurrenceForm() {
    if (this.state.showForm) {
      return (
        <BillRecurrenceForm
          recurrence={this.state.selectedRecurrence}
          payMethods={this.props.payMethods}
          onSubmit={this.saveRecurrence}
          allowCancel={this.state.allowFormCancel}
          onCancel={this.toggleForm}
          content={this.props.content}
          config={this.props.config}
          disabled={this.state.isSubmitDisabled}
          i18n={this.props.i18n} />
      );
    }
  }

  saveRecurrence(recurrence) {
    this.setState({ isSubmitDisabled: true });
    this.props.saveBillRecurrence(recurrence);
  }

  toggleForm() {
    this.setState({
      showForm: !this.state.showForm,
      allowFormCancel: true
    });
  }

  recurrencesTable() {
    if (!this.state.showForm && !this.state.selectedRecurrence) {
      return (
        <BillRecurrencesTable
          selectable
          showActions
          useExternal
          data={this.props.billRecurrences.data}
          heading={"AutoPays"}
          onRowClick={this.selectRecurrence}
          columns={this.props.config.feature.portal_bill_recurrences_columns}
          filterPlaceholder={this.props.i18n.common.filter}
          filterQuery={this.state.recurrencesQuery}
          actions={this.recurrenceActions()}
          i18n={this.props.i18n}
          content={this.props.content}
          externalSetFilter={this.filterRecurrences}
          externalSetPage={this.pageRecurrences}
          externalChangeSort={this.sortRecurrences} />
      );
    }
  }

  recurrenceActions() {
    return (
      <Button icon="plus-circle" onClick={this.toggleForm}>
        {this.props.i18n.common.add}
      </Button>
    );
  }

  filterRecurrences(query) {
    this.setState({ recurrencesQuery: query });
    this.props.fetchBillRecurrences({
      query,
      sort_order: this.state.recurrencesSortOrder
    });
  }

  pageRecurrences(page) {
    this.props.fetchBillRecurrences({
      page,
      query: this.state.recurrencesQuery,
      sort_order: this.state.recurrencesSortOrder
    });
  }

  sortRecurrences(sortOrder) {
    this.setState({ recurrencesSortOrder: sortOrder });
    this.props.fetchBillRecurrences({
      sort_order: sortOrder,
      query: this.state.recurrencesQuery
    });
  }

  selectRecurrence(recurrence) {
    this.setState({ selectedRecurrence: recurrence });
  }

  recurrenceOverview() {
    if (this.state.selectedRecurrence && !this.state.showForm) {
      return (
        <BillRecurrenceOverview
          recurrence={this.state.selectedRecurrence}
          recurrenceAccounts={this.props.recurrenceAccounts}
          fetchRecurrenceAccounts={this.props.fetchRecurrenceAccounts}
          clearRecurrenceAccounts={this.props.clearRecurrenceAccounts}
          portalAccounts={this.props.portalAccounts}
          fetchPortalAccounts={this.props.fetchPortalAccounts}
          clearPortalAccounts={this.props.clearPortalAccounts}
          createRecurrenceAccount={this.props.createRecurrenceAccount}
          createdRecurrenceAccount={this.props.createdRecurrenceAccount}
          clearCreatedRecurrenceAccount={this.props.clearCreatedRecurrenceAccount}
          deleteRecurrenceAccount={this.props.deleteRecurrenceAccount}
          deletedRecurrenceAccount={this.props.deletedRecurrenceAccount}
          clearDeletedRecurrenceAccount={this.props.clearDeletedRecurrenceAccount}
          onReturn={this.returnToBillRecurrencesList}
          onEdit={this.editRecurrence}
          onRemove={this.toggleRemoveModal}
          content={this.props.content}
          config={this.props.config}
          i18n={this.props.i18n} />
      );
    }
  }

  returnToBillRecurrencesList() {
    this.toggleSelectedRecurrence();
    this.props.fetchBillRecurrences();
  }

  editRecurrence() {
    this.setState({ showForm: true, allowFormCancel: true });
  }

  toggleRemoveModal() {
    this.setState({ isRemoveModalOpen: !this.state.isRemoveModalOpen });
  }

  toggleSelectedRecurrence() {
    this.setState({ selectedRecurrence: null });
  }

  loading() {
    if (!this.props.billRecurrences.data) {
      return <Spinner centered size="medium" />;
    }
  }

  removeRecurrenceModal() {
    if (this.state.selectedRecurrence) {
      return (
        <Modal
          showClose
          showConfirm
          title={this.props.i18n.portal.modals.confirm_remove_header}
          message={this.props.i18n.portal.modals.confirm_remove_message}
          confirmLabel={this.props.i18n.common.remove}
          confirmClassName="danger"
          closeLabel={this.props.i18n.common.close}
          onConfirm={this.removeSelectedRecurrence}
          isOpen={this.state.isRemoveModalOpen}
          toggle={this.toggleRemoveModal} />
      );
    }
  }

  removeSelectedRecurrence() {
    this.props.deleteBillRecurrence(this.state.selectedRecurrence);
  }

  render() {
    return (
      <div id="autopay-settings">
        <div className="content-main">
          <section className="settings-section">
            <h1 className="section-heading">
              {this.props.i18n.portal.payer.autopay.manage_autopay}
            </h1>
            <div className="card panel-themed">
              <div className="card-body">
                {this.loading()}
                {this.recurrenceForm()}
                {this.recurrencesTable()}
                {this.recurrenceOverview()}
              </div>
            </div>
          </section>
        </div>
        {this.removeRecurrenceModal()}
      </div>
    );
  }
}

BillRecurrencePage.propTypes = {
  i18n: PropTypes.object,
  config: PropTypes.object,
  content: PropTypes.object,
  payMethods: PropTypes.array,
  billRecurrences: PropTypes.object,
  recurrenceAccounts: PropTypes.object,
  fetchPayMethods: PropTypes.func,
  fetchBillRecurrences: PropTypes.func,
  saveBillRecurrence: PropTypes.func,
  deleteBillRecurrence: PropTypes.func,
  clearBillRecurrences: PropTypes.func,
  clearDeletedBillRecurrence: PropTypes.func,
  clearSavedBillRecurrence: PropTypes.func,
  createdRecurrenceAccount: PropTypes.object,
  clearRecurrenceAccounts: PropTypes.func,
  fetchRecurrenceAccounts: PropTypes.func,
  createRecurrenceAccount: PropTypes.func,
  deleteRecurrenceAccount: PropTypes.func,
  deletedRecurrenceAccount: PropTypes.object,
  clearCreatedRecurrenceAccount: PropTypes.func,
  clearDeletedRecurrenceAccount: PropTypes.func,
  fetchPortalAccounts: PropTypes.func,
  clearPortalAccounts: PropTypes.func,
  portalAccounts: PropTypes.object,
  currentUser: PropTypes.object
};

export default BillRecurrencePage;
