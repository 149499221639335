import { http } from 'shared/api';

class PortalDocumentAPI {

  static createPortalDocument(portalPayment, file, splitId = null) {
    return http.post(`portal_payments/${portalPayment.id}/portal_documents`, {
      file_content: file.content,
      file_name: file.name,
      file_size: file.size,
      file_type: file.type,
      file_blob: file.preview,
      split_id: splitId
    });
  }

  static deletePortalDocument(portalPayment, file) {
    return http.delete(`portal_payments/${portalPayment.id}/portal_documents/${file.id}`);
  }
}

export default PortalDocumentAPI;
